import React from "react";

const OccupationSalaried = ({ handleInputChange, data }) => {
  return (
    <div className="row ">
      <div className="form-group col-sm-12 col-md-12 col-md-12">
        <label htmlFor="name">Company/Firm name</label>
        <input
          type="text"
          className="form-control"
          id="company_name"
          name="company_name"
          value={data?.company_name}
          onChange={handleInputChange}
          placeholder="Enter Company  Name"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Designation</label>
        <input
          type="text"
          className="form-control"
          id="designation"
          name="designation"
          value={data?.designation}
          onChange={handleInputChange}
          placeholder="Enter Designation"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Department</label>
        <input
          type="text"
          className="form-control"
          id="department"
          name="department"
          value={data?.department}
          onChange={handleInputChange}
          placeholder="Enter Designation"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-12 col-md-12">
        <label htmlFor="name">Office Address: </label>
        <textarea
          type="text"
          className="form-control"
          id="office_address"
          name="office_address"
          value={data?.office_address}
          onChange={handleInputChange}
          placeholder="Address"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Office City / Town / Village: </label>
        <input
          type="text"
          className="form-control"
          id="office_city"
          name="office_city"
          value={data?.office_city}
          onChange={handleInputChange}
          placeholder="Enter City/Town/Village Name"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Pin Code: </label>
        <input
          type="number"
          className="form-control"
          id="office_pincode"
          name="office_pincode"
          value={data?.office_pincode}
          onChange={handleInputChange}
          placeholder="Enter Pincode number"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Organisation Type :</label>
       
        <select
            className="form-control"
            id="organisation_type"
            name="organisation_type"
            value={data?.organisation_type}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Central Govt.">Central Govt.</option>
            <option value="State Govt.">State Govt.</option>
            <option value="PSU">PSU</option>
            <option value="MNC">MNC</option>
            <option value="Public Ltd. Co.">Public Ltd. Co.</option>
            <option value="Pvt. Ltd. Co./ LLP / LLC / OPC">Pvt. Ltd. Co./ LLP / LLC / OPC</option>
            <option value="Proprietorship / Partnership / HUF">Proprietorship / Partnership / HUF</option>
            <option value="Trust / NGO">Trust / NGO</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Employment Status :</label>
       
        <select
            className="form-control"
            id="employment_status"
            name="employment_status"
            value={data?.employment_status}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Permanent">Permanent</option>
            <option value="Contractual">Contractual</option>
            <option value="Retainership">Retainership</option>
            <option value="Part Time">Part Time</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Employed in this organisation :</label>
        <input
          type="number"
          className="form-control"
          id="employed_in_this_organisation"
          name="employed_in_this_organisation"
          value={data?.employed_in_this_organisation}
          placeholder="Enter number of years in this organisation"
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Retirement Age :</label>
        <input
          type="number"
          className="form-control"
          id="retirement_age"
          name="retirement_age"
          value={data?.retirement_age}
          onChange={handleInputChange}
          placeholder="Enter Retirement age"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">HR / Contact Person :</label>
        <input
          type="text"
          className="form-control"
          id="contact_person_name"
          name="contact_person_name"
          value={data?.contact_person_name}
          onChange={handleInputChange}
          placeholder="Name of HR/Contact person"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Contact Number :</label>
        <input
          type="number"
          className="form-control"
          id="contact_person_phone"
          name="contact_person_phone"
          value={data?.contact_person_phone}
          onChange={handleInputChange}
          placeholder="Contact number of HR/Contact person"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Gross Monthly Salary :</label>
        <input
          type="number"
          className="form-control"
          id="gross_monthly_income"
          name="gross_monthly_income"
          value={data?.gross_monthly_income}
          onChange={handleInputChange}
          placeholder="Gross monthly income in rupees"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Net Monthly Salary :</label>
        <input
          type="number"
          className="form-control"
          id="net_monthly_income"
          name="net_monthly_income"
          value={data?.net_monthly_income}
          onChange={handleInputChange}
          placeholder="Net monthly income after deductions (if any) in rupees"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Work Exp. (Years) :</label>
        <input
          type="number"
          className="form-control"
          id="work_experience_years"
          name="work_experience_years"
          value={data?.work_experience_years}
          onChange={handleInputChange}
          placeholder="Number of years working at the current company"
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Work Exp. (Months) :</label>
        <input
          type="number"
          className="form-control"
          id="work_experience_months"
          name="work_experience_months"
          value={data?.work_experience_months}
          onChange={handleInputChange}
          placeholder="Number of months working at the current job"
        />
      </div>
      <div className="form-group col-12 d-flex gap-4 align-items-center ">
        <label htmlFor="">
          {" "}
          Is there any break in Service beyond 3 months in last 2 years ?{" "}
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="is_there_a_break_service_beyond_3_months_in_2_years"
            onChange={handleInputChange}
            id="Yes"
            value="Yes"
          />
          <label className="form-check-label" htmlFor="Yes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="is_there_a_break_service_beyond_3_months_in_2_years"
            onChange={handleInputChange}
            id="No"
          />
          <label className="form-check-label" htmlFor="No">
            No
          </label>
        </div>
      </div>

      <div className="form-group col-12">
        <label htmlFor="name">Comment :</label>
        <textarea
          type="text"
          className="form-control"
          id="comments"
          name="comments"
          value={data?.comments}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default OccupationSalaried;

import React, { useEffect, useState } from "react";
import "../../loan/Loan.css";
import useFetch from "../../../../hooks/useFetch";
import { BASE_URL } from "../../../../util/Constant";
import { useSelector } from "react-redux";

const initialData = {
  loan_type: "",
  number_of_coapplicants: 0,
  applicant: [],
  relationship_with_applicant_of_coapplicant1: "",
  relationship_with_applicant_of_coapplicant2: "",
  relationship_with_applicant_of_coapplicant3: "",
  amount_required: "",
  tenure_in_years: "",
  moratorium_required: "false",
  moratorium_months: 0,
  purpose: "",
  city_in_which_property_is_located: "",
  have_you_finalised_property: "false",
  property_value: "",
  unit_number: "",
  project_name: "",
  project_rera_number: "",
  address: "",
  area: "",
  is_it_resale: "false",
  size_sqft: "",
  bhk_sqft: "",
};

const Application = () => {
  const [data, setData] = useState(initialData);
  const { data: loanType } = useFetch("/finance/loantype/");
  const [applicants, setApplicants] = useState([]);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const [errorMessage, setErrorMessage] = useState("");
  

  const fetchApplicant = async () => {
    const response = await fetch(`${BASE_URL}/finance/applicants/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Can not get the applicants");
    }
    const result = await response.json();
    setApplicants(result);
  };

  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;
    if (type === "select-multiple") {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setData({ ...data, [name]: selectedOptions });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  useEffect(() => {
    fetchApplicant();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${BASE_URL}/finance/loanapplication/`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/Json",
        },
        body: JSON.stringify(data),
      });

      if (res.ok) {
        alert("Application Submited successfully")
        setData(initialData)
        
      }else{
        const result = await res.json();
        setErrorMessage(result);
      }
      
    } catch (error) {
      setErrorMessage(error);
    }
  };
  const formatCurrency = (amount) => {
    if (amount < 100000) {
      return `₹${amount / 1000}K`;
    } else if (amount < 10000000) {
      return `₹${amount / 100000}L`;
    } else {
      return `₹${amount / 10000000}Cr`;
    }
  };
  
  const renderRelationshipFields = () => {
    const relationshipFields = [];
    for (let i = 1; i <= data.number_of_coapplicants; i++) {
      relationshipFields.push(
        <div className={`form-group col-lg-6 col-sm-12 col-md-6`} key={i}>
          <label htmlFor={`relationship_with_applicant_of_coapplicant${i}`}>
            Relation with Applicant {i}
          </label>
          <select
            type="text"
            className="form-control"
            id={`relationship_with_applicant_of_coapplicant${i}`}
            name={`relationship_with_applicant_of_coapplicant${i}`}
            value={data[`relationship_with_applicant_of_coapplicant${i}`]}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="Wife">Wife</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Husband">Husband</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
          </select>
        </div>
      );
    }
    return relationshipFields;
  };
  return (
    <div className="loan container add-project">
      <h1 className="text-center mb-3">Home Loan Application</h1>
      <form className="loan-form row" onSubmit={handleSubmit}>
        <div className="form-group col-12 d-flex align-items-center gap-5">
          <label htmlFor="name ">LoanType </label>
          <select
            className="form-control"
            id="loan_type"
            name="loan_type"
            value={data?.loan_type}
            onChange={handleInputChange}
            required
          >
            <option value="">Select loan type</option>
            {loanType &&
              loanType.map((type, i) => (
                <option key={i} value={type.id}>
                  {type.name}
                </option>
              ))}
          </select>
        </div>
        {errorMessage.loan_type && (
          <span className="error-message" style={{ color: "darkred" }}>
            {errorMessage.loan_type[0] ? errorMessage.loan_type[0] : ""}
          </span>
        )}
        <div className="form-group col-12">
          <label htmlFor="name">Select Purpose</label>
          <select
            className="form-control"
            id="purpose"
            name="purpose"
            value={data?.purpose}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Purpose</option>
            <option value="Purchase House / Flat">Purchase House / Flat</option>
            <option value="Renovate - Extend Existing House/Flat">
              Renovate - Extend Existing House/Flat
            </option>
            <option value="Takeover HL from other bank">
              Takeover HL from other bank
            </option>
            <option value="Reimbursement of investment of Renovate - Extend Existing House/Flat">
              Reimbursement of investment of Renovate - Extend Existing
              House/Flat
            </option>
          </select>
          {errorMessage.purpose && (
            <span className="error-message" style={{ color: "darkred" }}>
              {errorMessage.purpose[0] ? errorMessage.purpose[0] : ""}
            </span>
          )}
        </div>

        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">Applicant </label>
          <select
            className="form-control"
            id="applicant"
            name="applicant"
            multiple
            value={data?.applicant}
            onChange={handleInputChange}
          >
            {applicants?.map((applicant) => (
              <option key={applicant?.id} value={applicant?.id}>
                {applicant?.first_name} {applicant?.last_name}
              </option>
            ))}
          </select>
          {errorMessage.purpose && (
            <span className="error-message" style={{ color: "darkred" }}>
              {errorMessage.purpose[0] ? errorMessage.purpose[0] : ""}
            </span>
          )}
        </div>
        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">No. of Co-Applicants: </label>

          <select
            className="form-control"
            id="number_of_coapplicants"
            name="number_of_coapplicants"
            value={data.number_of_coapplicants}
            onChange={handleInputChange}
            placeholder="Number of Co-applicants"
            required
          >
            <option value="">Select Number of Co-applicants</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        {data.number_of_coapplicants > 0 && renderRelationshipFields()}
        {/* <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name"> Loan Amount </label>
          <input
            type="number"
            className="form-control"
            id="amount_required"
            name="amount_required"
            value={data.amount_required}
            onChange={handleInputChange}
            placeholder="Enter loan amount"
            required
          />
        </div> */}
        <div className="form-group col-lg-6 col-sm-12 col-md-6">
  <label htmlFor="name"> Loan Amount </label>
  <input
    type="range"
    className="form-range"
    id="amount_required"
    name="amount_required"
    min="0" // set the minimum value of the slider
    max="100000000" // set the maximum value of the slider
    step="100000" // set the step interval
    value={data.amount_required}
    onChange={handleInputChange}
    required
  />
  <div className="">
    <span>{formatCurrency(data.amount_required)}</span>
  </div>
</div>

<div className="form-group col-lg-6 col-sm-12 col-md-6">
  <label htmlFor="name">Tenure in years </label>
  <select
    className="form-control"
    id="tenure_in_years"
    name="tenure_in_years"
    value={data.tenure_in_years}
    onChange={handleInputChange}
    required
  >
    <option value="">Select Tenure in years</option>
    {Array.from({ length: 30 }, (_, index) => index + 1).map((year) => (
      <option key={year} value={year}>
        {year} years
      </option>
    ))}
  </select>
</div>

        <div className="form-group col-lg-6 col-sm-12 col-md-6 d-flex gap-4 align-items-center">
          <label htmlFor="name">Moratorium Required</label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="moratorium_required"
              onChange={handleInputChange}
              checked={data.moratorium_required === "true"}
              id="true"
              value="true"
            />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="moratorium_required"
              value="false"
              checked={data.moratorium_required === "false"}
              onChange={handleInputChange}
              id="false"
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
        </div>
        {data.moratorium_required === "true" && <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">Moratorium(in months)</label>
          <input
            type="number"
            className="form-control"
            id="moratorium_months"
            name="moratorium_months"
            value={data?.moratorium_months}
            onChange={handleInputChange}
            placeholder="Enter number of months"
            required
          />
        </div>}
        <h4>About the Property:</h4>
        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">City ( Property )</label>
          <input
            type="text"
            className="form-control"
            id="city_in_which_property_is_located"
            name="city_in_which_property_is_located"
            value={data.city_in_which_property_is_located}
            onChange={handleInputChange}
            placeholder="City name where property is located"
            required
          />
        </div>
        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">Size</label>
          <input
            type="text"
            className="form-control"
            id="size_sqft"
            name="size_sqft"
            value={data.size_sqft}
            onChange={handleInputChange}
            placeholder="Size in sqft"
            required
          />
        </div>
        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">BHK</label>
          <input
            type="text"
            className="form-control"
            id="bhk_sqft"
            name="bhk_sqft"
            value={data.bhk_sqft}
            onChange={handleInputChange}
            placeholder="bhk"
            required
          />
        </div>

        <div className="form-group col-lg-6 col-sm-12 col-md-6">
          <label htmlFor="name">Property Price( Value)</label>
          <input
            type="number"
            className="form-control"
            id="property_value"
            name="property_value"
            value={data.property_value}
            onChange={handleInputChange}
            placeholder="Property  price in INR"
            required
          />
        </div>
        <div className="form-group col-lg-6 col-sm-12 col-md-6 d-flex gap-4 align-items-center">
          <label htmlFor="name">Is Property Finalised ?</label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="have_you_finalised_property"
              id="Yes"
              value="true"
              checked={data.have_you_finalised_property === "true"}
              onChange={handleInputChange}
            />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="have_you_finalised_property"
              id="No"
              value="false"
              checked={data.have_you_finalised_property === "false"}
              onChange={handleInputChange}
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
        </div>
        {data.have_you_finalised_property === "true" && (
          <>
           
            <div className="form-group col-lg-6 col-sm-12 col-md-6 d-flex gap-4 align-items-center">
              <label htmlFor="name">Is it Resale ?</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_it_resale"
                  onChange={handleInputChange}
                  id="Yes"
                  value="Yes"
                />
                <label className="form-check-label" htmlFor="Yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_it_resale"
                  value="No"
                  onChange={handleInputChange}
                  id="No"
                />
                <label className="form-check-label" htmlFor="No">
                  No
                </label>
              </div>
            </div>
            <div className="form-group col-lg-6 col-sm-12 col-md-6">
              <label htmlFor="name">Unit Number</label>
              <input
                type="text"
                className="form-control"
                id="unit_number"
                name="unit_number"
                value={data.unit_number}
                onChange={handleInputChange}
                placeholder="Unit number"
                required
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12 col-md-6">
              <label htmlFor="name">Project Name</label>
              <input
                type="text"
                className="form-control"
                id="project_name"
                name="project_name"
                value={data.project_name}
                onChange={handleInputChange}
                placeholder="Project name"
                required
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12 col-md-6">
              <label htmlFor="name">Project Area</label>
              <input
                type="text"
                className="form-control"
                id="area"
                name="area"
                value={data.area}
                onChange={handleInputChange}
                placeholder="Project name"
                required
              />
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12">
              <label htmlFor="name">Address</label>
              <textarea
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={data.address}
                onChange={handleInputChange}
                placeholder="Address"
                required
              />
            </div>
            <div className="form-group col-12">
              <label htmlFor="name">RERA Number (if Project post 2017 )</label>
              <input
                type="text"
                className="form-control"
                id="project_rera_number"
                name="project_rera_number"
                value={data.project_rera_number}
                onChange={handleInputChange}
                placeholder="Rera  number"
                required
              />
              {errorMessage.project_rera_number && (
                <span className="error-message" style={{ color: "darkred" }}>
                  {errorMessage.project_rera_number[0]
                    ? "Enter Correct mobile Number"
                    : ""}
                </span>
              )}
            </div>
          </>
        )}

        <div className="text-center">
          <button type="submit" className={`m-2 btn btn-primary `}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Application;

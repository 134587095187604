import React, {  useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import { BASE_URL } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";

const Cancellation = () => {
  const { project_id, id } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector(state=>state.authorization.accessToken)
  // const {data: property} = useFetch(`/projects/projects/${project_id}`)
  const [showConfirmation, setShowConfirmation] = useState(true);
  const dispatch = useDispatch()
  const property = useSelector(state=>state.project.selectedProject)
  useEffect(()=>{
    dispatch(fetchProjectByIdAsync(project_id))
  },[dispatch,project_id])
  const handleReschedule = () => {
    navigate(`/reschedule/${project_id}/${id}`);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const status = "Cancel";
    fetch(`${BASE_URL}/projects/sitevisits/${id}/`, {
      method: "PATCH",
      body: JSON.stringify({ status: status }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setShowConfirmation(false);
      })
      .catch((error) => {
        console.error("Error Canceling site visit:", error);
      });
  };

  if (!property) {
    return <div>Loading...</div>;
  }

  return (
    <div className="site-visit-cancellation">
      {showConfirmation ? (
        <div className="container">
          <div className="site-visit-cancellation-detail">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="site-visit-cancellation-image">
                  
                  <h4>
                    {property.name} | {property.address}
                  </h4>
                  <ClickableImage src={property.image1 ? property.image1 : "/images/image_not_available.png"} />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 d-flex justify-content-center align-items-center" >
                <div className="site-visit-cancellation-text">
                  <h5>Do you really want to cancel the site visit?</h5>
                  <button className="buttons" onClick={handleFormSubmit}>
                    Cancel
                  </button>
                  <br />
                  <button className="buttons" onClick={handleReschedule}>
                    Reschedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="site-visit-cancellation-detail">
            <div className="site-visit-confimation-detail">
              <div className="site-confirmation-left">
                <h5>Your Site visit has been cancelled.</h5>
                <h5>
                  There are unbelievable discounts on this project for limited
                  units, and people are loving it.
                </h5>
                <h5>
                  You may schedule again as per your convenience, and we will be
                  happy to serve you.
                </h5>
                <h5>
                  Thanks, <br />Team TDK
                </h5>
              </div>
              <div className="site-confirmation-right">
                <h4>
                  {property?.name} | {property?.address}
                </h4>
                <div className="site-image">
                  <ClickableImage
                    src={
                      property?.image1 ? property?.image1 : "/images/image_not_available.png"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cancellation;

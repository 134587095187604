import React from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from '../../../components/Stepper/StepTracker';

const Payment = () => {
  const navigate = useNavigate();

  const handlePaymentSuccess = () => {
    navigate('/confirmation');
  }

  return (
    <div className='container'>
      <Stepper currentPage={3}/>
      <h1 className="text-center">Payment</h1><br/>
      <p className="lead text-center">Click on the button to continue</p>
      <div className='d-flex justify-content-center mb-5'>
      <div className="text-center">
        <button className='buttons' onClick={handlePaymentSuccess}>Payment Successful</button>
      </div>
      <div className="text-center">
        <button className='buttons'>Payment Failed</button>
      </div>
      </div>
    </div>
  )
}

export default Payment;

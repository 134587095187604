import React from 'react';

const Offers = () => {
  const cardStyle = {
    backgroundImage: 'url(https://static.vecteezy.com/system/resources/thumbnails/023/669/545/small/abstract-gradient-pink-blue-liquid-wave-background-free-vector.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white', // Set text color to white for better visibility
  };

  return (
    <div >
      <div className="card border-0 mb-3" style={cardStyle}>
        <div className="card-body text-center">
          <h5 className="card-title">Upto 15% additional discount*</h5>
          <p className="card-text">On  your first purchase with us.</p>
          <p className="card-text text-muted">*T&C apply</p>
        </div>
      </div>
      <div className="card border-0 mb-3" style={cardStyle}>
        <div className="card-body text-center">
          <h5 className="card-title">Upto 15% additional discount*</h5>
          <p className="card-text">On  your first purchase with us.</p>
          <p className="card-text text-muted">*T&C apply</p>
        </div>
      </div>
      <div className="card border-0 mb-3" style={cardStyle}>
        <div className="card-body text-center">
          <h5 className="card-title">Upto 15% additional discount*</h5>
          <p className="card-text">On  your first purchase with us.</p>
          <p className="card-text text-muted">*T&C apply</p>
        </div>
      </div>
    </div>
  );
};

export default Offers;

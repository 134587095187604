import { configureStore } from '@reduxjs/toolkit'
import projectReducer from '../features/project/projectSlice'
import authorizationSlice from '../features/authentication/authorizationSlice'
import wishlistReducer from '../features/wishlist/wishlistSlice'
import settingsReducer from '../features/settings/settingSlice'
import userDetailReducer from '../features/user/userSlice'
import bookingReducer from '../features/bookings/bookingSlice'
import siteVisitReducer from '../features/sitevisit/siteVisitSlice'
import builderReducer from '../features/builder/builderSlice'
import adminReducer from '../features/admin/adminSlice'
import loanReducer from '../features/loan/loanSlice'

export const store = configureStore({
    reducer: {
       project : projectReducer,
       authorization : authorizationSlice,
       wishlist : wishlistReducer,
       settings : settingsReducer,
       user     : userDetailReducer,
       booking : bookingReducer,
       sitevisit : siteVisitReducer,
       builder : builderReducer,
       admin : adminReducer,
       loan : loanReducer,
    },
   //  devTools: false,
  })
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const UnitType = ({Id}) => {
    const [UnitType, setUnitType] = useState('');

    useEffect(() => {
      if (Id) {        
        const apiUrl = `${BASE_URL}/projects/unit_types/${Id}/`;        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const unitName = data.unittypes;
            setUnitType(unitName);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [Id]);
    return (
      <div>
          {UnitType && <span>{UnitType}</span>}
      </div>
    )
  }
export default UnitType
import React from 'react'
import AddProjDetail from './AddProjDetail';
import AddOtherDetails from './AddOtherDetails'
import AddImages from './AddImages';
import AddFiles from './AddFiles';

const FormInputs = ({page,handleInputChange,data}) => {
    const display = {
        0 : <AddProjDetail  handleInputChange = {handleInputChange} data={data}/>,
        1 : <AddOtherDetails handleInputChange = {handleInputChange}/>,
        2 : <AddImages handleInputChange = {handleInputChange}/>,
        3 : <AddFiles handleInputChange = {handleInputChange}/>
    }
  return (
    <div>
        {display[page]}
    </div>
  )
}

export default FormInputs
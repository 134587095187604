import { BASE_URL } from "../../util/Constant"


export function getUserSetting(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/notifications/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }


  export function updateSettings({newData,id,token}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/notifications/${id}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${token}`,
            },
            body: JSON.stringify(newData),
          });
          if (!response.ok) {
            alert('Failed to update settings');
          }
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
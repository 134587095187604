import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAsync } from "../../../features/loan/loanSlice";
import '../../builder/addProject/AddProject.css'
import './Loan.css'
import Loader from "../../../components/loader/Loader";
import { BASE_URL } from "../../../util/Constant";

const LoanForm = () => {
  const [panNo, setPanNo] = useState("");
  const dispatch = useDispatch();
  const panData = useSelector((state) => state.loan.userDetail?.data);
  const isLoading = useSelector((state) => state.loan.isLoading);
  const errorMessage = useSelector((state) => state.loan.error);

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    dispatch(getUserDetailsAsync(panNo));
  };

  const handleLoanDataSubmit = async (e) => {
    e.preventDefault();

    let userData = {
      pan_number: panData.pan_number,
      full_name: panData.full_name,
      first_name: panData.full_name_split[0],
      middle_name: panData.full_name_split[1],
      last_name: panData.full_name_split[2],
      masked_aadhar_number: panData.masked_aadhaar,
      address_line1: panData.address.line_1 ? panData.address.line_1 : "",
      street_name: panData.address.street_name,
      zip: panData.address.zip,
      city: panData.address.city,
      state: panData.address.state,
      country: panData.address.country,
      email: panData.email,
      phone_number: panData.phone_number,
      gender: panData.gender,
      dob: panData.dob,
      input_dob: panData.input_dob,
      aadhaar_linked: panData.aadhaar_linked,
      dob_verified: panData.dob_verified,
      dob_check: panData.dob_check,
      category: panData.category,
    };
    try {
      const loanDataResponse = await fetch(
        `${BASE_URL}/loans/pancard/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const loanData = await loanDataResponse.json();

      if (loanDataResponse.ok) {
        console.log("Loan data submitted successfully");
      } else {
        console.error("Error submitting loan data:", loanData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="contforsignin  loan-page">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="signup-form add-project">
          <h3 className="text-center text-uppercase mb-5">Pan detail</h3>
          {isLoading ?  <Loader/>: 
           
           
           panData ? (
            <form onSubmit={handleLoanDataSubmit}>
              <div className="row ">
                <div className="col-lg-6 col-md-6 col-sm-6">
                 
                  <div className="form-group">
                    <label htmlFor="pan_number">Pan Number:</label>
                    <input
                      type="text"
                      value={panData?.pan_number}
                      name="pan_number"
                      id="pan_number"
                      className="form-control"
                      placeholder="Pan Number"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="full_name">Full Name:</label>
                    <input
                      type="text"
                      value={panData?.full_name}
                      name="full_name"
                      id="full_name"
                      className="form-control"
                      placeholder="Full Name"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name:</label>
                    <input
                      type="text"
                      value={panData?.full_name_split[0]}
                      name="first_name"
                      id="first_name"
                      className="form-control"
                      placeholder="First Name"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="middle_name">Middle Name:</label>
                    <input
                      type="text"
                      value={panData?.full_name_split[1]}
                      name="middle_name"
                      id="middle_name"
                      className="form-control"
                      placeholder="Middle Name"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="last_name">Last Name:</label>
                    <input
                      type="text"
                      value={panData?.full_name_split[2]}
                      name="last_name"
                      id="last_name"
                      className="form-control"
                      placeholder="Last Name"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="masked_aadhar_number">
                      Masked Aadhar Number:
                    </label>
                    <input
                      type="text"
                      value={panData?.masked_aadhaar}
                      name="masked_aadhar_number"
                      id="masked_aadhar_number"
                      className="form-control"
                      placeholder="Masked Aadhar Number"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="address_line1">Address Line 1:</label>
                    <input
                      type="text"
                      value={panData?.address.line_1}
                      name="address_line1"
                      id="address_line1"
                      className="form-control"
                      placeholder="Address Line 1"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="street_name">Street Name:</label>
                    <input
                      type="text"
                      value={panData?.address.street_name}
                      name="street_name"
                      id="street_name"
                      className="form-control"
                      placeholder="Street Name"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="zip">ZIP:</label>
                    <input
                      type="number"
                      value={panData?.address.zip}
                      name="zip"
                      id="zip"
                      className="form-control"
                      placeholder="ZIP"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="city">City:</label>
                    <input
                      type="text"
                      value={panData?.address.city}
                      name="city"
                      id="city"
                      className="form-control"
                      placeholder="City"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="state">State:</label>
                    <input
                      type="text"
                      value={panData?.address.state}
                      name="state"
                      id="state"
                      className="form-control"
                      placeholder="State"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="country">Country:</label>
                    <input
                      type="text"
                      value={panData?.address.country}
                      name="country"
                      id="country"
                      className="form-control"
                      placeholder="Country"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      value={panData?.email}
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input
                      type="text"
                      value={panData?.phone_number}
                      name="phone_number"
                      id="phone_number"
                      className="form-control"
                      placeholder="Phone Number"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="gender">Gender:</label>
                    <input
                      type="text"
                      value={panData?.gender}
                      name="gender"
                      id="gender"
                      className="form-control"
                      placeholder="Gender"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="dob">Date of Birth:</label>
                    <input
                      type="text"
                      value={panData?.dob}
                      name="dob"
                      id="dob"
                      className="form-control"
                      placeholder="Date of Birth"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="aadhaar_linked">Aadhaar Linked:</label>
                    <input
                      type="text"
                      value={panData?.aadhaar_linked}
                      name="aadhaar_linked"
                      id="aadhaar_linked"
                      className="form-control"
                      placeholder="Aadhaar Linked"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="dob_verified">
                      Date of Birth Verified:
                    </label>
                    <input
                      type="text"
                      value={panData?.dob_verified}
                      name="dob_verified"
                      id="dob_verified"
                      className="form-control"
                      placeholder="Date of Birth Verified"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="dob_verified">Input Dob Verified:</label>
                    <input
                      type="text"
                      value={panData?.input_dob}
                      name="input_dob"
                      id="input_dob"
                      className="form-control"
                      placeholder=" Input Date of Birth Verified"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="dob_check">Date of Birth Check:</label>
                    <input
                      type="text"
                      value={panData?.dob_check}
                      name="dob_check"
                      id="dob_check"
                      className="form-control"
                      placeholder="Date of Birth Check"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    <input
                      type="text"
                      value={panData?.category}
                      name="category"
                      id="category"
                      className="form-control"
                      placeholder="Category"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group text-center">
                  <button className="signup text-uppercase">
                    Submit Loan Data
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-5">
                <input
                  type="text"
                  value={panNo}
                  onChange={(e) => setPanNo(e.target.value)}
                  name="panNo"
                  className="form-control"
                  placeholder="Enter Your PAN Number"
                  autoComplete="off"
                /> {errorMessage?.message && (
                    <div className="error-message" style={{ color: "darkred" ,padding:"10px"}}>
                      {errorMessage.message}
                    </div>
                  )}
              </div>
             
              <div className="form-group text-center">
                <button className="signup text-uppercase">Submit</button>
              </div>
            </form>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default LoanForm;

import React, { useState } from 'react'
import './Calculator.css'
import Emi from './Emi'
import Eligibility from './Eligibility'

const Calculator = () => {
    const [calulator, setcalulator] = useState("EMI")
  return (
    <div className='mb-3'>
        <div className=" gap-2 d-flex ">
          <button onClick={()=>setcalulator("EMI")} className={`options-btn ${calulator === "EMI" ? "selected" : ""}`}>EMI Calculator</button>
          <button onClick={()=>setcalulator("Eligibility")} className={`options-btn ${calulator === "Eligibility" ? "selected" : ""}`}>Eligibility Calculator</button>
        </div>
        <div className='mt-3'>
        {calulator=== "EMI" ? <Emi/> : <Eligibility/>}</div>
    </div>
  )
}

export default Calculator
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserSetting, updateSettings } from "./settingApi";


export const getUserSettingsAsync = createAsyncThunk(
    'settings/getUserSettings',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getUserSetting(token);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const updateSettingsAsync = createAsyncThunk(
    'settings/updatesetting',
    async ( {newData, id, token}, {rejectWithValue} ) => {
      try {
        const response = await updateSettings({newData, id, token});
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

const settingSlice = createSlice({
    name: "settings",
    initialState: {
        settings:[],
        loading:false,
        error:null

    },
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getUserSettingsAsync.pending, (state) => {
            state.loading = true;
          })
          .addCase(getUserSettingsAsync.fulfilled, (state, action) => {
            state.settings = action.payload;
            state.loading = false
          })
          .addCase(getUserSettingsAsync.rejected, (state, action) => {
            state.error = action.error
            state.loading = false
          })
          
          .addCase(updateSettingsAsync.fulfilled, (state, action) => {
            state.settings = [action.payload];
          })
          .addCase(updateSettingsAsync.rejected, (state, action) => {
            state.error = action.error
            state.loading = false
          })
        },

})

export default settingSlice.reducer


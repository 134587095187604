import React from 'react'

const AddFiles = ({handleInputChange}) => {
    

  return (
    
    <div className='add-files'>
      <div className="input-fields">
      <div className="form-group">
            <label htmlFor="builder_logo">Builder Logo</label>
            <input
              type="file"
              className="form-control"
              id="builder_logo"
              name="builder_logo"
              // value={data?.builder_logo}
              onChange={handleInputChange}
            />
          </div>
      <div className="form-group">
            <label htmlFor="builder_kyc_documents">Builder KYC Documents</label>
            <input
              type="file"
              className="form-control"
              id="builder_kyc_documents"
              name="builder_kyc_documents"
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="document_7_12">Document 7/12</label>
            <input
              type="file"
              className="form-control"
              id="document_7_12"
              name="document_7_12"
                  onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="document_revenue_records">
              Document Revenue Records
            </label>
            <input
              type="file"
              className="form-control"
              id="document_revenue_records"
              name="document_revenue_records"
              
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="land_ownership">Land Ownership</label>
            <input
              type="file"
              className="form-control"
              id="land_ownership"
              name="land_ownership"
                   onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="non_agriculture_permission">
              Non-Agriculture Permission
            </label>
            <input
              type="file"
              className="form-control"
              id="non_agriculture_permission"
              name="non_agriculture_permission"
            
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="land_title_report">Land Title Report</label>
            <input
              type="file"
              className="form-control"
              id="land_title_report"
              name="land_title_report"
                      onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="nhai_permission">NHAI Permission</label>
            <input
              type="file"
              className="form-control"
              id="nhai_permission"
              name="nhai_permission"
                    onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="town_planning_scheme_layout">
              Town Planning Scheme Layout
            </label>
            <input
              type="file"
              className="form-control"
              id="town_planning_scheme_layout"
              name="town_planning_scheme_layout"
          
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="final_plot_plan">Final Plot Plan</label>
            <input
              type="file"
              className="form-control"
              id="final_plot_plan"
              name="final_plot_plan"
                    onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="f_form">F-Form</label>
            <input
              type="file"
              className="form-control"
              id="f_form"
              name="f_form"
              hange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="plan_pass">Plan Pass</label>
            <input
              type="file"
              className="form-control"
              id="plan_pass"
              name="plan_pass"
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="unit_plan_pass">Unit Plan Pass</label>
            <input
              type="file"
              className="form-control"
              id="unit_plan_pass"
              name="unit_plan_pass"
                   onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="construction_permission">
              Construction Permission*
            </label>
            <input
              type="file"
              className="form-control"
              id="construction_permission"
              name="construction_permission"
                            onChange={handleInputChange}
                            required
            />
          </div>

          <div className="form-group">
            <label htmlFor="rera_certificate">RERA Certificate*</label>
            <input
              type="file"
              className="form-control"
              id="rera_certificate"
              name="rera_certificate"
                     onChange={handleInputChange}
                     required
            />
          </div>
          <div className="form-group">
            <label htmlFor="bu_or_oc_permission">BU/OR OC Permission</label>
            <input
              type="file"
              className="form-control"
              id="bu_or_oc_permission"
              name="bu_or_oc_permission"
                        onChange={handleInputChange}
              placeholder="Enter BU/OR OC Permission URL"
            />
          </div></div>
    </div>
  )
}

export default AddFiles
import React from "react";
import ProjectCard from "../../../components/projectCard/ProjectCard";
import SearchBox from "../../../components/searchBox/SearchBox";
import "./Home.css";
import ClickableImage from "../../../components/modal/ClickableImage";
import { Link } from "react-router-dom";
import AdvantageList from "./AdvantageList";
import CustomerSpeaks from "./CustomerSpeaks";
import { useSelector } from "react-redux";
import Emi from "../../customer/loanApplication/loanDashboard/calculator/Emi";
import Eligibility from "../../customer/loanApplication/loanDashboard/calculator/Eligibility";

const Home = () => {
  const isLoggedIn = useSelector(
    (state) => state.authorization.isAuthenticated
  );

  return (
    <div className="home container">
      <div>
        <ProjectCard />
      </div>
      <div className="mt-4">
        <SearchBox enableNavigation={true} />
        <ClickableImage />
      </div>
      <div className="banking-section">
        <div className="our-banking">
          <div className="align-items-center banking-detail clearfix d-flex">
            <div className="banking-text">
              <h2>be sure,</h2>
              <h2>be smart.</h2>
              <p >We Get you Pre Sanction HL</p>
              <p>ZERO Processing Fees, Simplified Process !</p>
            </div>
            <div className="start-now-btn">
              {isLoggedIn ? (
                <Link to="/homeloan">Apply now</Link>
              ) : (
                <Link to={`/login`}>Apply Now</Link>
              )}
            </div>
          </div>
          <div className="banking-logo">
            <ul>
              <li>
                <img src="images/bank-1.png" alt="" />
              </li>
              <li>
                <img src="images/bank-2.png" alt="" />
              </li>
              <li>
                <img src="images/bank-3.png" alt="" />
              </li>
              <li>
                <img src="images/bank-4.png" alt="" />
              </li>
              <li>
                <img src="images/bank-5.png" alt="" />
              </li>
              <li>
                <img src="images/bank-6.png" alt="" />
              </li>
              <li>
                <img src="images/bank-7.png" alt="" />
              </li>
              <li>
                <img src="images/bank-8.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="home-calculator">
      
        <div className="home-cal"><Eligibility/></div>
        <div className="home-cal my-3"><Emi/></div>
        
      </div>
      <div className="dream-home">
        <div className="mall-title text-center">
          <h1>Dream Home Mall</h1>
        </div>
        <div className="genuine-service">
          <p className="text-center">Every thing you need after buying home to occupying home.</p>
          <p className="text-center">
            Now beautify home in clicks and get best rates and genuine services.
          </p>
          <div className="row mt-5 services">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-1.png" alt="" />
                <Link to="">Fans, Lights & Geysers</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-2.png" alt="" />
                <Link to="">Furniture</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-3.png" alt="" />
                <Link to="">Kitchen Appliances</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-4.png" alt="" />
                <Link to="">Paint</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-5.png" alt="" />
                <Link to="">Laptops</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-6.png" alt="" />
                <Link to="">Electronics</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-7.png" alt="" />
                <Link to="">Interior Designers</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="genuine-product text-center">
                <img src="images/iteam-8.png" alt="" />
                <Link to="">Decor</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offer-board">
        <div className="our-offer">
          <p>OFFER BOARD:</p>
          <p className="offer">
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
      <AdvantageList />
      <CustomerSpeaks />
    </div>
  );
};

export default Home;

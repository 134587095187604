export function getBookings(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`/projects/bookings/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function addFeedback({token,bookingId,data}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`/projects/bookings/${bookingId}/customerspeaks/`, {
          method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify(data),
      });
        if (response.ok) {
          const data = await response.json();
          alert("Feedback added successfully")
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }

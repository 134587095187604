import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "./loanApi";


export const getUserDetailsAsync = createAsyncThunk(
    'loan/getUserDetails',
    async (panNo,{rejectWithValue} ) => {
      try {
        const response = await getUserDetails(panNo);
        return response.data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
)

const loanSlice = createSlice({
    name :"loan",
    initialState:{
        userDetail:null,
        isLoading:false,
        error:"",
    },
    reducer:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getUserDetailsAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
            state.userDetail = action.payload;
            state.isLoading = false;
            state.error = null
          })
          .addCase(getUserDetailsAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
            
          })
            
    }
})


export default loanSlice.reducer;
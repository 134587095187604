import React, {  useState } from "react";
import "./ConstStatus.css";
import { useNavigate, useParams } from "react-router-dom";
import ProjectName from "../../../components/card/ProjectName";
import useFetch from "../../../hooks/useFetch";
import { BASE_URL } from "../../../util/Constant";
import { useSelector } from "react-redux";

const AddConstStatus = () => {
  const {data: block } = useFetch("/projects/block/");
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const {id} = useParams()
  const navigate = useNavigate();
  const initialData = {
    project: "",
    block: "",
    status: "",
    description: "",
    payment_due: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    construction_update_document: "",
  };
  const [data, setData] = useState(initialData);
  const handleInputChange = (e) => {
    const { name } = e.target;

    if (e.target.type === "file") {
      const selectedFile = e.target.files[0];
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile, // Store the File object directly
      }));
    } else {
      setData({ ...data, [name]: e.target.value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      for (const key in data) {
        formData.append(key, data[key]);
      }

      const response = await fetch(
        `${BASE_URL}/projects/builder_projects/${id}/construction_status/`,
        {
          method: "POST",
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
          body: formData,
        }
      );
      const resp = await response.json();
      if (response.ok) {
        alert("Added Construction Status Successfully");
        setData(initialData);
        navigate("/dashboard")
      } else {
        alert(resp);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const filteredBlocks = block?.filter(
    (block) => block?.project === parseInt(id)
  );

 
  return (
    <div>
      <div className="mains">
        <div className="container add-project ">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
          <h1 className="text-center mb-4">Add Construction Status</h1>
          <div className="form-group">
                <label htmlFor="">Select the Project</label>
                <br />
                <select
                type='number'
                 className="form-control"
                 id="project"
                 name="project"
                  value={data?.project}
                  onChange={handleInputChange}
                >
                  <option value="">Select the Project</option>
                  
                        <option key={id} value={id}>
                          <ProjectName ID ={id}/>
                        </option>
                </select>
              </div>
            <div className="add-unit add-const-status">
              
              
              <div className="form-group">
                <label htmlFor="rera_number">Block</label>
                <select
                  type="text"
                  className="form-control"
                  id="block"
                  name="block"
                  value={data?.block}
                  onChange={handleInputChange}
                  placeholder="Enter Block"
                >
                  <option value="">Select Block</option>
                  {filteredBlocks && filteredBlocks.length > 0
                    ? filteredBlocks?.map((block, index) => (
                        <option key={index} value={block.id}>
                          {block.block}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="status">Status</label>
                <input
                  type="text"
                  className="form-control"
                  id="status"
                  name="status"
                  value={data?.status}
                  onChange={handleInputChange}
                  placeholder="Enter block"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  value={data?.description}
                  onChange={handleInputChange}
                  placeholder="Enter block"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="payment_due">Payment due</label>
                <input
                  type="number"
                  className="form-control"
                  id="payment_due"
                  name="payment_due"
                  value={data?.payment_due}
                  onChange={handleInputChange}
                  placeholder="Enter block"
                  required
                />
              </div>
              <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">Image1</label>
              <input
                type="file"
                className="form-control"
                id="image1"
                name="image1"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">Image2</label>
              <input
                type="file"
                className="form-control"
                id="image2"
                name="image2"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">Image3</label>
              <input
                type="file"
                className="form-control"
                id="image3"
                name="image3"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">Image4</label>
              <input
                type="file"
                className="form-control"
                id="image4"
                name="image4"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">Image5</label>
              <input
                type="file"
                className="form-control"
                id="image5"
                name="image5"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="land_parcel_SqMtr">
                Construction Update document
              </label>
              <input
                type="file"
                className="form-control"
                id="construction_update_document"
                name="construction_update_document"
                onChange={handleInputChange}
              />
            </div>

           
            </div>
            <div className="text-center">
              <button type="submit" className=" buttons">
                Add Block
              </button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddConstStatus;

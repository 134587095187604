import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import "./Buynow.css";
import ClickableImage from "../../../components/modal/ClickableImage";
// import Stepper from "../../components/Stepper/StepTracker";
import { BASE_URL } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "../../../components/Stepper/StepTracker";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";

const BuyerDetail = () => {
  const navigate = useNavigate();
  const property = useSelector(state=>state.project.selectedProject)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const [selectedBuyerOption, setSelectedBuyerOption] = useState("SB");
  const queryParams = new URLSearchParams(window.location.search);
  const unitNumber = queryParams.get('unitNumber');
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(fetchProjectByIdAsync(id));
  }, [id, dispatch]);
  
  const indianStates = {
    AndamanandNicobarIslands: 'Andaman and Nicobar Islands',
    AndhraPradesh: 'Andhra Pradesh',
    ArunachalPradesh: 'Arunachal Pradesh',
    Assam: 'Assam',
    Bihar: 'Bihar',
    Chandigarh: 'Chandigarh',
    Chhattisgarh: 'Chhattisgarh',
    DadraandNagarHaveliandDamanandDiu: 'Dadra and Nagar Haveli and Daman and Diu',
    Delhi: 'Delhi',
    Goa: 'Goa',
    Gujarat: 'Gujarat',
    Haryana: 'Haryana',
    HimachalPradesh: 'Himachal Pradesh',
    JammuandKashmir: 'Jammu and Kashmir',
    Jharkhand: 'Jharkhand',
    Karnataka: 'Karnataka',
    Kerala: 'Kerala',
    Ladakh: 'Ladakh',
    Lakshadweep: 'Lakshadweep',
    MadhyaPradesh: 'Madhya Pradesh',
    Maharashtra: 'Maharashtra',
    Manipur: 'Manipur',
    Meghalaya: 'Meghalaya',
    Mizoram: 'Mizoram',
    Nagaland: 'Nagaland',
    Odisha: 'Odisha',
    Puducherry: 'Puducherry',
    Punjab: 'Punjab',
    Rajasthan: 'Rajasthan',
    Sikkim: 'Sikkim',
    TamilNadu: 'Tamil Nadu',
    Telangana: 'Telangana',
    Tripura: 'Tripura',
    UttarPradesh: 'Uttar Pradesh',
    Uttarakhand: 'Uttarakhand',
    WestBengal: 'West Bengal',
  };
  
  const handlehomeloan = (option) => {
    // Update the number_of_cobuyers field in applicants based on the selected option
    if (option === "Yes") {
      setApplicants((prevState) => ({
        ...prevState,
        homeloan_required: "Yes", // No co-buyers
      }));
    } else if (option === "May-be") {
      setApplicants((prevState) => ({
        ...prevState,
        homeloan_required: "May-be",
      }));
    } else {
      setApplicants((prevState) => ({
        ...prevState,
        homeloan_required: "No",
        loan_stage: 'Y',
      }));
    }
  };

  const [applicants, setApplicants] = useState([
    {
      first_name: "",
      last_name: "",
      resident_status: "",
      aadhar_card: "",
      passportsize_photo: "",
      pan_card: "",
      number_of_cobuyers: "SB",
      relation_with_buyer: "",
      cobuyer_first_name: "",
      cobuyer_last_name: "",
      cobuyer_resident_status: "",
      cobuyer_pan_card: "",
      cobuyer_aadhar_card: "",
      cobuyer_passportsize_photo: "",
      relation_with_buyer1: "",
      cobuyer_first_name3: "",
      cobuyer_last_name3: "",
      cobuyer_resident_status3: "",
      cobuyer_pan_card3: "",
      cobuyer_aadhar_card3: "",
      cobuyer_passportsize_photo3: "",
      contact_address_line1: "",
      contact_address_line2: "",
      contact_address_line3: "",
      contact_address_pincode: "",
      homeloan_required: "",
      loan_stage: "",
      bank_name: "",
      loan_sanction_letter: "",
    },
  ]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicants((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'resident_status' && value === 'Indian') {
      setApplicants({
        ...applicants,
        resident_status: value,
        contact_address_line3: 'India', // Set to 'India' when 'Indian' is selected
      });
    }else if  (name === 'resident_status' && value === 'NRI') {
      setApplicants({
        ...applicants,
        resident_status: value,
        contact_address_line3: '', // Set to 'India' when 'Indian' is selected
      });
    }else if  (name === 'number_of_cobuyers' && value === 'SB') {
      setApplicants({
        ...applicants,
        number_of_cobuyers: "SB",
      });
    }
  };
  const handleBuyerOptionChange = (option) => {
    setSelectedBuyerOption(option);
      setApplicants((prevState) => ({
        ...prevState,
        number_of_cobuyers: option,
      }));
   
  };
  useEffect(() => {
    // Set the default value of number_of_cobuyers based on selectedBuyerOption
    if (selectedBuyerOption === "SB") {
      setApplicants((prevState) => ({
        ...prevState,
        number_of_cobuyers: 'SB',
      }));
    }
  }, [selectedBuyerOption]); // The empty dependency array ensures this runs only once on mount
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;

    // Update the state with the file reference
    setApplicants((prevState) => {
      return {
        ...prevState,
        [name]: file,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(); // Create a new FormData object

      // Append each field and file to the FormData object
      for (const key in applicants) {
        if (applicants.hasOwnProperty(key)) {
          // Append files as Blobs
          if (applicants[key] instanceof File) {
            formData.append(key, applicants[key]);
          } else {
            // Append regular fields
            formData.append(key, applicants[key]);
          }
        }
      }

      const response = await fetch(
        `${BASE_URL}/projects/CustomerKYC/`,
        {
          method: "POST",
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
          body: formData, // Pass the FormData object as the body
        }
      );

      const data = await response.json();
      if (response.ok) {
        // alert(`KYC completed`);
        navigate('/payment');
        // // refresh the page to reset the form field for now 
        // window.location.reload();
      } else {
        setErrorMessage(data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  return (
    <div className="buyerForm">
      
      <div className="buyer-detail">
      <div className="container">
        <Stepper currentPage={2}/>
          <div className="booking-details">
            
              <div className="buyer-detail-text">
                <h2>Please fill the following details for booking</h2>
                <h2>
                  Unit No. : <span>{unitNumber}</span>
                </h2>
                <h2>
                  Project Name : <span> {property?.name}</span>
                </h2>
              
            </div>
           
              <div className="buyer-detail-image">
                <ClickableImage
                  src={
                    property?.image1 ? property?.image1 : "/images/image_not_available.png"
                  }
                  alt="Image 1"
                />
              </div>
            
          </div>
        </div>
      </div>
      <div className="buyer-detail-form">
        <div className="container">
          <div className="buyer-form-data">
            <form onSubmit={onSubmit} encType="multipart/form-data">
              <div className="applicant-section">
                <h3>Buyer Detials:</h3>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={applicants.first_name}
                        onChange={handleInputChange}
                        name="first_name"
                        placeholder="First name"
                        required
                      />
                      
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={applicants.last_name}
                        onChange={handleInputChange}
                        name="last_name"
                        placeholder="Last Name"
                        required
                      />
                      
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Resident status</label>
                      <select
                        className="form-control"
                        value={applicants.resident_status}
                        onChange={handleInputChange}
                        name="resident_status"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Indian">Indian</option>
                        <option value="NRI">NRI</option>
                      </select>
                     
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Upload Aadhar Card</label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => handleFileChange(e)}
                        name="aadhar_card"
                        placeholder="Aadhar Card photo"
                        required
                      />
                      
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Upload Passportsize photo</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(e)}
                        name="passportsize_photo"
                        required
                      />
                      
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Upload PAN Card</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(e)}
                        name="pan_card"
                        required
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={applicants.contact_address_line1}
                    onChange={handleInputChange}
                    name="contact_address_line1"
                    placeholder="Address"
                    required
                  />
                  
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value={applicants.contact_address_line3}
                        onChange={handleInputChange}
                        name="contact_address_line3"
                        placeholder="Country"
                      />
                      
                    </div>
                  </div>
                  
                  <div className="col-lg-4 col-md-4 col-sm-12">
  <div className="form-group">
    <label>State</label>
    {applicants.contact_address_line3 === 'India' ? (
      <select
        className="form-control"
        value={applicants.contact_address_line2}
        onChange={handleInputChange}
        name="contact_address_line2"
        placeholder="State"
        required
      >
        <option value="">Select</option>
        {Object.values(indianStates).map((state, index) => (
          <option key={index} value={state}>
            {state}
          </option>
        ))}
      </select>
    ) : (
      <input
        type="text"
        className="form-control"
        value={applicants.contact_address_line2}
        onChange={handleInputChange}
        name="contact_address_line2"
        placeholder="State"
        required
      />
    )}
  </div>
</div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>PIN Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={applicants.contact_address_pincode}
                        onChange={handleInputChange}
                        name="contact_address_pincode"
                        placeholder="PIN Code"
                        required
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="buyer-option-section">
                  <label className="form-check-label-main">
                    Number of cobuyers :
                  </label>
                  <div className="form-check form-check-inline col-sm-12 col-lg-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="number_of_cobuyers"
                      value="SB"
                      checked={selectedBuyerOption === "SB"}
                      onChange={() => handleBuyerOptionChange("SB")}
                      required
                    />
                    <label className="form-check-label" htmlFor="singleBuyer">
                      Single Buyer
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-sm-12 col-lg-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="number_of_cobuyers"
                      value="1"
                      checked={selectedBuyerOption === "1"}
                      onChange={() => handleBuyerOptionChange("1")}
                    />
                    <label className="form-check-label" htmlFor="oneCoBuyer">
                      1 Co-Buyer
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-sm-12 col-lg-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="number_of_cobuyers"
                      id="twoCoBuyer"
                      value="2"
                      checked={selectedBuyerOption === "2"}
                      onChange={() => handleBuyerOptionChange("2")}
                    />
                    <label className="form-check-label" htmlFor="twoCoBuyer">
                      2 Co-Buyers
                    </label>
                    
                  </div>
                  {errorMessage.number_of_cobuyers && (
                        <span
                          className="error-message"
                          style={{ color: "red" }}
                        >
                          {errorMessage.number_of_cobuyers
                            ? errorMessage.number_of_cobuyers
                            : ""}
                        </span>
                      )}
                </div>
                {selectedBuyerOption !== "SB" && (
                  <div className="co-buyer-form">
                    {Array.from({
                      length: selectedBuyerOption === "1" ? 1 : 2,
                    }).map((_, index) => (
                      <div key={index} className="co-buyer-details">
                        <h4>{index === 0 ? "1st" : "2nd"} Co-Buyer Details:</h4>
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>Relation with buyer</label>
                              <select
                                className="form-control"
                                value={
                                  index === 0
                                    ? applicants.relation_with_buyer
                                    : applicants["relation_with_buyer" + index]
                                }
                                onChange={handleInputChange}
                                name={
                                  index === 0
                                    ? "relation_with_buyer"
                                    : "relation_with_buyer" + index
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="H">Husband</option>
                                <option value="W">Wife</option>
                                <option value="B">Brother</option>
                                <option value="S">Sister</option>
                                <option value="F">Father</option>
                                <option value="M">Mother</option>
                                <option value="S">Son</option>
                                <option value="D">Daughter</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  index === 0
                                    ? applicants.cobuyer_first_name
                                    : applicants[
                                        "cobuyer_first_name" + (index + 2)
                                      ]
                                }
                                onChange={handleInputChange}
                                name={
                                  index === 0
                                    ? "cobuyer_first_name"
                                    : "cobuyer_first_name" + (index + 2)
                                }
                                placeholder="First name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  index === 0
                                    ? applicants.cobuyer_last_name
                                    : applicants[
                                        "cobuyer_last_name" + (index + 2)
                                      ]
                                }
                                onChange={handleInputChange}
                                name={
                                  index === 0
                                    ? "cobuyer_last_name"
                                    : "cobuyer_last_name" + (index + 2)
                                }
                                placeholder="Last name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>Resident status</label>
                              <select
                                className="form-control"
                                value={
                                  index === 0
                                    ? applicants.cobuyer_resident_status
                                    : applicants[
                                        "cobuyer_resident_status" + (index + 2)
                                      ]
                                }
                                onChange={handleInputChange}
                                name={
                                  index === 0
                                    ? "cobuyer_resident_status"
                                    : "cobuyer_resident_status" + (index + 2)
                                }
                                placeholder="Resident Status"
                                required
                              >
                                <option value="">Select</option>
                                <option value="Indian">Indian</option>
                                <option value="NRI">NRI</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>Upload Aadhar Card</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => handleFileChange(e)}
                                name={
                                  index === 0
                                    ? "cobuyer_adhar_card"
                                    : "cobuyer_adhar_card" + (index + 2)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label>Upload Passportsize photo</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => handleFileChange(e)}
                                name={
                                  index === 0
                                    ? "cobuyer_passportsize_photo"
                                    : "cobuyer_passportsize_photo" + (index + 2)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label> Upload PAN Card</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => handleFileChange(e)}
                                name={
                                  index === 0
                                    ? "cobuyer_pan_card"
                                    : "cobuyer_pan_card" + (index + 2)
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="aditional-info">
                <h4>Additional Info:</h4>
                <div className="additional-info-detail">
                  <div className="loan-section">
                    <span>Home Loan Required?</span>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="homeloan_required"
                        onChange={() => handlehomeloan("Yes")}
                        id="Yes"
                        value="Yes"
                      />
                      <label className="form-check-label" htmlFor="Yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="homeloan_required"
                        onChange={() => handlehomeloan("No")}
                        id="No"
                      />
                      <label className="form-check-label" htmlFor="No">
                        No
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="homeloan_required"
                        onChange={() => handlehomeloan("May-be")}
                        id="May-Be"
                      />
                      <label className="form-check-label" htmlFor="May-Be">
                        May Be
                      </label>
                    </div>
                    {errorMessage.homeloan_required && (
                        <span
                          className="error-message"
                          style={{ color: "red" }}
                        >
                          {errorMessage.homeloan_required
                            ? errorMessage.homeloan_required
                            : ""}
                        </span>
                      )}
                  </div>
                  {applicants?.homeloan_required === "Yes" || applicants?.homeloan_required=== "May-be" ? (<>
                  <div className="form-group">
                    <label>Have you already applied for home loan?</label>
                    <select
                      className="form-control"
                      value={applicants.loan_stage}
                      onChange={handleInputChange}
                      name="loan_stage"
                    >
                      <option value="">Select</option>
                      <option value="Y">Yet to Apply</option>
                      <option value="U">Underproccess through TDK</option>
                      <option value="U2">
                        Underproccess through other channnel
                      </option>
                      <option value="S">Sanctioned</option>
                    </select>
                    {errorMessage.loan_stage && (
                      <span className="error-message" style={{ color: "red" }}>
                        {errorMessage.loan_stage ? errorMessage.loan_stage : ""}
                      </span>
                    )}
                  </div>{applicants?.loan_stage === "S"? (<> 
                   <div className="d-flex loan-section">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                      <label>Bank name</label>
                      <select
                        className="form-control"
                        value={applicants.bank_name}
                        onChange={handleInputChange}
                        name="bank_name"
                      >
                        <option value="">Select</option>
                        <option value="SBI">SBI</option>
                        <option value="ICICI">ICICI</option>
                        <option value="HDFC">HDFC</option>
                        <option value="KOTAK MAHINDRA">KOTAK MAHINDRA</option>
                        <option value="BANK OF Baroda">Bank of Baroda</option>
                        <option value="BANK OF India">Bank of India</option>
                        <option value="Central Bank of India">
                          Central Bank of India
                        </option>
                        <option value="PNB">PNB</option>
                        <option value="OTHERS">Others</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                      <div className="form-group">
                        <label>Loan Sanction Letter</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => handleFileChange(e)}
                          name="loan_sanction_letter"
                          
                        />
                      </div>
                    </div>
                  </div></>) : ("")} </>) : ""}
                  {applicants?.loan_stage === "U" || applicants?.loan_stage === "U2" ?  (<> 
                   <div className="d-flex loan-section">
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <label>Bank name</label>
                      <select
                        className="form-control"
                        value={applicants.bank_name}
                        onChange={handleInputChange}
                        name="bank_name"
                      >
                        <option value="">Select</option>
                        <option value="SBI">SBI</option>
                        <option value="ICICI">ICICI</option>
                        <option value="HDFC">HDFC</option>
                        <option value="KOTAK MAHINDRA">KOTAK MAHINDRA</option>
                        <option value="BANK OF Baroda">Bank of Baroda</option>
                        <option value="BANK OF India">Bank of India</option>
                        <option value="Central Bank of India">
                          Central Bank of India
                        </option>
                        <option value="PNB">PNB</option>
                        <option value="OTHERS">Others</option>
                      </select>
                    </div>
                   
                  </div></>): ("")}
                </div>
              </div>
              <div className="proceed-button">
                <input type="submit" className="proceed-btn" value="Proceed" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerDetail;

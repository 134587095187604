import React, { useEffect, useState } from "react";
import ClickableImage from "../../../components/modal/ClickableImage";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlistAsync,
  getUserWishlistsAsync,
} from "../../../features/wishlist/wishlistSlice";
import { BASE_URL } from "../../../util/Constant";
import Amenities from "../../../components/card/Amenities";
import Slider from "react-slick";

const SearchResults = ({ project }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state) => state.authorization.isAuthenticated
  );
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const isItemInWishlist = (id) => {
    // Check if wishlist exists and is an array
    if (wishlist && Array.isArray(wishlist)) {
      // Use the some method to check if the itemId is in the wishlist array
      return wishlist?.some((item) => item?.project_id === id);
    } else {
      // Handle the case where wishlist is not an array
      console.error("wishlist is not an array or is not defined.");
      return false; // You can return false or another appropriate value here.
    }
  };
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleWishlist = (id) => {
    dispatch(addToWishlistAsync({ itemId: id, token: accessToken }));
  };
  const handleCallback = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/projects/callback/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
        body: JSON.stringify({ project_id: id }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if (response.ok) {
        alert(
          "Thanks for your callback request. You will get callback from us very soon -Team TDK"
        );
      }

      // setError(null);
    } catch {
      console.log("Error handling callback");
    }
  };

  const formatPossessionDate = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date(); // Get the current date
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    
    if (date < currentDate) {
      return 'Ready to Move'; 
    }
    
    return `${month} ${year}`;
  };


  useEffect(() => {
    dispatch(getUserWishlistsAsync(accessToken));
  }, [dispatch, accessToken]);
  return (
    <div>
      <div className="container">
        <div className="projectCard">
          {project && (
            <div className="row align-items-center">
              <div className="col-lg-9 col-md-9 col-sm-12">
                <p>
                  <Link
                    to={`/detail/${project.id}`}
                    style={{ textDecoration: "none", color: "darkblue" }}
                  >
                    {project.name}
                  </Link>{" "}
                  | {project.address}
                  <a
                    href={project.google_location}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <i
                      className="bi bi-geo-alt"
                      style={{ fontSize: "1.5rem", color: "#012060" }}
                    ></i>
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <p className="possession">
                  <span>possession: {formatPossessionDate(project.possession)}</span>
                </p>
              </div>
              <div className="">
                <div className="row img-section">
                  {isSmallDevice ? (
                    <>
                      <Slider {...sliderSettings}>
                        <div>
                          <ClickableImage
                            src={
                              project.image1
                                ? project.image1
                                : "/images/image_not_available.png"
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          <ClickableImage
                            src={
                              project.image2
                                ? project.image2
                                : "/images/image_not_available.png"
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          <ClickableImage
                            src={
                              project.image3
                                ? project.image3
                                : "/images/image_not_available.png"
                            }
                            alt=""
                          />
                        </div>
                      </Slider>
                    </>
                  ) : (
                    <>
                      <div className="project-images col-lg-8 col-md-8 col-sm-12">
                        <ClickableImage
                          src={
                            project.image1
                              ? project.image1
                              : "/images/pro-1.jpg"
                          }
                          alt="Image 1"
                        />
                        <ClickableImage
                          src={
                            project.image2
                              ? project.image2
                              : "/images/pro-2.jpg"
                          }
                          alt="Image 1"
                        />
                        <ClickableImage
                          src={
                            project.image3
                              ? project.image3
                              : "/images/pro-3.jpg"
                          }
                          alt="Image 1"
                        />
                      </div>
                    </>
                  )}

                  <div className="builder-logo col-lg-2 col-md-2 col-sm-12">
                    <ClickableImage
                      src={
                        project.builder_logo
                          ? project.builder_logo
                          : "/images/partner-3.png"
                      }
                      alt="Image 1"
                    />
                  </div>
                  <div className=" col-lg-2 col-md-2 col-sm-12">
                    

                    <ul>
                      
                      <div
                      className="like-wrapper text-center "
                      onClick={() => {
                        handleWishlist(project?.id);
                      }}
                    >
                      {isLoggedIn ? (
                        <i
                          className={`bi ${
                            isItemInWishlist(project?.id)
                              ? "bi-heart-fill"
                              : "bi-heart"
                          }`}
                          style={{
                            fontSize: "30px",
                            color: isItemInWishlist(project?.id)
                              ? "#012060"
                              : "black",
                          }}
                        ></i>
                      ) : (
                        <Link to="/login" className="like">
                          <i
                            className={`bi bi-heart `}
                            style={{ fontSize: "30px", color: "#012060" }}
                          ></i>
                        </Link>
                      )}
                    </div>
                    <div className="area-registerd text-right">
                  <img src="images/area-registerd.png" alt="" />
                </div>
                      {isLoggedIn ? (
                        <li>
                          <Link to={`/sitevisit/${project?.id}`}>
                            site visit
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link to={`/login`}>site visit</Link>
                        </li>
                      )}
                      {isLoggedIn ? (
                        <li>
                          <Link to={`/buynow/${project?.id}`}>buy now</Link>
                        </li>
                      ) : (
                        <li>
                          <Link to={`/login`}>buy now</Link>
                        </li>
                      )}
                      {isLoggedIn ? (
                        <li>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              handleCallback(project?.id);
                            }}
                          >
                            call back
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link to={`/login`}>call back</Link>
                        </li>
                        
                      )}
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="our-flat-detail d-flex  justify-content-between">
                <div>
                  <div>
                    {project && project.short_description ? (
                      project.short_description
                    ) : (
                      <p> Short Description </p>
                    )}
                  </div>
                  <div>
                    {project && project.size_description ? (
                      project.size_description
                    ) : (
                      <p> Size Description </p>
                    )}
                  </div>
                  <div className="d-flex gap-1 amenities mt-2">
                    <span className="fw-bold">Amenities: </span>
                    {project && project.amenities ? (
                      project.amenities.map((item, index) => (
                        <span key={index}>
                          {" "}
                          <Amenities
                            Id={item}
                            isLast={index === project?.amenities.length - 1}
                          />
                        </span>
                      ))
                    ) : (
                      <p>Amenities</p>
                    )}
                  </div>
                </div>
                <div className="area-registerd text-right">
                  <img src="images/area-registerd.png" alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;

import React, {  useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { BASE_URL } from "../../../util/Constant";
import { useSelector } from "react-redux";

const AddLayoutInAdmin = () => {
  const filteredProject = useSelector (state=>state.project.projects)
  const accessToken = useSelector (state=>state.authorization.accessToken)
  const [selectedProject, setSelectedProject] = useState();
  const {data: bhk } = useFetch("/projects/bhk/");
  const {data: unitTypes } = useFetch("/projects/unit_types/");
  const [errorMessage, setErrorMessage] = useState("");
  
  const intialData = {
    nickname: "",
    bhk: "",
    unit_type: "",
    RERAcarpetarea: "",
    builtuparea: "",
    superbuiltuparea: "",
    layoutimage: "",
    comment: "",
  }
  const [data, setData] = useState(intialData);
  const handleInputChange = (e) => {
    const { name } = e.target;

    if (e.target.type === "file") {
      const selectedFile = e.target.files[0];
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile, // Store the File object directly
      }));
    } else {
      setData({ ...data, [name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
  
      for (const key in data) {
        formData.append(key, data[key]);
      }
  
      const response = await fetch(
        `${BASE_URL}/projects/builder_projects/${selectedProject}/addlayouts/`,
        {
          method: "POST",
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
          body: formData,
        }
      );
      const resp= await response.json();
      if (response.ok) {
        alert("Added Layout Successfully");
        setData(intialData)
      } else {
        setErrorMessage(resp)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
 
  
  return (
      <div className="mains m-2">
        <div className="container add-project">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="add-unit">
              <h1 className="text-center mb-4">Add Layout</h1>
              <div className="form-group">
                  <label >
                    Select the Project in which you want to Add Layout
                  </label>
                  <select
                    className="form-control"
                    value={selectedProject} // Use selectedProject as the value
                    onChange={(e) => setSelectedProject(e.target.value)} // Update selectedProject on change
                    required
                  >
                    <option value="" >Select</option>
                    {filteredProject &&
                      filteredProject?.map((proj) => {
                        return (
                          <option key={proj.id} value={proj.id}>
                            {proj.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              <div className="project-detail">
                
                <div className="form-group">
                  <label htmlFor="name">Nickname*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nickname"
                    name="nickname"
                    value={data?.nickname}
                    onChange={handleInputChange}
                    placeholder="Enter nickname"
                    required
                  />
                  {errorMessage.nickname && (
                          <span
                            className="error-message"
                            style={{ color: "darkred" }}
                          >
                            {errorMessage.nickname[0] ? errorMessage.nickname[0] : ""}
                          </span>
                        )}
                </div>
                
                <div className="form-group">
                  <label htmlFor="bhk">BHK*</label>
                  <select
                    className="form-control"
                    id="bhk"
                    name="bhk"
                    value={data?.bhk}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>Select</option>
                    {Array.isArray(bhk) && bhk?.map((bhk)=>(
              <option key={bhk?.id} value={bhk?.bhk}>{bhk?.bhk} BHK</option>
            ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="unit_types">Unit Types*</label>
                  <select
                    className="form-control"
                    id="unit_type"
                    name="unit_type"
                    value={data?.unit_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>select</option>
                    {Array.isArray(unitTypes) &&
              unitTypes?.map(type => (<option value={type.id}>{type.unittypes}</option>))
            }
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="total_blocks">Reracarpert Area*</label>
                  <input
                    type="number"
                    className="form-control"
                    id="RERAcarpetarea"
                    name="RERAcarpetarea"
                    value={data?.RERAcarpetarea}
                    onChange={handleInputChange}
                    placeholder="Enter Reracarpert Area"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="land_parcel_SqMtr">Builtup Area*</label>
                  <input
                    type="number"
                    className="form-control"
                    id="builtuparea"
                    name="builtuparea"
                    value={data?.builtuparea}
                    onChange={handleInputChange}
                    placeholder="Enter Builtup Area"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="land_parcel_SqMtr">Superbuiltup Area*</label>
                  <input
                    type="number"
                    className="form-control"
                    id="superbuiltuparea"
                    name="superbuiltuparea"
                    value={data?.superbuiltuparea}
                    onChange={handleInputChange}
                    placeholder="Enter Superbuiltup Area"
                    required
                  />
                  {errorMessage.superbuiltuparea && (
                          <span
                            className="error-message"
                            style={{ color: "darkred" }}
                          >
                            {errorMessage.superbuiltuparea[0] ? errorMessage.superbuiltuparea[0] : ""}
                          </span>
                        )}
                </div>
                <div className="form-group">
                  <label htmlFor="land_parcel_SqMtr">Layout Image</label>
                  <input
                    type="file"
                    className="form-control"
                    id="layoutimage"
                    name="layoutimage"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="land_parcel_SqMtr">Comment</label>
                  <input
                    type="text"
                    className="form-control"
                    id="comment"
                    name="comment"
                    value={data?.comment}
                    onChange={handleInputChange}
                    placeholder="comment"
                  />
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className=" buttons">
                  Add Layout
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    
  );
};

export default AddLayoutInAdmin;

import React from 'react'
import './Loader.css'

const Loader = () => {
  return (
    <div className="loading-container">
    <div className="loader">
      <div className="spinner-border" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  </div>
  )
}

export default Loader
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import "./Buynow.css";
// import StepTracker from "../../components/Stepper/StepTracker";
import { BASE_URL } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";
import Stepper from "../../../components/Stepper/StepTracker";
import useFetch from "../../../hooks/useFetch";

const BuyNow = () => {
  const { id } = useParams();
  const [unitsData, setUnitsData] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const { data: view } = useFetch("/projects/view_from_unit/");
  const { data: openDirection } = useFetch("/projects/unit_open_direction/");
  const { data: sunLight } = useFetch("/projects/unit_sunlight/");
  const [layoutNicknames, setLayoutNicknames] = useState({});
  const dispatch = useDispatch();
  // const [criteriaVisible, setCriteriaVisible] = useState(true);

  // const toggleCriteriaVisibility = () => {
  //   // Toggle the visibility of the criteria section
  //   setCriteriaVisible(!criteriaVisible);
  // };
  const property = useSelector((state) => state.project.selectedProject);
  useEffect(() => {
    dispatch(fetchProjectByIdAsync(id));
  }, [dispatch, id]);
  const [criteria, setCrieteria] = useState({
    floor_range: "",
    layout: "",
    facing_of_gate: "",
    view_from_flat: [],
    sun_light: [],
    open_direction: [],
  });
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [layout, setLayout] = useState(null); // Added state for layout data

  const handleUnitSelect = useCallback(
    async (unit) => {
      setSelectedUnit(unit);
      try {
        // Fetch layout data based on the selected unit
        const layoutResponse = await fetch(
          `${BASE_URL}/projects/projects/${id}/layouts/${unit.layout}/`
        );
        const layoutData = await layoutResponse.json();
        // Update the layout state with the selected unit's layout data
        setLayout(layoutData);
      } catch (error) {
        console.error("Error fetching layout data:", error);
      }
    },
    [id, setSelectedUnit, setLayout]
  );
  const fetchUnitsData = useCallback(async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/projects/projects/${id}/units/`
      );
      const data = await response.json();
      setUnitsData(data);

      // If there are units available, get the layout for the first unit by default
      if (data.length > 0) {
        handleUnitSelect(data);
        const uniqueLayouts = [...new Set(data.map((unit) => unit.layout))];
        setLayouts(uniqueLayouts);

        // Fetch layout nicknames and store them in layoutNicknames state
        const layoutNicknameMapping = {};
        for (const layoutOption of uniqueLayouts) {
          const layoutResponse = await fetch(
            `${BASE_URL}/projects/projects/${id}/layouts/${layoutOption}/`
          );
          const layoutData = await layoutResponse.json();
          layoutNicknameMapping[layoutOption] = layoutData.nickname;
        }
        setLayoutNicknames(layoutNicknameMapping);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, setUnitsData, handleUnitSelect, setLayouts, setLayoutNicknames]);

  useEffect(() => {
    fetchUnitsData();
  }, [fetchUnitsData, id]);
  
  const filteredData = unitsData?.filter((unit) => {
    const floorMatch =
      !criteria.floor_range || unit.floor_range === criteria.floor_range;
    const layoutMatch =
      !criteria.layout || unit.layout === parseInt(criteria.layout);
    const facingMatch =
      !criteria.facing_of_gate ||
      unit.facing_of_gate === criteria.facing_of_gate;
      
      const viewMatch =
    criteria.view_from_flat.length === 0 ||
    (unit.view_from_flat &&
      criteria.view_from_flat.every((view) => unit.view_from_flat.includes(view)));

  const openDirectionMatch =
    criteria.open_direction.length === 0 ||
    criteria.open_direction.every((direction) => unit.open_direction.includes(direction));

  const sunlightMatch =
    criteria.sun_light.length === 0 ||
    criteria.sun_light.every((sunlight) => unit.sun_light.includes(sunlight));

    const isMatch =
      floorMatch &&
      layoutMatch &&
      facingMatch &&
      viewMatch &&
      sunlightMatch &&
      openDirectionMatch;

    return isMatch;
  });

  const handleCheckboxChange = (e, key, value) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCrieteria({
        ...criteria,
        [key]: [...criteria[key], value],
      });
    } else {
      // Remove the value from the criteria.open_direction array
      setCrieteria({
        ...criteria,
        [key]: criteria[key].filter((item) => item !== value),
      });
    }
  };

  if (!property) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {layout ? (
        <div className="main">
          <div className="availability">
            <div className="container">
              <Stepper currentPage={0} />
              <div className="project-name">
                <div className="buyer-detail-image">
                  <img
                    src={
                      property.image1
                        ? property?.image1
                        : "/images/image_not_available.png"
                    }
                    alt=""
                  />
                </div>
                <div className="buyer-detail-text">
                  <h3>{property?.name}</h3>
                  <h3>
                    {property?.address}{" "}
                    <a
                      href={property?.google_location}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="bi bi-geo-alt"
                        style={{ fontSize: "1.5rem", color: "#012060" }}
                      ></i>
                    </a>
                  </h3>
                </div>
              </div>
              <div className="avaibility-detail">
              {/* <button
                className="toggle-criteria-btn "
                onClick={toggleCriteriaVisibility}
              >
                <i class="bi bi-funnel"></i> Criteria
              </button> */}
              
                <div className="row">
                
                  <div className="criteria-selection col-lg-3 col-md-3 col-sm-12">
                    <div className="select-criteria">
                      <h5>Select Criteria for easy selection of Unit:</h5>
                      <div className="row">
                        {/* Filter options for layout */}
                        <div>
                          <label>Floor</label>
                          <select
                            value={criteria.floor_range}
                            onChange={(e) =>
                              setCrieteria({
                                ...criteria,
                                floor_range: e.target.value,
                              })
                            }
                          >
                            <option value="">All Floor</option>
                            <option value="Lower">Lower Floor</option>
                            <option value="Middle">Middle Floor</option>
                            <option value="Higer">Upper Floor</option>
                          </select>
                        </div>
                        <div>
                          <label>Layouts</label>
                          <select
                            value={criteria.layout}
                            onChange={(e) =>
                              setCrieteria({
                                ...criteria,
                                layout: e.target.value,
                              })
                            }
                          >
                            <option value="">All Layout</option>
                            {layouts.map((layoutOption) => (
                              <option key={layoutOption} value={layoutOption}>
                                {layoutNicknames[layoutOption] ||
                                  "No Nickname Available"}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Filter options for facing */}
                        <div>
                          <label>Facing of gate</label>
                          <select
                            value={criteria.facing_of_gate}
                            onChange={(e) =>
                              setCrieteria({
                                ...criteria,
                                facing_of_gate: e.target.value,
                              })
                            }
                          >
                            <option value="">All Facing</option>

                            <option value="E">East</option>
                            <option value="W">West</option>
                            <option value="N">North</option>
                            <option value="S">South</option>
                          </select>
                        </div>

                        {/* Filter options for view/overlooking */}
                        <div>
                          <h5>View / Overlooking</h5>
                          
                          {view?.map(({ id, view_from_unit }) => (
                            <div key={id} className="checkbox-criteria">
                              <input
                                type="checkbox"
                                value={id}
                                checked={criteria.view_from_flat.includes(
                                  id
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    "view_from_flat",
                                    id
                                  )
                                }
                              />
                              <label>{`${view_from_unit} Facing`}</label>
                            </div>
                          ))}
                        </div>

                        {/* Filter options for sunLight */}
                        <div>
                          <h5>Sun Light</h5>
                          {sunLight?.map(({id, sunlight}) => (
                            <div key={id} className="checkbox-criteria">
                              <input
                                type="checkbox"
                                id={`${sunlight}Checkbox`}
                                value={sunlight}
                                checked={criteria.sun_light.includes(id)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "sun_light", id)
                                }
                              />
                              <label htmlFor={`${sunlight}Checkbox`}>
                              {sunlight}
                              </label>
                            </div>
                          ))}
                        </div>

                        {/* Filter options for openDirection */}
                        <div>
                          <h5>Open Direction</h5>
                          {openDirection?.map(({ id, unit_open_direction }) => (
                            <div key={id} className="checkbox-criteria">
                              <input
                                type="checkbox"
                                value={unit_open_direction}
                                checked={criteria.open_direction.includes(
                                  id
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    "open_direction",
                                    id
                                  )
                                }
                              />
                              <label>{`${unit_open_direction}`}</label>
                            </div>
                          ))}
                        </div>

                        {/* <div className="criteria-btn" ><button onClick={toggleCriteriaVisibility}>close</button></div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <h5>Available Units:</h5>
                    {filteredData?.length > 0 ? (
                      <div className="filtered-units">
                        {filteredData.map((unit) => (
                          <div
                            key={unit.id}
                            className={`selected-units ${
                              selectedUnit && selectedUnit.id === unit.id
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleUnitSelect(unit)}
                          >
                            {/* <p>Layout: {unit.layout}</p> */}
                            <p>Floor: {unit.floor}</p>
                            <p>Block: {unit.block}</p>
                            <p>Unit Number: {unit.full_unit_number}</p>
                            <p>
                              Status:{" "}
                              {unit.status === "Avl"
                                ? "Available"
                                : unit.status === "BO"
                                ? "Booked"
                                : unit.status === "HO"
                                ? "Hold"
                                : unit.status}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No units available</p>
                    )}
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="select-unit">
                      <ClickableImage
                        src={
                          property.project_layout
                            ? property.project_layout
                            : "/images/image_not_available.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="unit-layout">
                      {layout.layoutimage ? (
                        <ClickableImage
                          src={layout.layoutimage}
                          alt="unit-layout"
                        />
                      ) : (
                        <h4>No Layouts image available for selected unit</h4>
                      )}
                    </div>
                  </div>
                </div>
                <div className="confermation-main">
                  {Array.isArray(selectedUnit) ? (
                    <h3 className="text-center my-5">
                      Select the unit you want to buy by clicking on it to
                      further proceed.
                    </h3>
                  ) : (
                    <>
                      <div className="confermation-detail">
                        <h4>Confirmation</h4>
                        <p>
                          Selected Unit No.: {selectedUnit.full_unit_number}
                        </p>
                        <p>Size: {layout.nickname}</p>
                        <p>
                          Total price: Rs {selectedUnit?.all_inclusive_price}/-
                        </p>
                        <p>Construction Stage: {selectedUnit?.status}</p>
                        <p>
                          Amount Due as per Stage: Rs 14,46,250/- + GST Rs
                          14,463/- (32.5% + GST)
                        </p>
                        <p>
                          This Amount is to be paid in 1 month of ATS
                          Registration / 45 days off booking.
                        </p>
                      </div>
                      <div className="confermation-btn">
                        <ul>
                          <li>
                            <Link
                              to={`/buynow/${property.id}/buynowconformation/${selectedUnit.layout}/${selectedUnit.id}`}
                            >
                              Book Now
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="container text-center">No Units  are available</h1>
      )}
    </div>
  );
};

export default BuyNow;

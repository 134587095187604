import React, {  useState } from "react";
import './ProjectDetails.css'
import CityName from "../../../components/card/CityName";
import { useSelector } from "react-redux";

const ProjectDetails = () => {
  const projects = useSelector(state=>state.project.projects)

  const [filters, setFilters] = useState({
    projectName: "",
    city: "",
    layout: "",
    status: "",
  });
  // Extract unique project names, cities, layouts, and statuses
  const projectNames = [...new Set(projects?.map((project) => project.name))];
  const cities = [...new Set(projects?.map((project) => project.city))];
  const layouts = [...new Set(projects?.flatMap((project) => project.layout))];
  const statuses = [
    ...new Set(
      projects?.flatMap((project) => project.unit.map((unit) => unit.status))
    ),
  ];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const filteredProjects = projects?.filter((project) => {
    const { projectName, city, status, layout } = filters;
    return (
      (projectName === "" ||
        project.name.toLowerCase() === projectName.toLowerCase()) &&
      (city === "" || project.city === parseInt(city)) &&
      (status === "" || project.unit.some((unit) => unit.status === status)) &&
      (layout === "" || project.unit.some((unit) => unit.layout === parseInt(layout)))
      
    );
  });

  let serialNumber = 1;

  return (
    <div>
      <div className="container project-details">
        <h3 className="mt-2">Projects Details</h3>

        {/* Filter Section */}
        <div className="filter-card d-flex justify-content-between">
          <div>
          <label htmlFor="projectFilter">Filter by Name</label>
          <select
            name="projectName"
            value={filters.projectName}
            onChange={handleFilterChange}
          >
            <option value="">All Projects</option>
            {/* Populate options based on unique project names */}
            {projectNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by City</label>
          <select
            name="city"
            value={filters.city}
            onChange={handleFilterChange}
          >
            <option value="">All Cities</option>

            {cities.map((city) => (
              <option key={city} value={city}>
             
                <CityName Id={city} />
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by Layouts</label>
          <select
            name="layout"
            value={filters.layout}
            onChange={handleFilterChange}
          >
            <option value="">All Layouts</option>
            {/* Populate options based on unique layout names */}
            {layouts.map((layout) => (
              <option key={layout.id} value={layout.id}>
                {layout.nickname}
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by Status</label>
          <select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {statuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          </div>
          
        </div>
        <div className="project-details">
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Project Name</th>
                <th>City</th>
                <th>Layout</th>
                <th>Unit No.</th>
                <th>Status</th>
                <th>Basic rate per sqft(&#8377;)</th>
                <th>Extra charge per sqft(&#8377;)</th>
                <th>GST(&#8377;)</th>
                <th>Saledeed value(&#8377;)</th>
                <th>Legal fee(&#8377;)</th>
                <th>Other charges</th>
                <th>Stamp duty(&#8377;)</th>
                <th>Registration fee(&#8377;)</th>
                <th>Price(&#8377;)</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredProjects?.map((project) =>
                project.unit.map((unit) => {
                  const layout = project.layout.find(
                    (lay) => lay.id === unit.layout
                  );
                  return (
                    <tr key={unit.id}>
                      <td>{serialNumber++}</td>
                      <td>{project.name}</td>
                      <td>
                        <CityName Id={project?.city} />
                      </td>
                      <td>{layout ? layout.nickname : ""}</td>
                      <td>{unit.full_unit_number}</td>
                      <td>{unit.status}</td>
                      <td>{unit ? unit.basic_rate_per_sqft : ""}</td>
                      <td>{unit ? unit.extra_charges_per_sqft : ""}</td>
                      <td>{unit ? unit.gst : ""}</td>
                      <td>{unit ? unit.saledeed_value : ""}</td>
                      <td>{unit ? unit.legal_fees : ""}</td>
                      <td>{unit ? unit.other_charges : ""}</td>
                      <td>{unit ? unit.stamp_duty_amount : ""}</td>
                      <td>{unit ? unit.registration_fees_amount : ""}</td>
                      <td>{unit ? unit.all_inclusive_price : ""}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;

import React from "react";
import SearchBox from "../../../components/searchBox/SearchBox";
import "./Search.css";
// import SearchResults from "./SearchResults";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";

const Search = () => {
  // const project = useSelector((state) => state.project.filteredProjects);
  const isLoading = useSelector((state) => state.project.isLoading);
 
  return (
    <div className="container search">
      <SearchBox />
      {isLoading ? (
        <Loader/>
      ) : (
        <>
          {/* {project && (
            <p
              style={{
                color: "#12306c",
                textAlign: "center",
              }}
            >
              Found "{project?.length}" results from your selected filters
            </p>
          )} */}
          {/* {project && project?.map((proj) => <SearchResults project={proj} />)} */}
        </>
      )}
    </div>
  );
};

export default Search;

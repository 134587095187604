import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../util/Constant";
import { Link } from "react-router-dom";

const LoanApplication = () => {
  const [application, setApplication] = useState([]);
  const accessToken = useSelector((state) => state.authorization.accessToken);

  useEffect(() => {
    const getApplicants = async () => {
      try {
        const response = await fetch(`${BASE_URL}/finance/loanapplication/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
        });

        const result = await response.json();
        setApplication(result);
        if (!response.ok) {
          console.log("error Fetching loans");
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    getApplicants(); // Call getCreditCards inside useEffect
  }, [accessToken]);
  return (
    <div>
      <div>
        <h2 className="text-center">Loan Application</h2>
        <div>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Type</th>
                <th>Applicant</th>
                <th>Co Applicant</th>
                <th>No. of Applicant</th>
                <th>status</th>
                <th>Date Created </th>
                <th>Date Updated </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {application &&
                application?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.loan_type} </td>
                    <td>{item.applicant} </td>
                    <td>{item.coapplicant_1} </td>
                    <td>{item.number_of_coapplicants}</td>
                    <td>{item.number_of_coapplicants}</td>
                    <td>{item.created_date}</td>
                    <td>{item.last_updated}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {application && application.length > 0 ? (
          ""
        ) : (
          <>
            <span>No application created yet. </span>
            <Link to="/application" className="navigate-btn" >Create Now</Link>
          </>
        )}
      </div>
    </div>
  );
};

export default LoanApplication;

import React, { useState } from "react";

const RequiredDocs = () => {
  const [status, setStatus] = useState("Indian");
  const [occupation, setOccupation] = useState("salaried");

  return (
    <div>
      <h3 className="text-center">Required Documentes</h3>
      <div className="form-group col-lg-6 col-sm-12 col-md-12 d-flex gap-4 align-items-center">
        <label htmlFor="name">Resident Status : </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            id="Indian"
            checked= {status ==="Indian"}
            value="Indian"
          />
          <label  htmlFor="Male">
            Indian
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            id="NRI/OCI/PIO"
            value="NRI/OCI/PIO"
          />
          <label  htmlFor="Felmale">
            NRI/OCI/PIO
          </label>
        </div>
      </div>
      <div className="form-group col-lg-6 col-sm-12 col-md-12 d-flex gap-4 align-items-center">
        <label htmlFor="name">Occupation : </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="occupation"
            onChange={(e) => setOccupation(e.target.value)}
            checked= {occupation ==="salaried"}
            id="salaried"
            value="salaried"
          />
          <label  htmlFor="Male">
            Salaried
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="occupation"
            onChange={(e) => setOccupation(e.target.value)}
            id="self_employed"
            value="self_employed"
          />
          <label  htmlFor="Felmale">
            Self Employed
          </label>
        </div>
      </div>
      {status === "Indian" ? (
        <>
          <div>
            {occupation === "salaried" ? (
              <>
                <ol>
                  <li>Any of following ID Proof : PAN Card</li>
                  <li>
                    Any of following ID Proof for Address: Aadhar Card, Voter
                    ID, Driving License, Ration Card, Passport,Rent
                    Agreement,Electricity Bill, Property Tax Bill etc.
                  </li>
                  <li>Salary Slip of Last 3 months</li>
                  <li>Bank statement of last 6 months</li>
                  <li>
                    1 year Statement(s) and Sanction letter of existing loan(s),
                    If any.
                  </li>
                  <li>Last month statement(s) of Credit card(s) , if any.</li>
                  <li>Proof of assets like LIC, NSC, KVP, PPF, MF, Property</li>
                </ol>
              </>
            ) : (
              <>
                <ol>
                  <li>Any of following ID Proof :PAN Card</li>
                  <li>
                    Any of following ID Proof for Address: Aadhar Card, Voter
                    ID, Driving License, Ration Card, Passport,Rent
                    Agreement,Electricity Bill, Property Tax Bill etc.
                  </li>
                  <li>Bank statement of last 6 months</li>                  
                  <li>Proof of assets like LIC, NSC, KVP, PPF, MF, Property</li>                  
                  <li>Business/Profession's income tax returns with Balance sheets, P&L</li>
                  <li>Business Proof (Valid Registrations, any 2), Place of business & its Proof</li>
                </ol>
              </>
            )}
          </div>
        </>
      ) : (
        <>
        <div>
            {occupation === "salaried" ? (
              <>
                <ol>
                  <li>Any of following ID Proof : PAN Card</li>
                  <li>
                    Any of following ID Proof for Residence(India): Aadhar Card, Voter
                    ID, Driving License, Ration Card, Passport,Rent
                    Agreement,Electricity Bill, Property Tax Bill etc.
                  </li>
                  <li>Proof of Residence (Abroad)</li>
                  <li>Local Contact Person - Name, Relation, Address, Contact Number</li>
                  <li>Credit Check report from Overseas Credit Card Bureau(if available)</li>
                  
                  <li>NRE Saving Bank statement of last 6 months</li>
                  <li>Overseas Saving Bank statement of last 6 months</li>
                  <li>
                    1 year Statement(s) and Sanction letter of existing loan(s),
                    If any.
                  </li>
                  <li>Last month statement(s) of Credit card(s) , if any.</li>
                  <li>Proof of assets like LIC, NSC, KVP, PPF, MF, Property</li>
                  <li>Certified Salary Slip of Last 6 months</li>
                  <li>Copy of employement Contract</li>
                  <li>Copy of ITR(India/Overseas)</li>
                  <li>Copy of identity card issued by current Employer</li>
                  <li>Continuous discharge certificate, if applicable.</li>
                  <li>Copy of latest work permit</li>
                  <li>Additional Document (PIO only) : 
                    <ol>
                      <li>Current passport indicating birth place India/Abroad</li>
                      <li>Indian Passport, if held earlier</li>
                      <li>Parent or Grandparents passport with details therein substantiating applicant's claim being a PIO</li>
                    </ol>
                  </li>
                </ol>
              </>
            ) : (
              <>
                <ol>
                <li>Any of following ID Proof : PAN Card</li>
                  <li>
                    Any of following ID Proof for Residence(India): Aadhar Card, Voter
                    ID, Driving License, Ration Card, Passport,Rent
                    Agreement,Electricity Bill, Property Tax Bill etc.
                  </li>
                  <li>Proof of Residence (Abroad)</li>
                  <li>Local Contact Person - Name, Relation, Address, Contact Number</li>
                  <li>Credit Check report from Overseas Credit Card Bureau(if available)</li>
                  
                  <li>NRE Saving Bank statement of last 6 months</li>
                  <li>Overseas Saving Bank statement of last 6 months</li>
                  <li>
                    1 year Statement(s) and Sanction letter of existing loan(s),
                    If any.
                  </li>
                  <li>Last month statement(s) of Credit card(s) , if any.</li>
                  <li>Proof of assets like LIC, NSC, KVP, PPF, MF, Property</li>
                  <li>Individual's ITR(India/Overseas) - 3 years</li>
                  <li>Business/Profession's income tax returns with Balance sheets, P&L</li>
                  <li>Business Proof (Valid Registrations, any 2), Place of business & its Proof</li>
                  <li>Continuous discharge certificate, if applicable.</li>
                  <li>Additional Document (PIO only) : 
                    <ol>
                      <li>Current passport indicating birth place India/Abroad</li>
                      <li>Indian Passport, if held earlier</li>
                      <li>Parent or Grandparents passport with details therein substantiating applicant's claim being a PIO</li>
                    </ol>
                  </li>
                </ol>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RequiredDocs;

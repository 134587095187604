import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBuilderDetailsAsync } from '../../../features/user/userSlice';

const Profile = () => {
    // const {builderData} = useBuilderDetail()
    const dispatch = useDispatch()
    const builderData = useSelector(state=>state.user.userDetail)
    const accessToken = useSelector(state=>state.authorization.accessToken)
    useEffect(() => {
      dispatch(getBuilderDetailsAsync(accessToken));
    }, [dispatch,accessToken]);
  return (
    <div>
        <div className="container">
      <div className="profile">
        <div className="profile-right">
          <h3>My Profile</h3>

          <div className="profile-detail">
            <div className="profile-photo">
                <img
                  src={builderData?.logo ? builderData?.logo : "images/NotAvailble.png"
                  }
                  alt=""
                />
             
            </div>
            <div className="details">
              <p>
                Name:{" "}
                <span>
                  {builderData?.name} {builderData?.last_name}
                </span>
              </p>
              <p>
                Office Address: <span>{builderData?.office_address}</span>
              </p>
              <p>
               Office Mobile No.:{" "}
                <span>
                  {builderData ? builderData.office_phone || "Not available" : ""}
                </span>
              </p>
              <p>
              Operation since:{" "}
                <span>
                  {builderData ? builderData.operational_since || "Not available" : ""}
                </span>
              </p>
              
                <p>
                  Founder:{" "}
                  <span>
                    {builderData
                      ? builderData.founder || "Not available"
                      : ""}
                  </span>
                </p>
            
                <p>
                  Past Projects:{" "}
                  <span>
                    {builderData
                      ? builderData.past_projects || "Not available"
                      : ""}
                  </span>
                </p>
                <p>
                  Current Projects:{" "}
                  <span>
                    {builderData
                      ? builderData.current_projects || "Not available"
                      : ""}
                  </span>
                </p>
            
                
            
             
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Profile
export   const BASE_URL = process.env.REACT_APP_API_PATH

const headers = {
    'content-type': 'application/json'
};

export const fetchDataFromApi = async (url, params) => {
    try {
        const response = await fetch(BASE_URL + url + "?" + new URLSearchParams(params), {
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.error(err);
        return err;
    }
};
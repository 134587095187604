import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const OpenDirection = (props) => {
    const [opendirection, setOpendirection] = useState('');

    useEffect(() => {
      if (props.ID) {        
        const apiUrl = `${BASE_URL}/projects/unit_open_direction/${props.ID}`;        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const direction = data.unit_open_direction;
            setOpendirection(direction);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [props.ID]);
    return (
        <div>
        {opendirection && (
          <>
            <span>{opendirection}</span>
            {props.isLast ? '' : ', '}
          </>
        )}
      </div>
    )
  }

export default OpenDirection
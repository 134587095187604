import React from 'react';
import './Stepper.css' 


function Stepper({currentPage}) {

  const stepLabels = ['Select Units', 'Terms & Conditions', 'Complete KYC', 'Payment'];
  return (
    <div>
      <div className="progress-bar">
      <div className="progress" style={{ width: "100%" }}>
          {stepLabels.map((label, index) => (
            <div
              key={index}
              className={`step-label ${index < currentPage ? 'completed' : ''}` }
              
            >
              {label}
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
}

export default Stepper;

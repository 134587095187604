import React, { useState,useEffect } from "react";
import "./addCard.css"
import { FaPlusCircle, FaWindowClose } from "react-icons/fa";
import { BASE_URL } from "../../../../util/Constant";
import useFetch from "../../../../hooks/useFetch";
import { useSelector } from "react-redux";


const AddCreditCard = ({id}) => {
  const {data:banks} = useFetch("/finance/bankfi/")
  const [addCard, setAddCard] = useState(false);
  const [cards, setCards] = useState([]);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const [formData, setFormData] = useState({
    bank_fi:"",
    limit:"",
    statement:""
  })

  const handleInputChange = (e) => {
    const { type, name, value, files } = e.target;
  
    // If it's a file input, update the FormData with the file
    if (type === "file") {
      const  selectedFile  = files[0];
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedFile, // Use files array for file inputs
      }));
    } else {
      // If it's not a file input, update the FormData with the value
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", formData);

    const formDataToSend = new FormData(); // Create a new FormData object
  formDataToSend.append("bank_fi", formData.bank_fi); // Append all fields
  formDataToSend.append("limit", formData.limit);
  formDataToSend.append("statement", formData.statement);
    try {

      const response = await fetch(`${BASE_URL}/finance/applicants/${id}/creditcards/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization :  `JWT ${accessToken}`
        },
        body: JSON.stringify(formDataToSend),
      });
      if (response.ok) {
        alert("Credit card added successfully");
        setAddCard(false)
        // window.location.reload()
      }else{
        alert("Error adding card")
        setAddCard(false)
      }
    } catch (error) {
      console.log("error ", error);
      
    }
  };

  useEffect(() => {
    const getCreditCards = async () => {
      try {
        const response = await fetch(`${BASE_URL}/finance/applicants/${id}/creditcards/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
        });

        const result = await response.json();
        setCards(result);
        if (!response.ok) {
          console.log("error Fetching loans");
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    getCreditCards(); // Call getCreditCards inside useEffect

  }, [id, accessToken]);
  return (
    <div>
      <div className="heading">
        <h4>Credit Cards: </h4>
        {addCard ?<FaWindowClose onClick={() => setAddCard(false)} className="add-btn"/> :  <FaPlusCircle
          className="add-btn"
          onClick={() => setAddCard(true)}
        /> }
      </div>
      {addCard ? (
        <form className="row " onSubmit={handleSubmit}>
          <div className="form-group col-sm-4 col-sm-4 col-lg-4">
            <label htmlFor="name">Bank Name </label>
            <select
              className="form-control"
              id="bank_fi"
              name="bank_fi"
              value={formData?.bank_fi}
              onChange={handleInputChange}
              required
            >
              <option value="">Select </option>
              {banks && banks.map((bank,i)=><option key={i} value={bank.id}>{bank.name}</option>)}
            </select>
          </div>
         
          <div className="form-group col-sm-4 col-sm-4 col-lg-4">
            <label htmlFor="name">Limit</label>
            <input
              type="number"
              className="form-control"
              id="limit"
              name="limit"
                value={formData?.limit}
                onChange={handleInputChange}
              placeholder="Enter Limit of your card"
              required
            />
          </div>
         
         
         
          <div className="form-group col-sm-4 col-sm-4 col-lg-4">
            <label htmlFor="name">statement</label>
            <input
              type="file"
              className="form-control"
              id="statement"
              name="statement"
              onChange={handleInputChange}
              required
            />
          </div>

           <div className="text-center">
          <button type="submit" className={`m-2 btn btn-primary `}>
            Add Card
          </button>
        </div>
        </form>
      ) : (
        <div>
          <table>
            <thead>
              <tr className="text-center">
                <th>No.</th>
                <th>Bank/FI</th>
                <th>Issuer</th>
                <th>Limit </th>
              </tr>
            </thead>
            <tbody className="text-center">
            {cards &&
              cards?.map((card, index) => (
                <tr key={card.id}>
                  <td>{index + 1}</td>
                  <td>{card.bank_fi}</td>
                  <td>{card.applicant_id}</td>
                  <td>{card.limit}</td>
                 
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};;

export default AddCreditCard;

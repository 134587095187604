import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSiteVisits } from "./sitevisitApi";

export const getSiteVisitsAsync = createAsyncThunk("sitevisits/getSiteVisits",
async (token) =>{
  try {
    let response = await getSiteVisits(token)
  return response.data;

  } catch (error) {
    console.log(error)
  }


} 
)
const siteVisitSlice = createSlice({
    name : "sitevisit",
    initialState:{
      visits: [],
      error : null,
      isLoading : false,

    },
    reducers:{},
    extraReducers: (builder)=>{
        builder
        .addCase(getSiteVisitsAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getSiteVisitsAsync.fulfilled, (state, action) => {
            state.visits = action.payload;
            state.isLoading = false;
          })
          .addCase(getSiteVisitsAsync.rejected, (state, action) => {
            state.error = action.error;
          })
    }
})

export default siteVisitSlice.reducer
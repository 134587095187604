import React, {  useState } from 'react'
import { Link } from 'react-router-dom';
import '../../builder/layouts/Layout.css'
import useFetch from '../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import UnitType from '../../../components/card/UnitType';

const Layouts = () => {
  const [selectedProjId, setSelectedProjID] = useState('')
  const projects =  useSelector(state=>state.project.projects)
  const {data: layouts} = useFetch(`/projects/projects/${selectedProjId}/layouts/`)
 const [filter, setFilter] = useState({
  unit_type : "",
 })

const sortedLayouts = layouts

  return (
    <div className='container'>
      
      <div className='d-flex justify-content-between'>
      <h1 className="text-center mb-4">All Layouts</h1>
      <h1><Link to="/addLayoutInAdmin"><i className="bi bi-plus-circle"></i></Link></h1>
      </div>
      
      <div className="filter-card d-flex justify-content-around">
        
        <div>
          <label htmlFor="projectFilter">Select Project</label>
          <select
          className='form-control'
          name='project'
          value={selectedProjId}
          onChange={(e)=>setSelectedProjID(e.target.value)}
          >
            <option value="">Select Project</option>
            {
              projects?.map(type => (<option value={type.id}>{type.name}</option>))
            }
          </select>
        </div>  
        <div>
          <label htmlFor="projectFilter">Filter by Unit type</label>
          <select
          className='form-control'
          name='unit_type'
          value={filter.unit_type}
          onChange={(event)=>{setFilter({...filter,[event.target.name]: event.target.value})}}
          >
            <option value="">All Unit type</option>
            {/* {
              unitTypes?.map(type => (<option value={type.id}>{type.unittypes}</option>))
            } */}
          </select>
        </div>
      </div>
      <div className="project-details layout-details" style={{overflowX:"auto", marginBottom:"20px"}}>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Nickname</th>
                <th>BHK</th>
                <th>Unit type</th>
                <th>Reracarpert Area</th>
                <th>Builtup Area</th>
                <th>Superbuiltup Area</th>
                <th>Layout Image</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody className="text-center">
               {sortedLayouts?.length>0 && sortedLayouts?.map((layout,index) =>
                  (
                    <tr key={layout.id}>
                      <td>{index+1}</td>
                      <td>{layout.nickname ? layout.nickname : `nickname${index+1}`}</td>
                      <td>{layout.bhk.length > 0 && layout.bhk[0].bhk}</td>
                      {/* <td>{layout.unit_type}</td> */}
                      <td>{layout.unit_type ? <UnitType Id = {layout.unit_type}/> : "Not Avl"}</td>
                      <td>{layout.RERAcarpetarea ? layout.RERAcarpetarea : "Not Available"}</td>
                      <td>{layout.builtuparea ? layout.builtuparea : "Not Available"}</td>
                      <td>{layout.superbuiltuparea ? layout.superbuiltuparea : "Not Available"}</td>
                      <td>
                        <img 
                          src={layout.layoutimage} 
                          alt={` ${index + 1 }`}
                          style={{width:"100px", height:"100px", objectFit:"cover"}}
                        />
                      </td>
                      <td>{layout.comment ? layout.comment : "comment"}</td>
                    </tr>
                )
              )} 
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Layouts;

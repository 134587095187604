import React from 'react'

 const Confirmation = () => {
	
  return (
    <div className="unit-block-confermation">
		<div className="container">
			<div className="unit-conformation-detail">
				<div className="row align-items-center">
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="block-confermation-left">
							<img src="/images/unit-confirmation.png" alt=''/>
						</div>
					</div>
					<div className="col-lg-8 col-md-8 col-sm-12">
						<div className="block-confirmation-right">
							<p>Congratulations!</p>
							<p>You have taken the first Step. Your selected flat has been booked.</p>
							<p>Just wait for Verification call from us and very soon your booking will be confirmed.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}
export default Confirmation;
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const BlockName = (props) => {
    const [blockName, setBlockName] = useState('');

    useEffect(() => {
      if (props.ID) {        
        const apiUrl = `${BASE_URL}/projects/block/${props.ID}`;        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const name = data.block;
            setBlockName(name);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [props.ID]);
    return (
      <>
          {blockName && <span>{blockName}</span>}
      </>
    )
  }

export default BlockName
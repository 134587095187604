import React, { useEffect, useState } from "react";
import ProjectName from "../../../components/card/ProjectName";
import { useNavigate } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import Slider from "react-slick";
import "./ConstStatus.css";
import download from "downloadjs";
// import BlockName from "../Utils/BlockName";
import {useDispatch, useSelector } from "react-redux"
import { getConstStatusAsync } from "../../../features/builder/builderSlice";
const ConstStatus = ({ selectedProjId }) => {
  // const [data, setData] = useState();
  const dispatch = useDispatch()
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const data = useSelector(state=>state.builder.ConstStatus)
  const navigate = useNavigate();
  const [block, setBlock] = useState("");
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const addConstStatus = () => {
    navigate(`/addconststatus/${selectedProjId}/`);
  };
 
  useEffect(() => {
    dispatch(getConstStatusAsync({token:accessToken, projeId:selectedProjId}))
  }, [dispatch,selectedProjId,accessToken]);
 
  const downloadFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        download(blob);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const filteredData = data?.filter((data) => data.project === selectedProjId);
  const sortedData =
    filteredData &&
    [...filteredData].sort((a, b) => new Date(b.date) - new Date(a.date));
  const blockName = [...new Set(filteredData?.map((block) => block.block))];
  const sortedStatus = filteredData?.filter((status) => {
    return block === "" || parseInt(block) === status.block;
  });

  return (
    <div>
      {filteredData && filteredData.length > 0 ? (
        <div className="main ">
          <div className="heading mb-3 text-center">
            <h3 className="text-center">
              Construction Status : <ProjectName ID={selectedProjId} />
            </h3>
            <h1>
              <div onClick={addConstStatus}>
                <i className="bi bi-plus-circle"></i>
              </div>
            </h1>
          </div>
          <div className="mb-4">
            <h5>Last Updated On : {sortedData[0]?.date}</h5>
          </div>
          <div className="detail">
            <div className="detail-left">
              {data ? (
                <Slider {...sliderSettings}>
                  <div className="customer-image">
                    <ClickableImage
                      src={
                        sortedData[0]?.image1
                          ? sortedData[0]?.image1
                          : "/images/pro-1.jpg"
                      }
                      alt="Image 1"
                    />
                  </div>
                  <div className="customer-image">
                    <ClickableImage
                      src={
                        sortedData[0]?.image2
                          ? sortedData[0]?.image2
                          : "/images/pro-2.jpg"
                      }
                      alt="Image 1"
                    />
                  </div>
                  <div className="customer-image">
                    <ClickableImage
                      src={
                        sortedData[0]?.image3
                          ? sortedData[0]?.image3
                          : "/images/pro-3.jpg"
                      }
                      alt="Image 1"
                    />
                  </div>
                  <div className="customer-image">
                    <ClickableImage
                      src={
                        sortedData[0]?.image4
                          ? sortedData[0]?.image4
                          : "/images/pro-1.jpg"
                      }
                      alt="Image 1"
                    />
                  </div>
                </Slider>
              ) : (
                <div>Loading...</div>
              )}
            </div>
            <div className="detail-right">
              <p>Block : {filteredData[0]?.block}</p>
              <p>Status: {filteredData[0]?.status}</p>
              <p>Description: {filteredData[0]?.description}</p>
              <p>Payment Due: {filteredData[0]?.payment_due}</p>
              <button
                onClick={() =>
                  downloadFile(data[0]?.construction_update_document)
                }
              >
                Download Construction Report
              </button>
            </div>
          </div>
          <div className="history">
            <div className="d-flex justify-content-between mt-5">
              <h3 >History </h3>

              <select
                className="block-filter"
                name="block"
                value={block}
                onChange={(e) => {
                  setBlock(e.target.value);
                }}
              >
                <option value="">All Block</option>
                {blockName.map((name) => (
                  <option key={name} value={name}>
                    {name} 
                  </option>
                ))}
              </select>
            </div>
            <div className="project-details">
              <table>
                <thead>
                  <tr className="text-center">
                    <th>Sr No.</th>
                    <th>Date</th>
                    <th>Image</th>
                    <th>Block</th>
                    <th>Status</th>
                    <th>Payment due</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {sortedStatus &&
                    sortedStatus?.map((data, index) => (
                      <tr key={data.id}>
                        <td>{index + 1}</td>
                        <td>{data.date}</td>
                        <td>
                          <img src={data.image1} alt={` ${index + 1}`} />
                        </td>
                        <td>{data.block}</td>
                        <td>{data.status}</td>
                        <td>{data.payment_due}</td>
                        <td>{data.description}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-center">
            No Construction status Available for selected project
          </h3>
          <h3 className="d-flex justify-content-center">
            Add Construction Status :{" "}
            <div onClick={addConstStatus}>
              <i className="bi bi-plus-circle"></i>
            </div>
          </h3>
        </div>
      )}
    </div>
  );
};

export default ConstStatus;

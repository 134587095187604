import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HomeLoan.css";
import CreditAndLoan from "../../loan/creditAndloan/CreditAndLoan";
import Application from "../application/Application";
import ApplicantProfile from "./ApplicantProfile";
import RequiredDocs from "./RequiredDocs";
import LoanApplication from "./LoanApplication";
import Offers from "./Offers";
import Calculator from "./calculator/Calculator";

const HomeLoan = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const page = () => {
    switch (selectedOption) {
      case "application":
        return <LoanApplication />;

      case "creditcardAndLoan":
        return <CreditAndLoan />;

      case "profile":
        return <ApplicantProfile />;

      case "calculator":
        return <Calculator />;

      case "requiredDocs":
        return <RequiredDocs />;

      case "offers":
        return <Offers />;

      default:
        return (
          <>
            <h3>Step to Apply for Home Loan : </h3>
            <div className="mt-4 mx-4">
              <h5>Step 1 : Add Applicants </h5>
              <div class="stepper d-flex flex-column mt-2 mx-3">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      1
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                      Click on the Applicants Profile button showing the left
                      side
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      2
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                      Then Click on Add Now button to add applicants
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      3
                    </div>
                    <div class="line h-100 d-none"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                      Fill the three page form and submit the form to add
                      Applicant
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-2 mx-4">
            <h5>Step 2 : Create Applicantion</h5>
              <div class="stepper d-flex flex-column mt-2 mx-3">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      1
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                    Click on the Loan Application button showing the left side
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      2
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                    Then Click on Create Now button to create application
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">
                      3
                    </div>
                    <div class="line h-100 d-none"></div>
                  </div>
                  <div>
                    <p class="  px-2 pt-2 pb-4">
                    Fill the Home Loan Application form and submit it to create
                  the application
                    </p>
                  </div>
                </div>
              </div></div>
          </>
        );
    }
  };
  return (
    <div className="container mt-3">
      {/* <h1 className="text-center">Loan Dashboard</h1> */}
      <div className="dashboard row">
        <div className="options col-sm-3">
          <button
            onClick={() => setSelectedOption("profile")}
            className={`options-btn ${
              selectedOption === "profile" ? "selected" : ""
            }`}
          >
            {" "}
            Applicant Profile
          </button>
          <button
            onClick={() => setSelectedOption("application")}
            className={`options-btn ${
              selectedOption === "application" ? "selected" : ""
            }`}
          >
            Loan Application
          </button>
          <button
            onClick={() => setSelectedOption("creditcardAndLoan")}
            className={`options-btn ${
              selectedOption === "creditcardAndLoan" ? "selected" : ""
            }`}
          >
            Credit Card & Existing Loan
          </button>
          <button
            onClick={() => setSelectedOption("status")}
            className={`options-btn ${
              selectedOption === "status" ? "selected" : ""
            }`}
          >
            Check Status
          </button>
          <button
            onClick={() => setSelectedOption("offers")}
            className={`options-btn ${
              selectedOption === "offers" ? "selected" : ""
            }`}
          >
            Offers
          </button>
          <button
            onClick={() => setSelectedOption("calculator")}
            className={`options-btn ${
              selectedOption === "calculator" ? "selected" : ""
            }`}
          >
            Calculator
          </button>
          <button
            onClick={() => setSelectedOption("requiredDocs")}
            className={`options-btn ${
              selectedOption === "requiredDocs" ? "selected" : ""
            }`}
          >
            Required Documents
          </button>
        </div>
        <div className="col-sm-9">{page()}</div>
      </div>
    </div>
  );
};

export default HomeLoan;

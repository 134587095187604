import useFetch from "../../../hooks/useFetch";
import "./AddProject.css";

const AddProjDetail = ({ handleInputChange, data }) => {
  const { data: area } = useFetch("/projects/area/");
  const { data: city } = useFetch("/projects/city/");
  const { data: bhk } = useFetch("/projects/bhk/");
  const { data: amenities } = useFetch("/projects/amenities/");

  const filteredAreaData = area?.filter(
    (area) => area.city === parseInt(data?.city)
  );

  return (
    <div className="container ">
      {/* <h1 className="text-center mb-3  " >{title[page]}</h1> */}
      <div className="project-detail">
        <div className="form-group">
          <label htmlFor="name">Project Name*</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            placeholder="Enter Project Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="rera_number">RERA Number*</label>
          <input
            type="text"
            className="form-control"
            id="rera_number"
            name="rera_number"
            value={data?.rera_number}
            onChange={handleInputChange}
            placeholder="Enter RERA Number"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address*</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={data?.address}
            onChange={handleInputChange}
            placeholder="Enter Address"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="google_location">Google Location*</label>
          <input
            type="text"
            className="form-control"
            id="google_location"
            name="google_location"
            value={data?.google_location}
            onChange={handleInputChange}
            placeholder="Enter Google Location"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City*</label>
          <select
            className="form-control"
            id="city"
            name="city"
            value={data?.city}
            onChange={handleInputChange}
            required
          >
            <option value="">Select City</option>
            {city?.map((area) => (
              <option key={area.id} value={area.id}>
                {area.city}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="area">Area*</label>

          <select
            className="form-control"
            id="area"
            name="area"
            value={data?.area}
            onChange={handleInputChange}
          >
            <option value="">Select area</option>
            {filteredAreaData?.map((area) => (
              <option key={area.id} value={area.id}>
                {area.area}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="macro_details">Macro Details*</label>
          <textarea
            className="form-control"
            id="macro_details"
            name="macro_details"
            value={data?.macro_details}
            onChange={handleInputChange}
            placeholder="Enter Macro Details"
            required
            rows={3} // Set the number of visible rows
          />
        </div>

        <div className="form-group">
          <label htmlFor="micro_details">Micro Details*</label>
          <textarea
            className="form-control"
            id="micro_details"
            name="micro_details"
            value={data?.micro_details}
            onChange={handleInputChange}
            required
            placeholder="Enter Micro Details"
            rows={3}
          />
        </div>
        <div className="form-group">
          <label htmlFor="possession">Possession*</label>
          <input
            type="date"
            className="form-control"
            id="possession"
            name="possession"
            value={data?.possession}
            onChange={handleInputChange}
            placeholder="Enter Possession Date"
          />
        </div>
        <div className="form-group">
          <label htmlFor="total_floors">Total Floors*</label>
          <input
            type="number"
            className="form-control"
            id="total_floors"
            name="total_floors"
            value={data?.total_floors}
            onChange={handleInputChange}
            placeholder="Enter Total Floors"
          />
        </div>
        <div className="form-group">
          <label htmlFor="bhk">BHK*</label>
          <select
            className="form-control"
            id="bhk"
            name="bhk"
            multiple
            value={data?.bhk}
            onChange={handleInputChange}
          >
            {bhk?.map((bhk) => (
              <option key={bhk?.id} value={bhk?.bhk}>
                {bhk?.bhk} BHK
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="amenities">Amenities*</label>
          <select
            className="form-control"
            id="amenities"
            name="amenities"
            multiple // Allow multiple selections
            value={data?.amenities}
            onChange={handleInputChange}
          >
            {/* Map through the list of amenities and generate <option> elements */}
            {amenities?.map((amenities) => (
              <option value={amenities.id}>{amenities.amenities}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="total_blocks">Total Blocks*</label>
          <input
            type="number"
            className="form-control"
            id="total_blocks"
            name="total_blocks"
            value={data?.total_blocks}
            onChange={handleInputChange}
            placeholder="Enter Total Blocks"
          />
        </div>

        <div className="form-group">
          <label htmlFor="land_parcel_SqMtr">Land Parcel SqMtr*</label>
          <input
            type="number"
            className="form-control"
            id="land_parcel_SqMtr"
            name="land_parcel_SqMtr"
            value={data?.land_parcel_SqMtr}
            onChange={handleInputChange}
            placeholder="Enter Land Parcel SqMtr"
          />
        </div>
      </div>
    </div>
  );
};

export default AddProjDetail;

import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';
import { useSelector } from 'react-redux';

const UserData = ({userID}) => {
    const [UserData, serUserData] = useState('');
    const accessToken = useSelector(state=>state.authorization.accessToken)

    useEffect(() => {
        const fetchExecutivesdata = async () => {
            try {
              const response = await fetch(`${BASE_URL}/auth/users/${userID}/`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${accessToken}`,
                },
              });
              const data = await response.json();
              serUserData(data);
            } catch (error) {
              console.error(error);
            }
          };
          fetchExecutivesdata();
    }, [userID,accessToken]);
  return (
    <div>
         {UserData && <p style={{marginBottom:"0"}}>{UserData.username}</p>}
    </div>
  )
}

export default UserData
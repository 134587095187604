import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Authentication.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/authentication/authorizationSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state=> state.authorization.isAuthenticated)
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const error = useSelector(state=> state.authorization.error)
  const [errorMessage, setErrorMessage] = useState();
  const [showPass, setShowPass] = useState(false)

  useEffect(() => {
    setErrorMessage("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      password,
      username,
    };
  
    try {
      dispatch(login(userData));
      setErrorMessage(error)
    } catch (error) {
      console.error("Login error:", error);
    }
  };
  
  const handlePasswordView = ()=>{
    setShowPass(!showPass)
  }
  useEffect(() => {
    if ( isAuthenticated === true || isAuthenticated === "true") {
      navigate("/");
    }
  }, [isAuthenticated,navigate]);
  
  return (
    <div>
      <div className="contforsignin">
        <div className="col-lg-6 col-md-10 col-sm-12">
          <div className="signup-form width-50">
            <h3 className="text-center text-uppercase">Login</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                  name="username"
                  placeholder="Username"
                  required
                />
              </div>
              <div className="form-group password">
                <input
                  type={showPass ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  required
                />
                <i className={showPass ? "eye bi bi-eye" : " eye bi bi-eye-slash"} onClick={()=>handlePasswordView()}></i>
              </div>
              
             
              <div className="account-link">
                <p>
                  <Link to="/forgotpassword">Forgot Password</Link>
                </p>
              </div>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}
              <div className="form-group text-center">
                <input
                  className="signup text-uppercase"
                  type="submit"
                  name=""
                  value="login"
                />
              </div>
              <div className="account-link">
                <p>
                  Create an account? <Link to="/signup"> Sign Up</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { BASE_URL } from "../../util/Constant";

export function getUserDetails(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/customers/me/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function getBuilderDetails(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builders/me/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function getBuilders(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builders/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }

  export function updateUserDetail({newData, token}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/customers/me/`,  {
            method: "PUT",
            headers: {
              Authorization: `JWT ${token}`,
            },
            body: newData, 
          });
         
        if (response.ok) {
          const data = await response.json();
          alert('Successfully  update data', data);
          resolve({ data });

        } else {
            alert('Failed to update settings');
          const error = await response.text();
          console.log("error",error)
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
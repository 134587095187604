import React, { useState } from 'react';


const AdvantageList = () => {
  const advantages = [
    {
      title: 'Heavy Discounts',
      description: 'Heavy discounts on everything you need before you shift to your new home. Everything at one place lowest prices.',
     },
    {
      title: 'Authentic Info',
      description:
        '100% Authentic Info, Physical + Digital Optimized post-booking process. We bring everyone on a single platform.',
    },
    {
      title: 'Better Interest Rates',
      description: 'Better interest rates and simplified process for',
    },
    
    {
      title: 'Price Benefits',
      description: 'Price Benefits as we deal in bulk.',
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="buying-home">
        <div className="container">
          
          
            <h2 className="text-center text-uppercase">Advantages of buying home with us</h2>
    <div className="advantage-list">
      {advantages.map((advantage, index) => (
        <div
          key={index}
          className={`advantage-card ${hoveredIndex === index ? 'hovered' : ''}`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <h3 className='text-center'>{advantage.title}</h3>
          <p className='text-center mb-0'>{advantage.description}</p>
        </div>
      ))}
    </div>
    </div>
    </div>
  );
};

export default AdvantageList;

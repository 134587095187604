import React, { useEffect } from "react";
import ClickableImage from "../../../components/modal/ClickableImage";
import { useParams, useLocation } from "react-router-dom";
import "./Sitevisit.css"
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";


const Confirmation = () => {
  const { id } = useParams();
  const property = useSelector(state=>state.project.selectedProject)
  const location = useLocation();
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search);
  useEffect(()=>{
    dispatch(fetchProjectByIdAsync(id))
  },[dispatch,id])
  return (
    <div className="site-visit-confimation">
      <div className="container">
        <div className="site-visit-confimation-detail">
          <div className="site-confirmation-left">
            <p>That's the first step, it can be beginning of something great</p>
            <p>Your site has been booked. Details are as under : </p>
            <span>Project : {property?.name}</span>
            <span>Date : {searchParams.get("date")}</span>
            <p>Time: {searchParams.get("time")}</p>
            <span>Our project expert will meet you at site location.</span>
            <p>We are committed to be on time, every time</p>
            <span>Thanks,</span>
            <p>Team TDK</p>
          </div>

          <div className="site-confirmation-right">
            <h4>
              {property?.name} | {property?.address}
            </h4>
            <div className="site-image">
              <ClickableImage
                src={property?.image1 ? property?.image1 : "/images/pro-2.jpg"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;

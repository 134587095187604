import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  logout,
  refreshToken,
} from "../../features/authentication/authorizationSlice";
import { getUserDetailsAsync } from "../../features/user/userSlice";
import { fetchProjectAsync } from "../../features/project/projectSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const {
    user,
    accessToken,
    refreshToken: storedRefreshToken,isAuthenticated
  } = useSelector((state) => state.authorization);
  const customerData = useSelector(state=>state.user.userDetail)
  const userData = useSelector(state=>state.authorization.user)
  
  
  const renderDropdownOptions = () => {
    if (user && user_type === "Customer") {
      return (
        <>
          <Link to="/account">My Account</Link>
          <Link to="/profile">My Profile</Link>
          <Link to="/bookings">My Booking</Link>
          {/* <Link to="/feedback">Feedback</Link> */}
          <Link to="/homeloan">Appy for Loan</Link>
          <Link to="/settings">Settings</Link>
        </>
      );
    } else if (user && user_type === "Admin") {
      return (
        <>
          <Link to="/assignsitevisit">Manage sitevisits</Link>
          <Link to="/projectdetails">Projects Details</Link>
          <Link to="/projectstatus">Projects Status</Link>
          <Link to="/addprojects">Add Project</Link>
          <Link to="/alllayouts">All Layouts</Link>
          <Link to="/allunits">All Units</Link>
          <Link to="/allblocks">All Blocks</Link>
          <Link to="/adddeveloper">Add Developer</Link>
        </>
      );
    } else if (user && user_type === "Builder") {
      return (
        <>
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/builderprofile">My Profile</Link>
          <Link to="/addproject">Add Project</Link>
        </>
      );
    }
  };
  const dispatch = useDispatch();
  const isAccessTokenExpired = () => {
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        const timeToExpire = decodedToken.exp - currentTime;
  
        return timeToExpire < 60;
      } catch (error) {
        console.error("Invalid access token:", error);
        return true;
      }
    }
  
    return true; // No token present or invalid token
  };
  
  

  const isRefreshTokenExpired = () => {
    if (storedRefreshToken) {
      try {
        const decodedToken = jwtDecode(storedRefreshToken);
        const currentTime = Date.now() / 1000;
  
        return decodedToken.exp < currentTime;
      } catch (error) {
        console.error("Invalid refresh token:", error);
        return true;
      }
    }
  
    return true; // No token present or invalid token
  };

  const userType = useSelector((state) => state.authorization?.user?.user_type);
  const user_type = user ? userType : "";
 
  const isRefTokExp = isRefreshTokenExpired();
  const isAccTokExp = isAccessTokenExpired();
  useEffect(() => {
    const fetchData = async () => {
      if (!isAccTokExp) {
        dispatch(getUserDetails(accessToken));
      } else if (isAccTokExp && !isRefTokExp && storedRefreshToken !== null) {
        try {
          // Dispatch the refreshToken action and update the access token in the state
          await dispatch(refreshToken(storedRefreshToken));
          // After refreshing the token, fetch user details with the new access token
          dispatch(getUserDetailsAsync(accessToken));
        } catch (error) {
          console.error("Error refreshing token:", error);
          dispatch(logout()); // Log out user if token refresh fails
        }
      } else if (isRefTokExp && storedRefreshToken !== null) {
        dispatch(logout());
      } else if(accessToken===null){
       dispatch(logout())
      }
    };
  
    fetchData();
  }, [accessToken, dispatch, storedRefreshToken,isAccTokExp,isRefTokExp,navigate]);
  useEffect(() => {
    dispatch(fetchProjectAsync());
  }, [dispatch]);
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="header-top">
      <div className="container">
        <div className="navbar ">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo.png" alt="" />
            </Link>
          </div>
          {!isAuthenticated  ? (
            <div className="signin-btn text-right d-flex gap-2 ">
                <span>
                  <Link to="/signup">Sign Up</Link>
                </span>
                <span>
                  <Link to="/login">Login</Link>
                </span>
            
            </div>
            
          ) : (
            <>
              
              <ul className="header-right">
              <li>
                <p style={{ color: "white" }}>{userData ? userData.username : ""}</p>
              </li>
              <li>
                <div className="user-menu text-right">
                  <div className="dropdown text-left">
                    <button className="dropbtn">
                      <img
                        src={
                          customerData?.profile_photo
                            ? customerData?.profile_photo
                            : "/images/user-icon.png"
                        }
                        alt=""
                      />
                    </button>
                    <div className="dropdown-content">
                      {renderDropdownOptions()}
                      <Link to="/" onClick={handleLogout}>
                        Logout
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { BASE_URL } from "../../util/Constant"

export function getBlocks(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/block/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function getLayouts({token,id}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builder_projects/${id}/addlayouts/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function getUnits({token,id}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builder_projects/${id}/units/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
  export function getBuilderProjects(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builder_projects/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }

  export function getConstStatus({token,projId}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builder_projects/${projId}/construction_status/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
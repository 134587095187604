import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const ViewFromFlat = (props) => {
    const [view, setView] = useState('');

    useEffect(() => {
      if (props.ID) {        
        const apiUrl = `${BASE_URL}/projects/view_from_unit/${props.ID}`;        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const view = data.view_from_unit;
            setView(view);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [props.ID]);
    return (
      <div>
          {view && (
          <>
            <span>{view}</span>
            {props.isLast ? '' : ', '}
          </>
        )}
      </div>
    )
  }

export default ViewFromFlat
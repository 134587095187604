import React from 'react'
import { Link } from 'react-router-dom'
import './Error.css'

const Error = () => {
  return (
    <div className='error-page'>
      <h1>404 Error:</h1>
      <p>You are Lost</p>
      <Link to='/'><button className='btn btn-primary'>Go to home Page</button></Link>
    </div>
  )
}

export default Error
import React from 'react'
import './Booking.css'

const Booking = () => {
  return (
    <div>
        <div className="project-details block-details booking-page" style={{overflowX:"auto", marginBottom:"20px"}}>
        <h3 className="text-center">
              All Bookings
            </h3>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Unit No.</th>
                <th>Buyer Name</th>
                <th>Co Buyer Name</th>
                <th>Date of booking</th>
                <th>KYC-ATS</th>
                <th>Home Loan  </th>
                <th>Payment Due</th>
                <th>Payment Received</th>
              </tr>
            </thead>
            <tbody className="text-center">
              
                    <tr>
                      <td>01</td>
                      <td>1</td>
                      <td>Rohit</td>
                      <td>Rahul</td>
                      <td>01/01/2023</td>
                      <td>320000</td>
                      <td>61</td>
                      <td>320000</td>
                      <td>320000</td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>2</td>
                      <td>Rohit</td>
                      <td>Rahul</td>
                      <td>01/01/2023</td>
                      <td>320000</td>
                      <td>61</td>
                      <td>320000</td>
                      <td>320000</td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>3</td>
                      <td>Rohit</td>
                      <td>Rahul</td>
                      <td>01/01/2023</td>
                      <td>320000</td>
                      <td>61</td>
                      <td>320000</td>
                      <td>320000</td>
                    </tr>
                    <tr>
                      <td>04</td>
                      <td>4</td>
                      <td>Rohit</td>
                      <td>Rahul</td>
                      <td>01/01/2023</td>
                      <td>320000</td>
                      <td>61</td>
                      <td>320000</td>
                      <td>320000</td>
                    </tr>
                    <tr>
                      <td>05</td>
                      <td>5</td>
                      <td>Rohit</td>
                      <td>Rahul</td>
                      <td>01/01/2023</td>
                      <td>320000</td>
                      <td>61</td>
                      <td>320000</td>
                      <td>320000</td>
                    </tr>
                    
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Booking
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ProjectCard.css";
import Slider from "react-slick";
import ClickableImage from "../modal/ClickableImage";
import Loader from "../loader/Loader";
import Amenities from "../card/Amenities";
import { useEffect, useState } from "react";

const ProjectCard = () => {
  const projects = useSelector((state) => state.project.projects);
  const isLoading = useSelector((state) => state.project.isLoading);
  const randomId = Math.floor(Math.random() * projects.length);
  const property = projects[randomId];
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatPossessionDate = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date(); // Get the current date
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    
    if (date < currentDate) {
      return 'Ready to Move'; 
    }
    
    return `${month} ${year}`;
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      {projects && (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="projectCard">
              {property && (
                <div className="row align-items-center">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <p className="fw-bold pb-2">
                      <Link
                        to={`/detail/${property.id}`}
                        style={{
                          textDecoration: "none",
                          color: "darkblue",
                        }}
                      >
                        {property.name}
                      </Link>{" "}
                      | {property.address}
                      <a
                        href={property.google_location}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i
                          className="bi bi-geo-alt"
                          style={{ fontSize: "1.5rem", color: "#012060" }}
                        ></i>
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <p className="possession">
                      <span>possession: {formatPossessionDate(property.possession)}</span>
                    </p>
                  </div>
                  <div className="">
                    {isSmallDevice ? (<>
                                           <ul>
                        <Slider {...sliderSettings}>
                          <li>
                            <img
                              src={
                                property.image1
                                  ? property.image1
                                  : "/images/pro-1.jpg"
                              }
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              src={
                                property.image2
                                  ? property.image2
                                  : "/images/pro-2.jpg"
                              }
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              src={
                                property.image3
                                  ? property.image3
                                  : "/images/pro-3.jpg"
                              }
                              alt=""
                            />
                          </li>
                        </Slider>
                      </ul>
<div className="d-flex align-items-center justify-content-evenly">
                      <div className="builder-logo col-lg-3 col-md-3 col-sm-12">
                      <ClickableImage
                        src={
                          property.builder_logo
                            ? property.builder_logo
                            : "/images/partner-3.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="rera-registerd text-right">
                      <img src="images/area-registerd.png" alt="" />
                    </div></div>
                    </>
                    ) : (
                      <div className="row img-section">
                        <div className="project-images col-lg-9 col-md-9 col-sm-12">
                          <ClickableImage
                            src={
                              property.image1
                                ? property.image1
                                : "/images/pro-1.jpg"
                            }
                            alt=""
                          />
                          <ClickableImage
                            src={
                              property.image2
                                ? property.image2
                                : "/images/pro-2.jpg"
                            }
                            alt=""
                          />
                          <ClickableImage
                            src={
                              property.image3
                                ? property.image3
                                : "/images/pro-3.jpg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="builder-logo col-lg-3 col-md-3 col-sm-12">
                          <ClickableImage
                            src={
                              property.builder_logo
                                ? property.builder_logo
                                : "/images/partner-3.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="our-flat-detail ">
                    <div>
                      <div className="short-decription">
                        {property && property.short_description ? (
                          property.short_description
                        ) : (
                          <p> Short Description </p>
                        )}
                      </div>
                      <div className="mt-2 size-decription">
                        {property && property.size_description ? (
                          property.size_description
                        ) : (
                          <p> Size Description </p>
                        )}
                      </div>
                    </div>
                    <div className="area-registerd text-right">
                      <img src="images/area-registerd.png" alt="" />
                    </div>
                  </div>
                  <div className="d-flex gap-1 amenities mt-2">
                    <span className="fw-bold">Amenities: </span>
                    {property && property.amenities ? (
                      property.amenities.map((item, index) => (
                        <span key={index}>
                          {" "}
                          <Amenities
                            Id={item}
                            isLast={index === property?.amenities.length - 1}
                          />
                        </span>
                      ))
                    ) : (
                      <p>Amenities</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectCard;

import React, {  useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { BASE_URL } from "../../../util/Constant";
import { useSelector } from "react-redux";

const AddUnitInAdmin = () => {
  const filteredProject = useSelector(state=>state.project.projects)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const [selectedProject, setSelectedProject] = useState(4);
  const {data: layouts=[]} = useFetch(`/projects/projects/${selectedProject}/layouts/`)
  const [errorMessage, setErrorMessage] = useState();
  const {data: sunlight } = useFetch("/projects/unit_sunlight/");
  const {data: opendirection } = useFetch("/projects/unit_open_direction/");
  const {data: view } = useFetch("/projects/view_from_unit/");
  const {data: block } = useFetch("/projects/block/");

  const initialData = {
    layout: "",
    block: "",
    floor: "",
    floor_range: "",
    full_unit_number: "",
    unit_superbuiltup_area: "",
    status: "",
    facing_of_gate: "",
    sun_light: [],
    view_from_flat: [],
    open_direction: [],
    basic_rate_per_sqft: "",
    extra_charges_per_sqft: "",
    gst_percent: "",
    legal_fees: "",
    other_charges: 0,
    stamp_duty_percent: "",
    registration_fees_percent: "",
    registration_fees_for_female_buyers: "",
    maintenance_deposit_per_sqft: "",
    advance_maintenance_per_sqft: "",
    advance_maintenance_number_of_months: "",
    price_comment: "",
  }
  const [data, setData] = useState(initialData);

  const filteredBlocks = block?.filter((block) => block?.project === parseInt(selectedProject));

  const layout =  layouts?.filter((item) => item.id === parseInt(data?.layout) )

  

  const handleInputChange = (e) => {
    const { name, type, options } = e.target;
    
    if (
      name === "unit_superbuiltup_area" &&
      parseInt(e.target.value) !== layout[0]?.superbuiltuparea
    ) {
      setErrorMessage("This Superbuiltup area does not match with layout superbuiltarea");
    } else {
      setErrorMessage(""); // Reset errorMessage
    }
    if (type === "select-multiple") {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setData({
        ...data,
        [name]: selectedOptions,
      });
    } else {
      setData({ ...data, [name]: e.target.value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    
    try {
      const response = await fetch(
        `${BASE_URL}/projects/builder_projects/${selectedProject}/units/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        alert("Unit Added Successfully");
        setData(initialData)
      } else {
        alert(`Something went wrong`);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
 
  
  return (
    <div className="mains m-2">
      <div className="container add-project">
        <form onSubmit={handleSubmit}>
          <div className="add-unit">
            <h1 className="text-center mb-4">Add Unit </h1>
            <div className="form-group">
              <label htmlFor="">Select the Project</label>
              <br />
              <select
                className="form-control"
                value={selectedProject}
                onChange={(event) => setSelectedProject(event.target.value)}
              >
                <option value="">Select the Project</option>
                {filteredProject &&
                  filteredProject?.map((proj) => {
                    return (
                      <option key={proj.id} value={proj.id}>
                        {proj.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="project-detail">
              <div className="form-group">
                <label htmlFor="name">Layout*</label>
                <select
                  className="form-control"
                  id="layout"
                  name="layout"
                  value={data?.layout}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Layout</option>
                  {layouts && layouts?.length === 0 ? (
                    <option>No layouts available for this project</option>
                  ) : (
                    layouts?.map((layout, index) => (
                      <option key={layout?.id} value={layout?.id}>
                        {layout.nickname
                          ? layout.nickname
                          : `layout${index + 1}`}
                      </option>
                    ))
                    )}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="rera_number">Block*</label>
                <select
                  type="text"
                  className="form-control"
                  id="block"
                  name="block"
                  value={data?.block}
                  onChange={handleInputChange}
                  placeholder="Enter Block"
                >
                  <option value=""  >Select Block</option>
                  {filteredBlocks && filteredBlocks.length > 0 ? (filteredBlocks?.map((block,index) =>(
                    <option key={index} value={block.id}>{block.block}</option>
                  ))) :"" }
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="address">Floor*</label>
                <input
                  type="number"
                  className="form-control"
                  id="floor"
                  name="floor"
                  value={data?.floor}
                  onChange={handleInputChange}
                  placeholder="Enter floor"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="city">Floor Range*</label>
                <select
                  className="form-control"
                  id="floor_range"
                  name="floor_range"
                  value={data?.floor_range}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected hidden>Select Floor range</option>  
                  <option value="Lower">Lower Floor</option>
                  <option value="Middle">Middle Floor</option>
                  <option value="Higer">Higher Floor</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="total_floors">Full Unit Number*</label>
                <input
                  type="text"
                  className="form-control"
                  id="full_unit_number"
                  name="full_unit_number"
                  value={data?.full_unit_number}
                  onChange={handleInputChange}
                  placeholder="e.g. A 501"
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_floors">Unit Superbuiltup Area*</label>
                <input
                  type="number"
                  className="form-control"
                  id="unit_superbuiltup_area"
                  name="unit_superbuiltup_area"
                  value={data?.unit_superbuiltup_area}
                  onChange={handleInputChange}
                  placeholder="Enter Unit Superbuiltup Area"
                  required
                />
                {errorMessage ? (
                          <span
                            className="error-message"
                            style={{ color: "darkred" }}
                          >
                            {errorMessage}
                          </span>
                        ):""}
              </div>
              <div className="form-group">
                <label htmlFor="status">Status*</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={data?.status}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled hidden>select</option>
                  <option value="Avl">Available</option>
                  <option value="BO">Booked</option>
                  <option value="HO">Hold</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status">Facing of the Gate*</label>
                <select
                  className="form-control"
                  id="facing_of_gate"
                  name="facing_of_gate"
                  value={data?.facing_of_gate}
                  onChange={handleInputChange}
                >
                  <option value=""disabled hidden>select</option>
                  <option value="E">East</option>
                  <option value="W">West</option>
                  <option value="S">South</option>
                  <option value="N">North</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="bhk">Sunlight*</label>
                <select
                  className="form-control"
                  id="sun_light"
                  name="sun_light"
                  multiple // Allow multiple selections
                  value={data?.sun_light}
                  onChange={handleInputChange}
                >
                  {
                     sunlight?.map(sunlight => (<option value={sunlight.id}>{sunlight.sunlight}</option>))
                  }
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="amenities">View From Flat*</label>
                <select
                  className="form-control"
                  id="view_from_flat"
                  name="view_from_flat"
                  multiple // Allow multiple selections
                  value={data?.view_from_flat}
                  onChange={handleInputChange}
                >
                  {
                     view?.map(view => (<option value={view.id}>{view.view_from_unit}</option>))
                  }
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="open direction">Open Direction*</label>

                <select
                  className="form-control"
                  id="open_direction"
                  name="open_direction"
                  multiple
                  value={data?.open_direction}
                  onChange={handleInputChange}
                >
                  {
                     opendirection?.map(direction => (<option value={direction.id}>{direction.unit_open_direction}</option>))
                  }
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="total_blocks">Basic rate per sqft*</label>
                <input
                  type="text"
                  className="form-control"
                  id="basic_rate_per_sqft"
                  name="basic_rate_per_sqft"
                  value={data?.basic_rate_per_sqft}
                  onChange={handleInputChange}
                  placeholder="Enter Basic rate per sqft"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="total_blocks">Extra charges per sqft*</label>
                <input
                  type="text"
                  className="form-control"
                  id="extra_charges_per_sqft"
                  name="extra_charges_per_sqft"
                  value={data?.extra_charges_per_sqft}
                  onChange={handleInputChange}
                  placeholder="Enter Extra charges per sqft"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">Gst percent*</label>
                <input
                  type="text"
                  className="form-control"
                  id="gst_percent"
                  name="gst_percent"
                  value={data?.gst_percent}
                  onChange={handleInputChange}
                  placeholder="Enter Gst percent"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">Legal fees*</label>
                <input
                  type="text"
                  className="form-control"
                  id="legal_fees"
                  name="legal_fees"
                  value={data?.legal_fees}
                  placeholder="Enter Legal fees"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="total_blocks">Stamp duty percent*</label>
                <input
                  type="text"
                  className="form-control"
                  id="stamp_duty_percent"
                  name="stamp_duty_percent"
                  value={data?.stamp_duty_percent}
                  placeholder="Enter Stamp duty percent"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">Registration fees percent*</label>
                <input
                  type="text"
                  className="form-control"
                  id="registration_fees_percent"
                  name="registration_fees_percent"
                  value={data?.registration_fees_percent}
                  placeholder="Enter Registration fees percent"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">
                  Registration fees for female buyers*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="registration_fees_for_female_buyers"
                  name="registration_fees_for_female_buyers"
                  value={data?.registration_fees_for_female_buyers}
                  placeholder="Enter Registration fees for female buyers"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="total_blocks">
                  Maintenance deposit per sqft*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="maintenance_deposit_per_sqft"
                  name="maintenance_deposit_per_sqft"
                  value={data?.maintenance_deposit_per_sqft}
                  placeholder="Enter Maintenance deposit"
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">
                  Advance maintenance per sqft*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="advance_maintenance_per_sqft"
                  name="advance_maintenance_per_sqft"
                  value={data?.advance_maintenance_per_sqft}
                  placeholder="Enter Advance Maintenance deposit"
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="total_blocks">
                  Advance maintenance number of months*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="advance_maintenance_number_of_months"
                  name="advance_maintenance_number_of_months"
                  value={data?.advance_maintenance_number_of_months}
                  placeholder="Enter months"
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="land_parcel_SqMtr">Price comment</label>
                <input
                  type="text"
                  className="form-control"
                  id="price_comment"
                  name="price_comment"
                  value={data?.price_comment}
                  placeholder="comment"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className=" buttons">
                Add Unit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUnitInAdmin;

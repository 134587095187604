import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getBookingsAsync } from "../../../features/bookings/bookingSlice";
import "./Bookings.css";
import Loader from "../../../components/loader/Loader";

const Booking = () => {
  const navigate = useNavigate()
  const projects = useSelector((state) => state.project.projects);
  const bookings = useSelector((state) => state.booking.bookings);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const isLoading = useSelector((state) => state.booking.isLoading);
  const dispatch = useDispatch();
  const findDataById = (projectid) => {
    const propertyItem = projects?.find((item) => item?.id === projectid);
    return propertyItem ? propertyItem : null;
  };
 
  const Feedback =()=>{
    navigate('/feedback')
  }

  useEffect(() => {
    dispatch(getBookingsAsync(accessToken));
  }, [dispatch, accessToken]);

  return (
    <div className="container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {bookings && bookings.length > 0 ? (
            bookings?.map((booking) => {
              const propertyItem = findDataById(booking?.project);

              return (
                <div className="booking-middle-section">
                  <div className="container">
                    <div className="row">
                      <div key={booking?.id}>
                        {propertyItem && (
                          <div className="col-lg-12 col-md-12 col-sm-12  bookings">
                            <div className="booking-right">
                              <div className="buyer-booking-detail">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="booking-id-date">
                                      <p>
                                        Booking Id : <span>{booking?.id}</span>
                                      </p>
                                      <p>
                                        Booking Date : <span>04/11/2021</span>
                                      </p>
                                      <div className="booking-property-image">
                                        <img
                                          src={propertyItem?.image1}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="buyer-bank-detail">
                                      <h3>Bank details</h3>
                                      <p>
                                        Home Loan Required : <span>Yes</span>
                                      </p>
                                      <p>
                                        Home Loan Amount : <span>45 Lakhs</span>
                                      </p>
                                      <p>
                                        Home Loan Status :{" "}
                                        <span>Pre Sanction</span>
                                      </p>
                                      <p>
                                        Bank : <span>HDFC TDK</span>{" "}
                                        <Link to="">Manage</Link>
                                      </p>
                                      <div className="booking-property-detail">
                                        <h3>Property details</h3>
                                        <p>
                                          Unit No. <span>A 1201</span>
                                        </p>
                                        <p>
                                          Project Name :{" "}
                                          <span>
                                            {propertyItem?.name},{" "}
                                            {propertyItem?.area}, Ahmedabad
                                          </span>
                                        </p>
                                        <p>
                                          Carpet Area :{" "}
                                          <span>
                                            {propertyItem?.RERAcarpetarea}
                                          </span>
                                        </p>
                                        <p>
                                          Possession Expected :{" "}
                                          <span>
                                            {propertyItem?.possession}
                                          </span>
                                        </p>
                                        <p>
                                          RERA No. :{" "}
                                          <span>
                                            {propertyItem?.rera_number}
                                          </span>
                                        </p>
                                        <p>
                                          Total Cost :{" "}
                                          <span>
                                            Rs 67,00,000/- For Cost Break Up{" "}
                                          </span>{" "}
                                          <Link to="">Click Here</Link>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="booking-property-detail">
                                <ul>
                                  <li>
                                    <Link to="" title="">
                                      Accounts
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="" title="">
                                      Construction Status & Updates
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="" title="">
                                      Documents
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>{" "}
                            <div className="upload__box">
                              <div className="upload__btn-box">
                                <label className="upload__btn">
                                  <p>Upload images</p>
                                  <input
                                    type="file"
                                    multiple=""
                                    data-max_length="20"
                                    className="upload__inputfile"
                                  />
                                </label>
                              </div>
                              <div className="upload__img-wrap"><button onClick={Feedback}> FeedBack</button></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            
              <h3>You do not have any bookings Yet</h3>
          
          )}
        </>
      )}
    </div>
  );
};

export default Booking;

import React, { useState, useEffect } from 'react';

function App() {
  const [cityName, setCityName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch geolocation and city name based on IP
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCityName(data.city || 'Unknown City');
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching city name:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>City Name from Geolocation</h1>
        {loading ? (
          <p>Loading...</p>
        ) : cityName ? (
          <p style={{color:"white"}}> Your city: {cityName}</p>
        ) : (
          <p>Unable to determine city name</p>
        )}
      </header>
    </div>
  );
}

export default App;

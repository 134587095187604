import React, { useState } from "react";
import { Link } from "react-router-dom";

const Eligibility = () => {
  const [data, setData] = useState({
    monthly_income: "50000",
    existing_emi: "20000",
    tenure: "30",
    interest: "8",
  });

  const [occupation, setOccupation] = useState("salaried");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let maxValue;

    switch (name) {
      case "monthly_income":
        maxValue = 4000000;
        break;
      case "existing_emi":
        maxValue = 4000000;
        break;
      case "tenure":
        maxValue = 360;
        break;
      default:
        maxValue = Number.MAX_SAFE_INTEGER;
    }

    const newValue = Math.min(maxValue, Math.max(0, value));

    setData({
      ...data,
      [name]: newValue,
    });
  };

  const formatCurrency = (amount) => {
    if (amount < 100000) {
      return `₹${amount / 1000}K`;
    } else if (amount < 10000000) {
      return `₹${amount / 100000}L`;
    } else {
      return `₹${amount / 10000000}Cr`;
    }
  };

  const calculateEligibleLoanAmount = () => {
    const monthlyIncome = parseFloat(data.monthly_income);
    const existingEMI = parseFloat(data.existing_emi);
    const tenure = parseFloat(data.tenure);
    const incomePercentage = occupation === "salaried" ? 0.5 : 0.6;
    const interest = parseFloat(data.interest) / 100;

    const maxaffordableEmi = monthlyIncome * incomePercentage - existingEMI;
    const amount = maxaffordableEmi * tenure * (1 - interest);

    const eligibleAmount = amount;
    return eligibleAmount > 0 ? eligibleAmount.toFixed(0) : "0.00";
  };

  const calculateEMI = () => {
    const monthlyIncome = parseFloat(data.monthly_income);
    const existingEMI = parseFloat(data.existing_emi);
    const incomePercentage = occupation === "salaried" ? 0.5 : 0.6;

    const maxaffordableEmi = monthlyIncome * incomePercentage - existingEMI;

    return maxaffordableEmi > 0 ? maxaffordableEmi.toFixed(0) : 0;
  };

  return (
    <div className="calculator">
      <div>
        <h3 className="text-center">Calculate Home Loan Eligibility</h3>
        <p className="text-center">
          The Home Loan Eligibility Calculator is an east-to-use tool offered by
          use for the estimated amount of Home loan that you are eligible to get
          basis your profile
        </p>
      </div>
      <div className="calcultor-form row">
        <div className="col-lg-7 col-sm-12 col-md-7">
          <div className="occupation mb-3">
            <label htmlFor="" className="mb-2">
              {" "}
              Occupation :{" "}
            </label>
            <div className="occupation-option">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="occupation"
                  id="salaried"
                  value="salaried"
                  checked={occupation === "salaried"}
                  onChange={() => setOccupation("salaried")}
                />
                <span>Salaried</span>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="radio"
                  name="occupation"
                  checked={occupation === "self_employed"}
                  onChange={() => setOccupation("self_employed")}
                  id="self_employed"
                  value="self_employed"
                />
                <span>Self-Employed</span>
              </div>
            </div>
          </div>
          <div className="form-group mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Monthly Income</label>
              <input
                type="number"
                className="form-control mt-2"
                id="monthly_income"
                name="monthly_income"
                value={data.monthly_income}
                onChange={handleInputChange}
                placeholder="Enter loan amount"
                required
              />
            </div>

            <input
              type="range"
              className="form-range"
              id="monthly_income"
              name="monthly_income"
              min="30000"  
              max="4000000"  
              step="10000"  
              value={data.monthly_income}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span> {formatCurrency(30000)}</span>
              <span>{formatCurrency(4000000)}</span>
            </div>
          </div>

          <div className="form-group mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Total Existing Obligation/EMI </label>
              <input
                type="number"
                className="form-control mt-2"
                id="existing_emi"
                name="existing_emi"
                value={data.existing_emi}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="existing_emi"
              name="existing_emi"
              min="0"
              max="4000000"
              step="10000"
              value={data.existing_emi}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span> {formatCurrency(0)}</span>
              <span>{formatCurrency(4000000)}</span>
            </div>
          </div>

          <div className="form-group ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Loan Tenure</label>
              <input
                type="number"
                className="form-control mt-2"
                id="tenure"
                name="tenure"
                value={data.tenure}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="tenure"
              name="tenure"
              min="30"  
              max="360"  
              step="6"  
              value={data.tenure}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span>30 Months</span>
              <span>360 Months</span>
            </div>
          </div>
          <div className="form-group ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Interest</label>
              <input
                type="number"
                className="form-control mt-2"
                id="interest"
                name="interest"
                value={data.interest}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="interest"
              name="interest"
              min="5" 
              max="16"  
              step="0.5" 
              value={data.interest}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span>5 %</span>
              <span>16 %</span>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-md-5 ">
          <div className="form-result row text-center ">
            <div className=" d-flex  flex-column justify-content-center align-items-center mb-3">
              <span>Eligible for total loan amount of</span>
              <p>&#8377;{calculateEligibleLoanAmount()}</p>
            </div>
            {/* <div className="col-1 line"></div> */}
            <div className=" d-flex  flex-column justify-content-center align-items-center">
              <span>Your EMI will be</span>
              <p>&#8377;{calculateEMI()}</p>
            </div>
            <div className="col-12 mt-3">
              <Link to="/homeloan">
                <button>Apply For Loan</button>
              </Link>
            </div>
            <div className="mt-3">
              <span>
                Final eligibility is subject to validation post receipt of
                actual documents
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eligibility;


import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedAdmin() {
  const user_type =  useSelector(state=>state.authorization?.user?.user_type);
  const isAuthenticated = useSelector(state=>state.authorization?.isAuthenticated)
    if(!isAuthenticated){
      return <Navigate to="/login" />
      }
      if(user_type === 'Admin'){
        return <Outlet />
        }}
export default ProtectedAdmin;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../util/Constant";

const SignUp = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    mobile_number: "",
    password: "",
    confirmPass: "",
    user_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");

  const handelInput = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (userData.password !== userData.confirmPass) {
      setErrorMessages("Passwords do not match");
      setIsLoading(false)
      return;
    }
    if (userData.user_type === "Staff") {
      setErrorMessage("Select user type from Builder, Customer and Banker");
      return;
    }

    const newUser = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      username: userData.username,
      email: userData.email,
      mobile_number: userData.mobile_number,
      password: userData.password,
      user_type: userData.user_type,
    };

    try {
      const response = await fetch(`${BASE_URL}/auth/users/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      const data = await response.json();
      if (response.ok && data.user_type ==="Builder") {
        
          navigate("/builderconfirmation");
        
        setIsLoading(false)
        
      }else if(response.ok && data.user_type==="Customer"){
        setIsLoading(false)
        navigate("/login");
      }else if (response.ok && data.user_type==="Banker"){
        setIsLoading(false)
        navigate("/login")
      }
      
      else {
        // Registration failed
        if (data.message) {
          setErrorMessage(data.message);
          setIsLoading(false) // Display error message from the server
        } else {
          setErrorMessage(data);
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during registration");
    }
  };

  useEffect(() => {
    setErrorMessage("");
    setErrorMessages("");
    setIsLoading(false)
  }, [userData]);

  return (
    <div className="login-signup-form">
     
        <div className="row">
          <div className="contforsignin">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <div className="signup-form width-50">
                <h3 className="text-center text-uppercase mb-3">sign up</h3>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          value={userData.first_name}
                          onChange={handelInput}
                          className="form-control"
                          name="first_name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          value={userData.last_name}
                          onChange={handelInput}
                          className="form-control"
                          name="last_name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="number"
                          value={userData.mobile_number}
                          onChange={handelInput}
                          className="form-control"
                          name="mobile_number"
                          placeholder="Mobile Number"
                          required
                        />
                        {errorMessage.mobile_number && (
                          <span
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errorMessage.mobile_number[0]
                              ? "Enter Correct mobile Number"
                              : ""}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="email"
                          value={userData.email}
                          onChange={handelInput}
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          required
                        />
                        {errorMessage.email && (
                          <span
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errorMessage.email[0]}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          value={userData.username}
                          onChange={handelInput}
                          className="form-control"
                          name="username"
                          placeholder="Username"
                          required
                        />
                        {errorMessage.username && (
                          <span
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errorMessage.username[0]}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <select
                          value={userData.user_type}
                          onChange={handelInput}
                          className="form-control"
                          name="user_type"
                          required
                        >
                          <option value="">Select User Type</option>
                          <option value="Customer">Customer</option>
                          <option value="Banker">Banker</option>
                          <option value="Builder">Builder</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="password"
                          value={userData.password}
                          onChange={handelInput}
                          className="form-control"
                          name="password"
                          placeholder="Password"
                          required
                        />
                        {errorMessage.password && (
                          <span
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errorMessage.password[0]}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="password"
                          value={userData.confirmPass}
                          onChange={handelInput}
                          className="form-control"
                          name="confirmPass"
                          placeholder="Confirm Password"
                          required
                        />
                      </div>

                      {errorMessages && (
                        <p style={{ color: "red" }}>{errorMessages}</p>
                      )}
                    </div>
                    <div className="form-group text-center">
                      {isLoading ? (
                        <>
                          <button
                            className="signup"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </>
                      ) : (
                        <input
                          type="submit"
                          className="signup text-uppercase"
                          name=""
                          value="sign up"
                        />
                      )}
                    </div>
                    <div className="account-link">
                      <p>
                        Existing User? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default SignUp;

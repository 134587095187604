import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addFeedback, getBookings } from "./bookingApi";



export const getBookingsAsync = createAsyncThunk(
    'booking/getBookingDetails',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getBookings(token);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const addFeedbackAsync = createAsyncThunk(
    'feedback/addFeedback',
    async ({token,bookingId,data},  {rejectWithValue} ) => {
      try {
        const response = await addFeedback({token,bookingId,data});
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  const bookingSlice = createSlice({
    name: "booking",
    initialState: {
      bookings: [],
      isLoading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getBookingsAsync.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getBookingsAsync.fulfilled, (state, action) => {
          state.bookings = action.payload;
          state.isLoading = false;
        })
        .addCase(getBookingsAsync.rejected, (state, action) => {
          state.error = action.error;
        })
    },
  });
  
  export default bookingSlice.reducer
import React, {  useState } from "react";
import { Link,  useNavigate } from "react-router-dom";
import "./SearchBox.css";
import ReactSelect from "react-select";
import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectsByFiltersAsync } from "../../features/project/projectSlice";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../util/Constant";
import SearchResults from "../../pages/common/searchPage/SearchResults";

const SearchBox = ({enableNavigation,setShowFilterField}) => {
  const navigate = useNavigate()
  const { data: city } = useFetch(`/projects/city/`);
  const { data: area } = useFetch(`/projects/area/`);
  const { data: bhk } = useFetch(`/projects/bhk/`);
  const [otherData, setOtherData] = useState([]);
  const lastElementIndex = otherData?.length - 1;
  // const projects = useSelector((state) => state.project.projects);
  const projects = useSelector((state) => state.project.filteredProjects);
  const [minValue, setMinValue] = useState(500000);
  const [maxValue, setMaxValue] = useState(100000000);
  const [filters, setFilters] = useState({
    city: null,
    area: [],
    bhk: [],
    possession: null,

  });
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  
  
  const filteredProjects = projects.filter((project) => {
   
    
    const isMinBudgetMatch = minValue ? project.unit.some((unit) => unit.all_inclusive_price >= minValue) : true
    const isMaxBudgetMatch = maxValue ? project.unit.some((unit) => unit.all_inclusive_price <= maxValue) : true

    return (
      
      isMinBudgetMatch &&
      isMaxBudgetMatch
   
    );
  });
  
  const [showBudgetSlider, setShowBudgetSlider] = useState(false);
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      display: "flex",
      backgroundColor: "lightblue",
      color: "black",
      borderRadius: "1px",
      marginRight: "5px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "40px",
      overflow: "auto",
    }),
  };
  const dispatch = useDispatch();
  
  const filteredAreaData = area && area?.filter(
    (area) => area.city === parseInt(filters?.city)
  );

  function formatValue(value) {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L";
    } else {
      return value;
    }
  }
  const handleSubmit =async () => {
    dispatch(fetchProjectsByFiltersAsync(filters));
    const editedItem = {
      city: filters.city,
      area1: filters.area[0] || null,
      area2: filters.area[1] || null,
      area3: filters.area[2] || null,
      area4: filters.area[3] || null,
      area5: filters.area[4] || null,
      area6: filters.area[5] || null,
      area7: filters.area[6] || null,
      bhk: filters.bhk,
      min_budget: minValue,
      max_budget: maxValue,
      possession: filters.possession === "Under Construnction" ? "UnderCons" : filters.possession ==="Ready to Move" ? "Ready" :"Does Not Matter",
    };
    const filterParams = new URLSearchParams();
    filterParams.append("city", filters.city);
    filterParams.append("area", filters.area.join(",")); // Convert array to comma-separated string
    filterParams.append("bhk", filters.bhk.join(","));
    filterParams.append("minValue", minValue);
    filterParams.append("maxValue", maxValue);
    filterParams.append("possession", filters.possession);
  
    
    try {
      const response = await fetch(
        `${BASE_URL}/projects/requirements/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
          body: JSON.stringify(editedItem),
        }
      );

      const updatedDataFromAPI = await response.json();

      if (!response.ok) {
        if (enableNavigation && filters.city !== null) {
          navigate(`/search?${filterParams.toString()}`);
        }
      }else if(enableNavigation && response.ok){
        navigate(`/search?${filterParams.toString()}`);
      }
       else if(response.ok){
        const updatedData = [...otherData];
        updatedData[lastElementIndex] = updatedDataFromAPI;

        setOtherData(updatedData);
          setShowFilterField(false)
      }
    } catch (error) {
      console.error("Error while saving data:", error);
    }
  };

  const handleFilterChange = (field, value) => {
    
    if (field === "area" || field === "bhk" || field === "budget") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
      }));
    } else if (field === "city") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
        area: [], 
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
      }));
    }
    
  };

 
  return (
    <div className="main property-search">
      <div className="find-your-home">
        
          <div className="find-home">
            <ul>
              <li>
                <label htmlFor="city">City</label>
                <select
                  name="city"
                  id="city"
                  className="input-field"
                  onChange={(e) => {
                    setFilters({ ...filters, city: e.target.value });
                  }}
                >
                  <option value="">Select</option>
                  {(city  && city.length>0) &&  
                    city.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.city}
                      </option>
                    ))}
                </select>
              </li>
              <li>
                <label htmlFor="area">Area</label>
                <ReactSelect
                  name="area"
                  id="area"
                  className="input-field select"
                  value={filters.area.length>0 && filters?.area?.map((areaId) => {
                    const selectedArea = area.find((a) => a.id === areaId);
                    return {
                      value: areaId,
                      label: selectedArea ? selectedArea.area : "",
                    };
                  })}
                  onChange={(selectedOptions) =>
                    handleFilterChange(
                      "area",
                      selectedOptions.map((option) => option.value)
                    )
                  }
                  isMulti // Enable multi-select
                  options={filteredAreaData && filteredAreaData?.map((a) => ({
                    value: a.id,
                    label: a.area,
                  }))}
                  styles={customStyles}
                />
              </li>
              <li>
                <label htmlFor="bhk">BHK</label>

                <ReactSelect
                  name="bhk"
                  id="bhk"
                  className="input-field"
                  value={filters?.bhk?.map((bhkValue) => ({
                    value: bhkValue,
                    label: bhkValue,
                  }))}
                  onChange={(selectedOptions) =>
                    handleFilterChange(
                      "bhk",
                      selectedOptions.map((option) => option.value)
                    )
                  }
                  isMulti // Enable multi-select
                  styles={customStyles}
                  options={bhk?.map((bhkOption) => ({
                    value: bhkOption.bhk,
                    label: `${bhkOption.bhk} BHK`,
                  }))}
                />
              </li>
              <li>
                <label htmlFor="budget ">Budget</label>
                {showBudgetSlider ? (
                  <div className="modal-content ">
                    <div className="d-flex justify-content-between pl-3 pr-3 input-field ">
                      <p className="mb-0"> &#8377;{formatValue(minValue)}</p>-
                      <p className="mb-0">&#8377;{formatValue(maxValue)}</p>
                    </div>

                    <MultiRangeSlider
                      label="false"
                      ruler="flase"
                      
                      className={showBudgetSlider ? "multi-ranges-slider " : ""}
                      barLeftColor="grey"
                      barInnerColor="lime"
                      barRightColor="grey"
                      thumbLeftColor="lime"
                      thumbRightColor="lime"
                      min={500000}
                      max={100000000}
                      step={500000}
                      stepOnly={true}
                      minValue={minValue}
                      maxValue={maxValue}
                      onInput={(e) => {
                        handleInput(e);
                      }}
                    />
                    <span
                      onClick={() => setShowBudgetSlider(!showBudgetSlider)}
                      className="apply"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  className="budget"
                  type="button"
                  onClick={() => setShowBudgetSlider(!showBudgetSlider)}
                >
                  <span>&#8377;{formatValue(minValue)} - &#8377;
                  {formatValue(maxValue)}</span>
                </div>
              </li>
              <li>
                <label htmlFor="possession">Possession</label>
                <select
                  name="possession"
                  id="possession"
                  className="input-field"
                  value={filters.possession}
                  onChange={(e) =>
                    handleFilterChange("possession", e.target.value)
                  }
                >
                  <option value="">Does Not Matter</option>
                  <option value="Under Construction">Under Construction</option>
                  <option value="Ready to Move">Ready to Move</option>
                 
                </select>
              </li>

              <li className="submit-btn" onClick={handleSubmit}>
                <Link type="submit">
                  <i className=" bi bi-arrow-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        
        <div className="results">
        <p
              style={{
                color: "#12306c",
                textAlign: "center",
              }}
            >
              Found "{filteredProjects?.length}" results from your selected filters
            </p>
        {filteredProjects && filteredProjects?.map((proj) => <SearchResults project={proj} />)}
          
          
        </div>
      </div>
    </div>
  );
};

export default SearchBox;

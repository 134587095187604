import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/esm/CloseButton';


function ClickableImage({src, alt}) {
  const [show, setShow] = useState(false);

  return (
    <>
      
      <img src={src} alt={alt} onClick={() => setShow(true)}  />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <CloseButton onClick={() => setShow(false)} />
        <Modal.Body className='modal-body' closeButton>
          
           <img src={src} alt={alt}  style={{width:"100%", height:"auto"}}/>
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default ClickableImage;
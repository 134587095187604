import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../../util/Constant';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ApplicantProfile = () => {

  const [applicants, setApplicants] = useState([])
  const accessToken = useSelector((state) => state.authorization.accessToken);

  useEffect(() => {
    const getApplicants = async () => {
      try {
        const response = await fetch(`${BASE_URL}/finance/applicants/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
        });

        const result = await response.json();
        setApplicants(result);
        if (!response.ok) {
          console.log("error Fetching loans");
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    getApplicants(); // Call getCreditCards inside useEffect

  }, [ accessToken]);
  return (
    <div>
      <h2 className="text-center">Applicant Profile</h2>
      <div>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Name</th>
                <th>Status</th>
                <th>Date Created </th>
                <th>Date Updated </th>
              </tr>
            </thead>
            <tbody className="text-center">
            {applicants &&
              applicants?.map((card, index) => (
                <tr key={card.id}>
                  <td>{index + 1}</td>
                  <td>{card.first_name} {card.middle_name} {card.last_name}</td>
                  <td>{card.applicant}</td>
                  <td>{card.created_date}</td>
                  <td>{card.last_updated}</td>
                 
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {applicants && applicants.length>0 ? (<><span>Add Other Applicant</span><Link to="/loanapplication" className='navigate-btn'>Add Now</Link></>) : <><span>No Applicant added yet. </span><Link to="/loanapplication" className='navigate-btn'>Add Now</Link></>}
    </div>
  )
}

export default ApplicantProfile
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const CityName = ({Id}) => {
    const [name, setName] = useState();

    useEffect(() => {
      if (Id) {
        const apiUrl = `${BASE_URL}/projects/city/${Id}/`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const projectName = data.city;
            setName(projectName);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [Id]);
    return (
        <>
             {name && <span style={{marginBottom:"0"}}>{name}</span>}
             </>
       )
     }
export default CityName;

export function getUserDetails(panNo) {
    return new Promise(async (resolve, reject) => {
    try {

        const token = ""
        const response = await fetch(
          `https://sandbox.surepass.io/api/v1/pan/pan-comprehensive`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id_number: panNo }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          resolve({ data });
        }else {
            const error = data
            reject(error);
          }
      } catch (error) {
        console.error("Error:", error);
        reject( error );
      }
    });
  }
import React, { useState } from 'react'
import { BASE_URL } from '../../../util/Constant' 
import { useSelector } from 'react-redux'
import '../addProject/AddProject.css'

const AddBlock = () => {
  const initialData ={
    project :"",
    block:"",
    total_floors:"",
    no_of_units_on_each_floor:""
  }
  const filteredProject  = useSelector(state=>state.builder.projects)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const [data, setData] = useState(initialData)

  const handleInputChange = (e) => {
    const { name } = e.target;
      setData({ ...data, [name]: e.target.value });
   
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
  
      for (const key in data) {
        formData.append(key, data[key]);
      }
  
      const response = await fetch(
        `${BASE_URL}/projects/block/`,
        {
          method: "POST",
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
          body: formData,
        }
      );
      const resp= await response.json();
      if (response.ok) {
        alert("Added Block Successfully");
        setData(initialData)
      } else {
        alert(resp)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <div>
         <div className="mains">
        <div className="container add-project">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="add-unit">
              <h1 className="text-center mb-4">Add Block</h1>
              <div className="form-group">
                  <label >
                    Project
                  </label>
                  <select
                    className="form-control"
                    value={data?.project}
                    id="project"
                    name="project"
                    onChange={handleInputChange} // Update selectedProject on change
                    required
                  >
                    <option value="" >Select</option>
                    {filteredProject &&
                      filteredProject?.map((proj) => {
                        return (
                          <option key={proj.id} value={proj.id}>
                            {proj.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              
              
               
                <div className="form-group">
                  <label htmlFor="total_blocks">Block</label>
                  <input
                    type="text"
                    className="form-control"
                    id="block"
                    name="block"
                    value={data?.block}
                    onChange={handleInputChange}
                    placeholder="Enter block"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="total_blocks">Total Floor</label>
                  <input
                    type="number"
                    className="form-control"
                    id="total_floors"
                    name="total_floors"
                    value={data?.total_floors}
                    onChange={handleInputChange}
                    placeholder="Enter block"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="total_blocks">Number of units on each floor</label>
                  <input
                    type="number"
                    className="form-control"
                    id="no_of_units_on_each_floor"
                    name="no_of_units_on_each_floor"
                    value={data?.no_of_units_on_each_floor}
                    onChange={handleInputChange}
                    placeholder="Enter block"
                    required
                  />
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className=" buttons">
                  Add Block
                </button>
              </div>
           
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddBlock
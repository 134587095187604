import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant'; 

const ProjectName = ({ID}) => {
    const [name, setName] = useState('');

  useEffect(() => {
    if (ID) {
      const apiUrl = `${BASE_URL}/projects/projects/${ID}/`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const projectName = data.name;
          setName(projectName);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [ID]);
  return (
   <>
        {name && <p style={{marginBottom:"0"}}>{name}</p>}
        </>
  )
}

export default ProjectName;
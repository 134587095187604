import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBlocks, getBuilderProjects, getConstStatus, getLayouts, getUnits } from "./builderApi";

export const getBlockAsync = createAsyncThunk(
    'builder/getBlockDetails',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getBlocks(token);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getUnitAsync = createAsyncThunk(
    'builder/getUnits',
    async ({token,id},  {rejectWithValue} ) => {
      try {
        const response = await getUnits({token,id});
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getLayoutAsync = createAsyncThunk(
    'builder/getLayouts',
    async ({token,id},  {rejectWithValue} ) => {
      try {
        const response = await getLayouts({token,id});
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getBuilderProjectsAsync = createAsyncThunk(
    'builder/getBuilderProjects',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getBuilderProjects(token);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getConstStatusAsync = createAsyncThunk(
    'builder/getConstructionStatus',
    async ({token,projId},  {rejectWithValue} ) => {
      try {
        const response = await getConstStatus({token,projId});
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


const builderSlice = createSlice({
    name : 'builder',
    initialState:{
        projects:[],
        blocks: [],
        layouts:[],
        units:[],
        ConstStatus:[],
        isLoading: false,
        error: null
        },
    reducers:{},
    extraReducers: (builder) => {
        builder
        .addCase(getBuilderProjectsAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getBuilderProjectsAsync.fulfilled, (state, action) => {
            state.projects = action.payload;
            state.isLoading = false;
          })
          .addCase(getBuilderProjectsAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
          })
          .addCase(getBlockAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getBlockAsync.fulfilled, (state, action) => {
            state.blocks = action.payload;
            state.isLoading = false;
          })
          .addCase(getBlockAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
          })
          .addCase(getLayoutAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getLayoutAsync.fulfilled, (state, action) => {
            state.layouts = action.payload;
            state.isLoading = false;
          })
          .addCase(getLayoutAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
          })
          .addCase(getUnitAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getUnitAsync.fulfilled, (state, action) => {
            state.units = action.payload;
            state.isLoading = false;
          })
          .addCase(getUnitAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
          })
          .addCase(getConstStatusAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getConstStatusAsync.fulfilled, (state, action) => {
            state.ConstStatus = action.payload;
            state.isLoading = false;
          })
          .addCase(getConstStatusAsync.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading =false
          })

      },
})

export default builderSlice.reducer
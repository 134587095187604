import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const Amenities = (props) => {
    const [amenities, setAmenities] = useState('');

  useEffect(() => {
    if (props.Id) {
      
      const apiUrl = `${BASE_URL}/projects/amenities/${props.Id}/`;

      
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
       
          const AmenitiesFromApi = data.amenities;

          
          setAmenities(AmenitiesFromApi);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [props.Id]);
  return (
    <>
       {amenities && (
          <>
            {amenities}
            {props.isLast ? '' : ', '}
          </>
        )}
    </>
  )
}

export default Amenities;
import React from "react";
import Slider from "react-slick";


const CustomerSpeaks = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    
  };

  return (
 
    <div className="container mb-5 customer-speaks">
      
      <h1 className="text-center mb-5">Customer Speaks</h1>
        <Slider {...settings}>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="customer-image">
                  <img src="/images/coustomer-1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="customer-review">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="customer-image">
                  <img src="/images/coustomer-1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="customer-review">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="customer-image">
                  <img src="/images/coustomer-1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="customer-review">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="customer-image">
                  <img src="/images/coustomer-1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="customer-review">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="customer-image">
                  <img src="/images/coustomer-1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="customer-review">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </Slider>
      </div>
    
  );
};

export default CustomerSpeaks;

// import Carousel from 'react-bootstrap/Carousel';
// // import ExampleCarouselImage from 'components/ExampleCarouselImage';

// function IndividualIntervalsExample() {
//   return (<div className='container customer-speaks'>
//     <Carousel style={{backgroundColor:"wheat",padding:"20px"}}>
//       <Carousel.Item interval={1000}>
//         <img src='/images/pro-1.jpg' />
//         <Carousel.Caption>
//           <h3>First slide label</h3>
//           <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item interval={1000}>
//       <img src='/images/pro-1.jpg' />
//         <Carousel.Caption>
//           <h3>Second slide label</h3>
//           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item  interval={1000}>
//       <img src='/images/pro-1.jpg' />
//         <Carousel.Caption >
//           <h3>Third slide label</h3>
//           <p>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//           </p>
//         </Carousel.Caption>
//       </Carousel.Item>
//     </Carousel></div>
//   );
// }

// export default IndividualIntervalsExample;

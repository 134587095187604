import React, { useEffect, useState } from "react";
import Pages from "./Pages";
import { BASE_URL } from "../../../../util/Constant";
import { useSelector } from "react-redux";

const initialData = {
  first_name: "",
  middle_name: "",
  last_name: "",
  passport_size_photo: null,
  pan_number: "",
  father_husband_name: "",
  select_father_husband: "",
  gender: "",
  date_of_birth: "",
  maritial_status: "",
  residential_status: "",
  education: "",
  specify_education_profession: "",
  minority_religion: "",
  category: "",
  current_address_line1: "",
  current_address_line2: "",
  current_address_line3: "",
  current_address_city_town_village: "",
  current_address_state: "",
  current_address_country: "",
  current_address_pincode: "",
  years_living_at_current_address: "",
  current_address_same_as_permanent_address: true,
  current_address_type: "",
  permanent_address_line1: "",
  permanent_address_line2: "",
  permanent_address_line3: "",
  permanent_address_city_town_village: "",
  permanent_address_state: "",
  permanent_address_country: "",
  permanent_address_pincode: "",
  occupation: "",
  company_name: "",
  designation: "",
  office_address: "",
  office_city: "",
  office_pincode: "",
  organisation_type: "",
  department: "",
  employment_status: "",
  employed_in_this_organisation: "",
  retirement_age: "",
  contact_person_name: "",
  contact_person_phone: "",
  is_there_a_break_service_beyond_3_months_in_2_years: "false",
  comments: "",
  established_since: "",
  gst_registered: "false",
  gst_number: "",
  specify_profession_if_profession: "",
  activity: "",
  activity_comment: "",
  website: "",
  business_email: "",
  net_profit_earned_in_last_2_years: "false",
  gross_monthly_income: "",
  net_monthly_income: "",
  work_experience_years: "",
  work_experience_months: "",
  rent: "",
  agriculture: 0,
  consultancy: 0,
  other_income: 0,
  want_to_give_this_property_on_rent_it: "false",
  expected_monthly_rent: 0,
  mutual_fund:0,
  pf_ppf_insurance_sukanyasamriddhi_retirement_plan: 0,
  gold: 0,
  shares_stocks: 0,
  immovable_properties: 0,
  any_other_asset: 0,
  number_of_credit_cards_you_have: "",
  number_of_active_loans: "",
};

const LoanForm = () => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(initialData);
  const accessToken = useSelector((state) => state.authorization.accessToken);

  const title = {
    0: "Basic Details",
    1: "Occupation Details",
    2: "Assets & Liabilities",
  };

  const handleprev = () => setPage((prev) => prev - 1);
  const handlenext = () => setPage((prev) => prev + 1);
  const disablePrev = page === 0;
  const disableNext = page === Object.keys(title).length - 1;
  const prevHide = page === 0 && "remove-button";
  const nextHide = page === Object.keys(title).length - 1 && "remove-button";
  const submitHide = page !== Object.keys(title).length - 1 && "remove-button";

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", data);
    
    const formData = new FormData(); 

    // Append data to the FormData object
    for (const key in data) {
       if (data[key] instanceof File) {
        formData.append(key, data[key]); 
      } else {
        formData.append(key, data[key]); 
      }
    }
    console.log(formData, ":FormData");
    try {

      
      const response = await fetch(`${BASE_URL}/finance/applicants/`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
        body: formData,
      });
      if (response.ok) {
        alert("Loan application added successfully");
        setData(initialData)
        // setAddCard(false)
        // window.location.reload()
      } else {
        alert("Error adding application");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      
      const selectedFile = e.target.files[0];
      const formData = new FormData();
       formData.append(name, selectedFile);
    console.log(selectedFile, ": formdata");
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile,
      }));
    }  else {
      setData((prevData) => ({ ...prevData, [name]: e.target.value }));
    }
  };

  

  useEffect(() => {
    if (data.current_address_same_as_permanent_address === "Yes") {
      setData((prevData) => ({
        ...prevData,
        permanent_address_line1: prevData.current_address_line1,
        permanent_address_line2: prevData.current_address_line2,
        permanent_address_line3: prevData.current_address_line3,
        permanent_address_city_town_village:prevData.current_address_city_town_village,
        permanent_address_state: prevData.current_address_state,
        permanent_address_country: prevData.current_address_country,
        permanent_address_pincode: prevData.current_address_pincode,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        permanent_address_line1: "",
        permanent_address_line2: "",
        permanent_address_line3: "",
        permanent_address_city_town_village: "",
        permanent_address_state: "",
        permanent_address_country: "",
        permanent_address_pincode: "",
      }));
    }
  }, [data.current_address_same_as_permanent_address]);

  return (
    <div className="mains">
      <div className="container">
        <div className="add-project">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <h1 className="text-center mb-3  ">{title[page]}</h1>
            <Pages
              page={page}
              handleInputChange={handleInputChange}
              data={data}
            />

            <div className="button-container text-center ">
              <button
                type="button"
                className={`m-2 btn btn-dark  ${prevHide}`}
                onClick={handleprev}
                disabled={disablePrev}
              >
                Back
              </button>
              <button
                type="button"
                className={`m-2 btn btn-primary  ${nextHide}`}
                onClick={handlenext}
                disabled={disableNext}
              >
                Save and Proceed
              </button>
              <button
                type="submit"
                className={`m-2 btn btn-primary  ${submitHide}`}
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoanForm;

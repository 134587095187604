import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './Layout.css'
import { useDispatch, useSelector } from 'react-redux';
import { getLayoutAsync } from '../../../features/builder/builderSlice';
import UnitType from '../../../components/card/UnitType';
import useFetch from '../../../hooks/useFetch';

const Layout = ({selectedProjId}) => {
//   const {layouts,getLayout} = useBuilderDetail();
  const dispatch = useDispatch()
  const layouts = useSelector(state=>state.builder.layouts)
  const {data: unitTypes } = useFetch("/projects/unit_types/");
  const accessToken = useSelector(state=>state.authorization.accessToken)
 const [filter, setFilter] = useState({
  unit_type : "",
 })



useEffect(()=>{
  dispatch(getLayoutAsync({token : accessToken, id:selectedProjId}))
},[dispatch,selectedProjId, accessToken])

const sortedLayouts = layouts?.filter((layout)=>{
  return(filter.unit_type === "" || parseInt(filter.unit_type ) === layout.unit_type[0])
})

  return (
    <div className='container'>
      
      <div className='d-flex justify-content-between'>
      <h1 className="text-center mb-4">All Layouts</h1>
      <h1><Link to="/addlayout"><i className="bi bi-plus-circle"></i></Link></h1>
      </div>
      <div className='unit-type-filter'>
        
        
          
          <select
          className='form-control'
          name='unit_type'
          value={filter.unit_type}
          onChange={(event)=>{setFilter({...filter,[event.target.name]: event.target.value})}}
          >
            <option value="">All Unit type</option>
            {
              unitTypes?.map(type => (<option value={type.id}>{type.unittypes}</option>))
            }
          </select>
        
      </div>
      <div className="project-details layout-details" style={{overflowX:"auto", marginBottom:"20px"}}>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Nickname</th>
                <th>BHK</th>
                <th>Unit type</th>
                <th>Reracarpert Area</th>
                <th>Builtup Area</th>
                <th>Superbuiltup Area</th>
                <th>Layout Image</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody className="text-center">
               {sortedLayouts && sortedLayouts?.map((layout,index) =>
                  (
                    <tr key={layout.id}>
                      <td>{index+1}</td>
                      <td>{layout.nickname ? layout.nickname : `nickname${index+1}`}</td>
                      <td>{layout.bhk}</td>
                      <td><UnitType Id={layout.unit_type}/></td>
                      {/* <td>{layout.unit_type ? <UnitType ID = {layout.unit_type}/> : "Not Avl"}</td> */}
                      <td>{layout.RERAcarpetarea ? layout.RERAcarpetarea : "Not Available"}</td>
                      <td>{layout.builtuparea ? layout.builtuparea : "Not Available"}</td>
                      <td>{layout.superbuiltuparea ? layout.superbuiltuparea : "Not Available"}</td>
                      <td>
                        <img 
                          src={layout.layoutimage} 
                          alt={` ${index + 1 }`}
                          style={{width:"100px", height:"100px", objectFit:"cover"}}
                        />
                      </td>
                      <td>{layout.comment ? layout.comment : "comment"}</td>
                    </tr>
                )
              )} 
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Layout;

import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-section">
		<div className="container">
			<footer>
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="left-footer">
							<ul>
								<li>
									<p>RERA Number : <span></span></p>
								</li>
								<li>
									<Link>About Us</Link>
								</li>
								<li>
									<Link>Contact</Link>
								</li>
								<li>
									<Link>Terms & Conditions</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="right-footer">
							<p>Subscribe for our exclusive offers !</p>
							<form>
								<input type="text" name="email" placeholder="email"/>
							</form>
							<h4>Call : <a href="tel:+911234567890">91 XXXXXX YYYYYY</a></h4>
							<h4>Email : <a href="mailto:hello@gmail.com">hello@.........</a></h4>
						</div>
					</div>
				</div>
				<div className="coppyright text-center">
					<p>&copy; The Discount King 2021</p>
				</div>
			</footer>
		</div>
	</div>
  )
}

export default Footer
import { BASE_URL } from "../../util/Constant"

export function sitevisit({token,data}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/sitevisits/`, {
          method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify(data),
      });
        if (response.ok) {
          const data = await response.json();
          alert("Feedback added successfully")
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }

  export function getSiteVisits(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/projects/sitevisits/`, {
          method: 'GET',
          headers: { 
            'content-type': 'application/json',
            'Authorization': `JWT ${token}`

           },
        });
        if (response.ok) {
          const data = await response.json();
          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }
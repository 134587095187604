import React from "react";

const BasicDetail = ({handleInputChange,data}) => {
  const showPermanentAddressFields = data?.current_address_same_as_permanent_address === "No";
  
  
  return (
    <div className="row">
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">PAN number*</label>
        <input
          type="text"
          className="form-control"
          id="pan_number"
          name="pan_number"
          value={data?.pan_number}
          onChange={handleInputChange}
          placeholder="Enter your  PAN Number"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Upload Photo</label>
        <input
          type="file"
          className="form-control"
          id="passport_size_photo"
          name="passport_size_photo"
          onChange={handleInputChange}
          
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-4">
        <label htmlFor="name">First name*</label>
        <input
          type="text"
          className="form-control"
          id="first_name"
          name="first_name"
          value={data?.first_name}
          onChange={handleInputChange}
          placeholder="Enter Your First Name"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-4">
        <label htmlFor="name">Middle name*</label>
        <input
          type="text"
          className="form-control"
          id="middle_name"
          name="middle_name"
          value={data?.middle_name}
          onChange={handleInputChange}
          placeholder="Enter Your Middle Name"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-4">
        <label htmlFor="name">Last name*</label>
        <input
          type="text"
          className="form-control"
          id="last_name"
          name="last_name"
          value={data?.last_name}
          onChange={handleInputChange}
          placeholder="Enter Your Last Name"
          required
        />
      </div>
      <div className="form-group  col-6 d-flex gap-4 align-items-center ">
        
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="select_father_husband"
            onChange={handleInputChange}
            id="Father"
            value="Father"
          />
          <label className="form-check-label" htmlFor="Father">
            Father
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="select_father_husband"
            onChange={handleInputChange}
            id="husband"
            value="husband"
          />
          <label className="form-check-label" htmlFor="husband">
            Husband
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="name">Father/Husband Name*</label>
          <input
            type="text"
            className="form-control"
            id="father_husband_name"
            name="father_husband_name"
            value={data?.father_husband_name}
            onChange={handleInputChange}
            placeholder="Enter  father's or husband's name"
            required
          />
        </div>
      </div>
      <div className="form-group col-lg-6 col-sm-12 col-md-12 d-flex gap-4 align-items-center">
          <label htmlFor="name">Gender* : </label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              onChange={handleInputChange}
              id="Male"
              value="Male"
            />
            <label className="form-check-label" htmlFor="Male">
              Male
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              onChange={handleInputChange}
              id="Felmale"
            />
            <label className="form-check-label" htmlFor="Felmale">
              Felmale
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              onChange={handleInputChange}
              id="3rd Gender"
            />
            <label className="form-check-label" htmlFor="3rd Gender">
              3rd Gender
            </label>
          </div>
        
        </div>
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Data of Birth*</label>
        <input
          type="date"
          className="form-control"
          id="date_of_birth"
          name="date_of_birth"
          value={data?.date_of_birth}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Maritial Status*</label>
        <select
            className="form-control"
            id="maritial_status"
            name="maritial_status"
            value={data?.maritial_status}
            onChange={handleInputChange}
            required
          >
            <option value="">Select status</option>
            <option value="Married">Married</option>
            <option value="Unmarried">Unmarried</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Education*</label>
        <select
            className="form-control"
            id="education"
            name="education"
            value={data?.education}
            onChange={handleInputChange}
            required 
          >
            <option value="">Select Education status</option>
            <option value="Undergraduate">UnderGraduate</option>
            <option value="Graduate">Graduate</option>
            <option value="Post Graduate">Post Graduate</option>
            <option value="Professional">Professional</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">If Professional, Pls Specify</label>
        <input
          type="text"
          className="form-control"
          id="specify_education_profession"
          name="specify_education_profession"
          value={data?.specify_education_profession}
          onChange={handleInputChange}
          placeholder="Enter  profession if any"
        />
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Residential Status*</label>
        <select
            className="form-control"
            id="residential_status"
            name="residential_status"
            value={data?.residential_status}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Residential status</option>
            <option value="Resident Indian">Resident Indian</option>
            <option value="NRI">NRI</option>
            <option value="PIO">PIO</option>
            <option value="Foreign Citizen">Foreign Citizen</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">If Minority, Specify Religion</label>
        <select
            className="form-control"
            id="minority_religion"
            name="minority_religion"
            value={data?.minority_religion}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Buddhist">Buddhist</option>
            <option value="Christian">Christian</option>
            <option value="Jain">Jain</option>
            <option value="Muslim">Muslim</option>
            <option value="Sikh">Sikh</option>
            <option value="Zorastrian">Zorastrian</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Select Category, if Applicable</label>
        <select
            className="form-control"
            id="category"
            name="category"
            value={data?.category}
            onChange={handleInputChange}
          >
            <option value="">Select status</option>
            <option value="General">General</option>
            <option value="Scheduled Caste">Scheduled Caste</option>
            <option value="Scheduled Tribe">Scheduled Tribe</option>
            <option value="Other Backward Classes">Other Backward Classes</option>
          </select>
      </div>
      <h4>Current Address :</h4>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 1*
          </label>
          <input type="text" className="form-control" id="current_address_line1" 
          value={data?.current_address_line1}
          name="current_address_line1"
          onChange={handleInputChange}
          placeholder="Enter  current address line 1"
          required />
        </div>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 2*
          </label>
          <input type="text" className="form-control" id="current_address_line2" 
          name="current_address_line2"
          value={data?.current_address_line2}
          onChange={handleInputChange}
          placeholder="Enter Current address line 2"
          required />
        </div>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 3*
          </label>
          <input type="text" className="form-control" id="current_address_line3" 
          name="current_address_line3"
          value={data?.current_address_line3}
          onChange={handleInputChange}
          placeholder="Enter Current address line 3"
          required />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
          City / Town / Village*  
          </label>
          <input type="text" className="form-control" id="current_address_city_town_village"
          name="current_address_city_town_village"
          value={data?.current_address_city_town_village}
          onChange={handleInputChange}
          placeholder="City/Town/Village"
          required />
        </div>
       
        
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">State*</label>
        <input
          type="text"
          className="form-control"
          id="current_address_state"
          name="current_address_state"
          value={data?.current_address_state}
          onChange={handleInputChange}
          placeholder="State"
          required
        />
      </div>
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Country*</label>
        <input
          type="text"
          className="form-control"
          id="current_address_country"
          name="current_address_country"
          value={data?.current_address_country}
          onChange={handleInputChange}
          placeholder="Country"
          required
        />
      </div>
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Pin code*</label>
        <input
          type="text"
          className="form-control"
          id="current_address_pincode"
          name="current_address_pincode"
          value={data?.current_address_pincode}
          onChange={handleInputChange}
          placeholder="Enter Pin Code"
          maxLength='6'
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
          Number of years living at current address ?*
          </label>
          <input type="number" className="form-control" id="years_living_at_current_address"
          name="years_living_at_current_address"
          value={data?.years_living_at_current_address}
          onChange={handleInputChange}
          placeholder="Enter  number of years" 
           required />
        </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
          Current Address type*
          </label>
          <select
            className="form-control"
            id="current_address_type"
            name="current_address_type"
            value={data?.current_address_type}
            onChange={handleInputChange}
            required
          >
            <option value="">Select type</option>
            <option value="Owned">Owned</option>
            <option value="Rented">Rented</option>
            <option value="Company Provided">Company Provided</option>
          </select>
        </div>
        <div className="form-group col-12 d-flex gap-4 align-items-center">
          <label htmlFor="name">Is Current Address Same as Permanent Address* :</label>
          <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="current_address_same_as_permanent_address"
            id="Yes"
            value="Yes"
            checked={data?.current_address_same_as_permanent_address === "Yes"}
            onChange={handleInputChange}
          />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="current_address_same_as_permanent_address"
              onChange={handleInputChange}
              id="No"
              value="No"
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
        </div>
        {showPermanentAddressFields && (<>
        <h4 className="mt-4">Permanent Address :</h4>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 1*
          </label>
          <input type="text" className="form-control" 
          id="permanent_address_line1"
          name="permanent_address_line1"
          value={data?.permanent_address_line1}
          onChange={handleInputChange}
          placeholder="Enter permanent  address line 1"
           required />
        </div>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 2*
          </label>
          <input type="text" className="form-control" 
          id="permanent_address_line2"
          name="permanent_address_line2"
          value={data?.permanent_address_line2}
          onChange={handleInputChange}
          placeholder="Enter permanent address line 2 " 
          required />
        </div>
      <div className="form-group col-12">
          <label htmlFor="name">
          Line 3*
          </label>
          <input type="text" className="form-control" 
          id="permanent_address_line3"
          name="permanent_address_line3"
          value={data?.permanent_address_line3}
          onChange={handleInputChange}
          placeholder="Enter permanent address line 3"
          required />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
          City / Town / Village * 
          </label>
          <input type="text" className="form-control"
          id="permanent_address_city_town_village"
          name="permanent_address_city_town_village"
          value={data?.permanent_address_city_town_village}
          onChange={handleInputChange}
          placeholder="City/Town/Village" 
          required />
        </div>
        
        
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">State*</label>
        <input
          type="text"
          className="form-control"
          id="permanent_address_state"
          name="permanent_address_state"
          value={data?.permanent_address_state}
          onChange={handleInputChange}
          placeholder="Enter State"
          required
        />
      </div>
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Country*</label>
        <input
          type="text"
          className="form-control"
          id="permanent_address_country"
          name="permanent_address_country"
          value={data?.permanent_address_country}
          onChange={handleInputChange}
          placeholder="Enter Country"
          required
        />
      </div>
        <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Pin code*</label>
        <input
          type="number"
          className="form-control"
          id="permanent_address_pincode"
          name="permanent_address_pincode"
          value={data?.permanent_address_pincode}
          onChange={handleInputChange}
          placeholder="Enter Pin Code"
          required
        />
      </div></>)}
      <div className="form-group col-sm-12 col-sm-6 col-lg-6">
        <label htmlFor="name">Occupation* : </label>
        <select
            className="form-control"
            id="occupation"
            name="occupation"
            value={data?.occupation}
            onChange={handleInputChange}
            required
          >
            <option value="">Select </option>
            <option value="Business">Business</option>
            {/* <option value="Self Employed">Self Employed</option> */}
            <option value="Salaried">Salaried</option>
            {/* <option value="Farmer">Farmer</option>
            <option value="Retired">Retired</option>
            <option value="Pensioner">Pensioner</option>
            <option value="Student">Student</option>
            <option value="Home Maker">Home Maker</option>
            <option value="Unemployed">Unemployed</option> */}
          </select>
      </div>
    </div>
  );
};

export default BasicDetail;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBuilderDetails, getBuilders, getUserDetails, updateUserDetail } from "./userApi";


export const getUserDetailsAsync = createAsyncThunk(
    'user/getUserDetails',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getUserDetails(token);
        return response.data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  );

export const getBuilderDetailsAsync = createAsyncThunk(
    'user/getBuilderDetails',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getBuilderDetails(token);
        return response.data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  );

  export const getBuildersAsync = createAsyncThunk(
    'user/getBuilders',
    async (token,  {rejectWithValue} ) => {
      try {
        const response = await getBuilders(token);
        return response.data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  );
export const updateUserDetailAsync = createAsyncThunk(
    'user/updateUserDetail',
    async ( {newData,token},{rejectWithValue} ) => {
      try {
        const response = await updateUserDetail({newData, token});
        return response.data;
      } catch (error) {
        console.log("eroor in userSlice", rejectWithValue(error));
        return rejectWithValue(error);
      }
    }
  );
 const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetail: null,
    builders:null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getUserDetailsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        state.userDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(updateUserDetailAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserDetailAsync.fulfilled, (state, action) => {
        state.userDetail = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateUserDetailAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getBuilderDetailsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBuilderDetailsAsync.fulfilled, (state, action) => {
        state.userDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(getBuilderDetailsAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getBuildersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBuildersAsync.fulfilled, (state, action) => {
        state.builders = action.payload;
        state.isLoading = false;
      })
      .addCase(getBuildersAsync.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export default userSlice.reducer
import React, { useEffect, useState } from 'react'
import AddCreditCard from '../../loanApplication/addCreditCard/AddCreditCard'
import AddLoans from '../../loanApplication/addExistingLoans/AddLoans'
import { BASE_URL } from '../../../../util/Constant';
import { useSelector } from 'react-redux';

const CreditAndLoan = () => {
  const [applicants, setApplicants] = useState([])

  const accessToken = useSelector((state) => state.authorization.accessToken);

  const fetchApplicant = async () => {
    const response = await fetch(`${BASE_URL}/finance/applicants/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Can not get the applicants");
    }
    const result = await response.json();
    setApplicants(result);
  };
  const [applicant, setApplicant] = useState("")

  useEffect(() => {
    fetchApplicant();
  }, []);


  return (
    <div className='container '>
      <h2 className='text-center '>Credit Cards & Existing Loans</h2>
      <div className='mt-4'>
        <label htmlFor="applicant">Select the Applicant for which you want to Add  a Loan or Credit Card</label>
        <select
            className="form-control"
            id="applicant"
            name="applicant"
            value={applicant}
            onChange={(e)=>setApplicant(e.target.value)}
          >
            <option value="">Select  Applicant</option>
            {applicants?.map((applicant) => (
              <option key={applicant?.id} value={applicant?.id}>
                {applicant?.first_name} {applicant?.last_name}
              </option>
            ))}
          </select>
      </div>
      <div className='mt-4'>
      <AddCreditCard id={applicant}/></div>
      <div className='mt-4'>
      <AddLoans id={applicant}/></div>
    </div>
  )
}

export default CreditAndLoan
import React, { useEffect, useState } from "react";
import { Link,  useNavigate } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import "./Dashboard.css";
import Slider from "react-slick";
import { BASE_URL } from "../../../util/Constant";
import {useDispatch, useSelector} from "react-redux"
import { getBuilderDetailsAsync } from "../../../features/user/userSlice";
import { getBuilderProjectsAsync } from "../../../features/builder/builderSlice";
import Block from "../blocks/Block";
import Booking from "../Booking/Booking";
import Layout from "../layouts/Layout";
import Units from "../units/Unit";
import ConstStatus from "../constStatus/ConstStatus";
import Loader from "../../../components/loader/Loader";

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const userData = useSelector(state=>state.authorization.user)
  const builderData=useSelector(state=>state.user.userDetail)
  const isLoading=useSelector(state=>state.user.isLoading)
  const filteredProject = useSelector(state=>state.builder.projects)
  const loading = useSelector(state=>state.builder.isLoading)
  const [selectedProject, setSelectedProject] = useState();
  const [selectedSection, setSelectedSection] = useState("Booking");
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };
 
  useEffect(() => {
    const fetchBuilderProjects = async () => {
      try {
        const response = await fetch(`${BASE_URL}/projects/builder_projects/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
        });
        const data = await response.json();
        if (data && data.length > 0) {
          setSelectedProject(data[0]);
        }
      } catch {
        console.log("error");
      }
    };
    fetchBuilderProjects()
  }, [accessToken]);

  const addProject =()=>{
    navigate("/addproject")
  }
  useEffect(() => {
    dispatch(getBuilderDetailsAsync(accessToken));
    dispatch(getBuilderProjectsAsync(accessToken))
  }, [dispatch,accessToken]);
  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <>
    {loading && isLoading ? <Loader/> :<div className="dashboard">
      <div className="builder-detail-section">
        <div className="container">
          <div className="builder-detail">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="builder-detail-left ">
                  <img
                    src={
                      builderData?.logo
                        ? builderData?.logo
                        : "images/NotAvailble.png"
                    }
                    alt=""
                  />
                  <div className="my-1">
                    <h5>Name : {builderData?.name}</h5>
                    <h5>Account :{userData?.user_type}  Primary</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="builder-detail-right text-right">
                  <ul>
                    <li>
                      <Link to="#" title="">
                        <img src="images/message.png" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" title="">
                        <img src="images/notification.png" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {filteredProject.length>0 ? <><div className="our-project-tabs">
        <select
          className="selectProject"
          onChange={(e) => handleProjectClick(filteredProject[e.target.value])}
          value={filteredProject?.findIndex(
            (project) => selectedProject === project
          )}
        >
          <option value="" hidden>
            Select Project
          </option>
          {filteredProject?.map((project, index) => (
            <option key={project.id} value={index}>
              {project.name}
            </option>
          ))}
        </select>
      </div>

      {selectedProject && (
        <div className="project-details container">
          <div className="project-content">
            <div className="row">
              <div
                className="col-lg-3 col-md-3 col-sm-12"
                style={{ maxWidth: "300px" }}
              >
                {/* <ClickableImage src={selectedProject.image1 ? selectedProject.image1 : "images/pro-1.jpg"} alt=""/> */}
                <section className="slider-for">
                  {selectedProject ? (
                    <Slider {...sliderSettings}>
                      <div className="customer-image">
                        <ClickableImage
                          src={
                            selectedProject.image1
                              ? selectedProject.image1
                              : "/images/pro-1.jpg"
                          }
                          alt="Image 1"
                        />
                      </div>
                      <div className="customer-image">
                        <ClickableImage
                          src={
                            selectedProject.image2
                              ? selectedProject.image2
                              : "/images/pro-2.jpg"
                          }
                          alt="Image 1"
                        />
                      </div>
                      <div className="customer-image">
                        <ClickableImage
                          src={
                            selectedProject.image3
                              ? selectedProject.image3
                              : "/images/pro-3.jpg"
                          }
                          alt="Image 1"
                        />
                      </div>
                      <div className="customer-image">
                        <ClickableImage
                          src={
                            selectedProject.image4
                              ? selectedProject.image4
                              : "/images/pro-1.jpg"
                          }
                          alt="Image 1"
                        />
                      </div>
                    </Slider>
                  ) : (
                    <div>Loading...</div>
                  )}
                </section>
              </div>

              <div className="col-lg-9 col-md-9 col-sm-12">
                <h4 className="ml-4">Project Name: {selectedProject.name}</h4>
                <h5 className="ml-4 mb-3 d-flex">
                  {/* <AreaName areaID={selectedProject.area} />, &nbsp;{" "}
                  <CityName cityId={selectedProject.city} /> */}
                </h5>
                <div className="total-units ">
                  <ul className="units">
                    <li>
                      Total Units:{selectedProject.total_units_in_project}
                    </li>
                    <li>Sold 15</li>
                    <li>Unsold 10</li>
                  </ul>
                  <ul className="stage">
                    <li>Stage: {selectedProject.construction_stage}</li>
                    <li>Pre ATS 8</li>
                    <li>Post ATS 7</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <ul className="detail-list">
          <li
            onClick={() => handleSectionClick("Booking")}
            className={selectedSection === "Booking" ? "selected-item" : ""}
          >
            Booking
          </li>
          <li
            onClick={() => handleSectionClick("Units")}
            className={selectedSection === "Units" ? "selected-item" : ""}
          >
            Units
          </li>
          <li
            onClick={() => handleSectionClick("Blocks")}
            className={selectedSection === "Blocks" ? "selected-item" : ""}
          >
            Blocks
          </li>
          <li
            onClick={() => handleSectionClick("Layouts")}
            className={selectedSection === "Layouts" ? "selected-item" : ""}
          >
            Layouts
          </li>
          <li
            onClick={() => handleSectionClick("ConstructionStatus")}
            className={
              selectedSection === "ConstructionStatus" ? "selected-item" : ""
            }
          >
            Construction status
          </li>
          <li
            onClick={() => handleSectionClick("EditProject")}
            className={selectedSection === "EditProject" ? "selected-item" : ""}
          >
            Edit Project
          </li>
        </ul>
        <div className="detail-list-dropdown">
   
    <select
      id="sectionDropdown"
      value={selectedSection}
      onChange={(e) => handleSectionClick(e.target.value)}
    >
      <option value="Booking">Booking</option>
      <option value="Units">Units</option>
      <option value="Blocks">Blocks</option>
      <option value="Layouts">Layouts</option>
      <option value="ConstructionStatus">Construction status</option>
      <option value="EditProject">Edit Project</option>
    </select>
  </div>
        {selectedSection && (
          <div>
            {selectedSection === "Units" && (
              <div >
                <Units selectedProjId={selectedProject?.id} />
              </div>
            )}
            {selectedSection === "Blocks" && (
              <div >
                <Block selectedProjId={selectedProject?.id} />
              </div>
            )}
            {selectedSection === "Layouts" && (
              <div >
                <Layout selectedProjId={selectedProject?.id} />
              </div>
            )}
            {selectedSection === "ConstructionStatus" && (
              <div >
                <ConstStatus selectedProjId={selectedProject?.id} />
              </div>
            )}
            {selectedSection === "Booking" && (
              <div >
                <Booking selectedProjId={selectedProject?.id} />
              </div>
            )}
          </div>
        )}
      </div>
        </>:<div className="container text-center m-5">
        <h1>You do not have any Projects Yet </h1>
        <button onClick={addProject} className="buttons">Add Project</button>
        </div>
        
      
      }
      </div>
      
    </div>}
    </>
  );
};

export default Dashboard;

import React from "react";

const OccupationSelfEmployed = ({ handleInputChange, data }) => {
  return (
    <div className="row ">
      <div className="form-group col-sm-12 col-md-12 col-md-12">
        <label htmlFor="name">Company/Firm name</label>
        <input
          type="text"
          className="form-control"
          id="company_name"
          name="company_name"
          value={data?.company_name}
          onChange={handleInputChange}
          placeholder="Enter Company  Name"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Designation</label>
        <input
          type="text"
          className="form-control"
          id="designation"
          name="designation"
          value={data?.designation}
          onChange={handleInputChange}
          placeholder="Enter Designation"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Organisation Type :</label>
       
        <select
            className="form-control"
            id="organisation_type"
            name="organisation_type"
            value={data?.organisation_type}
            onChange={handleInputChange}
          >
            <option value="">Select</option>            
            <option value="MNC">MNC</option>
            <option value="Public Ltd. Co.">Public Ltd. Co.</option>
            <option value="Pvt. Ltd. Co./ LLP / LLC / OPC">Pvt. Ltd. Co./ LLP / LLC / OPC</option>
            <option value="Proprietorship / Partnership / HUF">Proprietorship / Partnership / HUF</option>
          </select>
      </div>
      <div className="form-group col-sm-12 col-md-12 col-md-12">
        <label htmlFor="name">Business Address: </label>
        <textarea
          type="text"
          className="form-control"
          id="office_address"
          name="office_address"
          value={data?.office_address}
          onChange={handleInputChange}
          placeholder="Address of Your  Business"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Bussiness City / Town / Village: </label>
        <input
          type="text"
          className="form-control"
          id="office_city"
          name="office_city"
          value={data?.office_city}
          onChange={handleInputChange}
          placeholder="Enter City/Town/Village Name"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Pin Code: </label>
        <input
          type="number"
          className="form-control"
          id="office_pincode"
          name="office_pincode"
          value={data?.office_pincode}
          onChange={handleInputChange}
          placeholder="Enter  Pin code"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Established Since :</label>
        <input
          type="date"
          className="form-control"
          id="established_since"
          name="established_since"
          value={data?.established_since}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-lg-6 d-flex gap-4 align-items-center ">
        <label htmlFor=""> GST Registered : </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="gst_registered"
            // onChange={() => handlehomeloan("Yes")}
            onChange={handleInputChange}
            id="Yes"
            value="Yes"
          />
          <label className="form-check-label" htmlFor="Yes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="gst_registered"
            value="No"
            onChange={handleInputChange}
            id="No"
          />
          <label className="form-check-label" htmlFor="No">
            No
          </label>
        </div>
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">GST Number : </label>
        <input
          type="number"
          className="form-control"
          id="gst_number"
          name="gst_number"
          value={data?.gst_number}
          onChange={handleInputChange}
          placeholder="Enter the GST number"
          required
        />
      </div>     
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Website (Optional ) : </label>
        <input
          type="text"
          className="form-control"
          id="website"
          name="website"
          value={data?.website}
          onChange={handleInputChange}
          placeholder="Enter website  url if any."
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Business Email (Optional ) : </label>
        <input
          type="text"
          className="form-control"
          id="business_email"
          name="business_email"
          value={data?.business_email}
          onChange={handleInputChange}
          placeholder="Enter business email address."
          required
        />
      </div>

      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Gross Monthly Income :</label>
        <input
          type="text"
          className="form-control"
          id="gross_monthly_income"
          name="gross_monthly_income"
          value={data?.gross_monthly_income}
          onChange={handleInputChange}
          placeholder="Enter amount in INR"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Net Monthly Income :</label>
        <input
          type="text"
          className="form-control"
          id="net_monthly_income"
          name="net_monthly_income"
          value={data?.net_monthly_income}
          onChange={handleInputChange}
          placeholder="Enter amount in INR"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Work Exp. (Years) :</label>
        <input
          type="text"
          className="form-control"
          id="work_experience_years"
          name="work_experience_years"
          value={data?.work_experience_years}
          onChange={handleInputChange}
          placeholder="Nuber of years"
          required
        />
      </div>
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Work Exp. (Months) :</label>
        <input
          type="text"
          className="form-control"
          id="work_experience_months"
          name="work_experience_months"
          value={data?.work_experience_months}
          onChange={handleInputChange}
          placeholder="Number of months"
          required
        />
      </div>
      
      <div className="form-group col-sm-12 col-md-6 col-md-6">
        <label htmlFor="name">Activity Type :</label>
       
        <select
             className="form-control"
             id="activity"
             name="activity"
             value={data?.activity}
             onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Trading">Trading</option>
            <option value="Service Industry">Service Industry</option>
            <option value="Profession">Profession</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Others">Others</option>
          </select>
      </div>
     
      <div className="form-group d-flex col-12 align-items-center">
        <label htmlFor="name" className="col-6">
          Specify Profession, if Profession :
        </label>
        <input type="text" className="form-control" 
        id="specify_profession_if_profession"
        name="specify_profession_if_profession"
        value={data?.specify_profession_if_profession}
        onChange={handleInputChange}
        placeholder="Enter Profession name"
        required />
      </div>
      <div className="form-group col-12">
        <label htmlFor="name">Activity Comment :</label>
        <textarea
          type="text"
          className="form-control"
          id="activity_comment"
          name="activity_comment"
          value={data?.activity_comment}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group col-12 d-flex gap-4 align-items-center ">
        <label htmlFor="">Net Profit earned in last 2 years ?</label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="net_profit_earned_in_last_2_years"
            // onChange={() => handlehomeloan("Yes")}
            id="Yes"
            value="Yes"
          />
          <label className="form-check-label" htmlFor="Yes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="net_profit_earned_in_last_2_years"
            // onChange={() => handlehomeloan("No")}
            id="No"
          />
          <label className="form-check-label" htmlFor="No">
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default OccupationSelfEmployed;

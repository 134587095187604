import React, { useState } from "react";

const Asset = ({ handleInputChange, data }) => {
  const [wantToRent, setWantToRent] = useState("false");

  // Handle radio button change
  const handleRadioChange = (event) => {
    setWantToRent(event.target.value);
    handleInputChange({
      target: {
        name: "want_to_give_this_property_on_rent_it",
        value: event.target.value,
      },
    });
  };

  return (
    <div>
      <div className="row ">
        <h4>Additional Income : </h4>
        <div className="form-group col-sm-12 col-md-12 col-md-12">
          <label htmlFor="name">
            Monthly Rental Income from Existing Immovable Properties :{" "}
          </label>
          <input
            type="text"
            className="form-control"
            id="rent"
            name="rent"
            value={data?.rent}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Annual Consultancy Income :</label>
          <input
            type="text"
            className="form-control"
            id="consultancy"
            name="consultancy"
            value={data?.consultancy}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Annual Agricultural Income :</label>
          <input
            type="text"
            className="form-control"
            id="agriculture"
            name="agriculture"
            value={data?.agriculture}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Any other Annual Income :</label>
          <input
            type="text"
            className="form-control"
            id="other_income"
            name="other_income"
            value={data?.other_income}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        {/* <div className="form-group col-sm-12 col-md-12 col-md-12">
          <label htmlFor="name">
          Income Comments:  
          </label>
          <textarea type="text" className="form-control"
          id="mutual_fund" 
          name="mutual_fund"
          value={data?.mutual_fund}
          required />
        </div> */}
        <div className="form-group col-12 d-flex gap-4 align-items-center">
          <label htmlFor="name">
            Are you going to rent this property you are buying?
          </label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="want_to_give_this_property_on_rent_it"
              id="Yes"
              value="true"
              checked={wantToRent === "true"}
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="want_to_give_this_property_on_rent_it"
              id="No"
              value="false"
              checked={wantToRent === "false"}
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
        </div>

        {wantToRent=== "true" && (
          <div className="form-group col-12 d-flex gap-4 align-items-center">
            <label htmlFor="name">
              If yes, Expected Monthly Rent (net of all expenses) ?
            </label>
            <div className="form-check form-check-inline">
              <input
                type="text"
                className="form-control"
                id="expected_monthly_rent"
                name="expected_monthly_rent"
                value={data?.expected_monthly_rent}
                onChange={handleInputChange}
                placeholder="Enter the Amount"
                required
              />
            </div>
          </div>
        )}
        <h4>ASSETS : </h4>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Mutual Fund Value :</label>
          <input
            type="number"
            className="form-control"
            id="mutual_fund"
            name="mutual_fund"
            value={data?.mutual_fund}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Shares & Stocks Value:</label>
          <input
            type="number"
            className="form-control"
            id="shares_stocks"
            name="shares_stocks"
            value={data?.shares_stocks}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Gold Value :</label>
          <input
            type="number"
            className="form-control"
            id="gold"
            name="gold"
            value={data?.gold}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Existing Immovable Properties Value :</label>
          <input
            type="number"
            className="form-control"
            id="immovable_properties"
            name="immovable_properties"
            value={data?.immovable_properties}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
            PPF/ PF / Insurance Policy / Sukanya Samriddhi Yojana :
          </label>
          <input
            type="text"
            className="form-control"
            id="pf_ppf_insurance_sukanyasamriddhi_retirement_plan"
            name="pf_ppf_insurance_sukanyasamriddhi_retirement_plan"
            value={data?.pf_ppf_insurance_sukanyasamriddhi_retirement_plan}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Any Other Asset</label>
          <input
            type="text"
            className="form-control"
            id="any_other_asset"
            name="any_other_asset"
            value={data?.any_other_asset}
            onChange={handleInputChange}
            placeholder="Enter the Amount"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">Number of Credit Cards</label>
          <input
            type="number"
            className="form-control"
            id="number_of_credit_cards_you_have"
            name="number_of_credit_cards_you_have"
            value={data?.number_of_credit_cards_you_have}
            onChange={handleInputChange}
            placeholder="Number of credit cards you have"
            required
          />
        </div>
        <div className="form-group col-sm-12 col-md-6 col-md-6">
          <label htmlFor="name">
            Number of Loan / Facility from any Bank or Financial Institution
          </label>
          <input
            type="number"
            className="form-control"
            id="number_of_active_loans"
            name="number_of_active_loans"
            value={data?.number_of_active_loans}
            onChange={handleInputChange}
            placeholder="Number of loans"
            required
          />
        </div>
        {/* <div className="mt-4">
        <AddExistingLoan/>
        </div>
        <div className="mt-4">
        <AddCreditCard/>
        </div> */}
      </div>
    </div>
  );
};

export default Asset;

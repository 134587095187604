import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const LayoutName = (props) => {
    const [layoutName, setLayoutName] = useState('');

    useEffect(() => {
      if (props.ID) {        
        const apiUrl = `${BASE_URL}/projects/projects/${props.projectID}/layouts/${props.ID}`;        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const layoutName = data.nickname;
            setLayoutName(layoutName);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [props.ID,props.projectID]);
    return (
      <div>
          {layoutName && <span>{layoutName}</span>}
      </div>
    )
  }

export default LayoutName
import React, { useEffect, useState } from 'react'
import './Feedback.css'
import { useDispatch, useSelector } from 'react-redux';
import { addFeedbackAsync, getBookingsAsync } from '../../../features/bookings/bookingSlice';
import ProjectName from '../../../components/card/ProjectName'

const Feedback = () => {
  const initialFeedbackData = {
    booking_id: "",
    project_rating: "",
    feedback_about_project: "",
    our_rating: "",
    feedback_about_us: "",
  };
  const [productRating, setProductRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [feedbackData, setFeedbackData] = useState(initialFeedbackData);
  const accessToken =  useSelector(state=>state.authorization.accessToken)
  const bookings = useSelector(state=> state.booking.bookings)
  const dispatch = useDispatch();
  const handelInput = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prevState) => ({ ...prevState, [name]: value }));
  };

  
  const handleProductRatingChange = (rating) => {
    setProductRating(rating);
  };

  const handleServiceRatingChange = (rating) => {
    setServiceRating(rating);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFeedBack = {
      booking_id : feedbackData.booking_id,
      project_rating: productRating,
      feedback_about_project: feedbackData.feedback_about_project,
      our_rating: serviceRating,
      feedback_about_us: feedbackData.feedback_about_us,
    }

    dispatch(addFeedbackAsync({token:accessToken, bookingId : feedbackData?.booking_id , data: newFeedBack}))
    
   
   
  };
  const renderStars = (maxRating, currentRating, handleRatingChange) => {
    const stars = [];
    for (let i = 1; i <= maxRating; i++) {
      stars.push(
        <span
          key={i}
          className={`star-rating  star ${i <= currentRating ? "active" : ""}`}
          onClick={() => handleRatingChange(i)}
        >
          &#9733;
        </span>
      );
    }
    return stars;
  };

  useEffect(()=>{
    dispatch(getBookingsAsync(accessToken))
  },[dispatch, accessToken])
  return (

<> {bookings && bookings.length>0 ? <div className="container feedback-form">
      <h2>Please give us your views</h2>
      <form onSubmit={handleSubmit}>
        {/* Product Feedback Section */}
        <div className="form-section">
          <label htmlFor="name" className="form-label me-4 col-lg-2 ">
            Select Booking :
          </label>
          <select
            className="form-select"
            id="name"
            name="booking_id"
            value={feedbackData.booking_id}
            onChange={handelInput}
            required
          >
            <option value="">Select Booking</option>
            {bookings.map((option, index) => (
              <option key={index} value={option.id}>
                {option.id}-
                <ProjectName ID={option.project}/>
              </option>
            ))}
          </select>
        </div>
        
       
        <br />
        <div className="form-section d-flex">
          <label  >Selected Booking :{feedbackData.booking_id && <span style={{textAlign:"center"}}>  {feedbackData.booking_id}</span>}
          </label>
        </div>
        <h3>About {feedbackData.booking_id ? feedbackData.booking_id : "Booking"}  </h3>
        <div className="d-flex form-section">
          <label className="form-label col-lg-2">Ratings :</label>
          <div className="d-flex align-items-center">
            {renderStars(5, productRating, handleProductRatingChange)}
          </div>
        </div>
        <div className="form-section">
            <label className="form-label col-lg-2">Comments : </label>
            <textarea
              className="form-control "
              rows="4"
              name="feedback_about_project"
              value={feedbackData.feedback_about_project}
              onChange={handelInput}
              required
            />
          </div>
        
        {/* Service Feedback Section */}
        <h3>About our service</h3>
        <div className="form-section d-flex">
          <label className="form-label col-lg-2">Ratings :</label>

          <div className="d-flex align-items-center">
            {renderStars(5, serviceRating, handleServiceRatingChange)}
          </div>
        </div>
        <div className="form-section d-flex">
          <label className="form-label col-lg-2">Comments : </label>
          <textarea
            className="form-control"
            rows="4"
            name="feedback_about_us"
            value={feedbackData.feedback_about_us}
            onChange={handelInput}
            required
          />
        </div>
        <div className="submit"><button type="submit" className="buttons">
          Submit
        </button></div>
      </form>
    </div> : <div className='container'> 
      <h2>You do not have bookings for feedback</h2>
      </div>}
    
    </>  )
}

export default Feedback
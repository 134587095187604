import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWishlists, addToWishlist} from "./wishlistApi";
import { BASE_URL } from "../../util/Constant";

export const addToWishlistAsync = createAsyncThunk(
  'wishlist/addtowhishlist',
  async ({itemId, token}) => {
    const response = await addToWishlist({itemId, token});
    return response.data;
  }
);
export const getUserWishlistsAsync = createAsyncThunk(
  'wishlists/getUserWishlists',
  async (token,  {rejectWithValue} ) => {
    try {
      const response = await fetchWishlists(token);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteItemFromWishlistAsync = createAsyncThunk(
  'wishlist/deleteItemFromWishlist',
  async ({ItemId,token}) => {
    try {
       await fetch(`${BASE_URL}/projects/likeprojects/${ItemId}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({ project_id: ItemId }),
      });

      
    } catch (error) {
      console.error("Error removing item from wishlist:", error);
    }
  }
);

const whishlistSlice = createSlice({
  name: "whishlist",
  initialState: {
    wishlist: [],
    loading: false,
    error: null,
  },
  reducers: {
    addToWhishlist: (state, action) => {
      state.wishlist.push(action.payload);
    },
    removeFromWhishlist: (state, action) => {
      const index = state.wishlist.indexOf(action.payload);
      if (index > -1) {
        state.wishlist.splice(index, 1);
      }
    },
    getWhishlist: (state, action) => {
      state.wishlist = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserWishlistsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserWishlistsAsync.fulfilled, (state, action) => {
        state.wishlist = action.payload;
        state.loading = false;
      })
      .addCase(getUserWishlistsAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(addToWishlistAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addToWishlistAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.wishlist?.push(action?.payload);
      })
      .addCase(addToWishlistAsync.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(deleteItemFromWishlistAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteItemFromWishlistAsync.fulfilled, (state, action) => {
        state.loading = false;
        const ItemId = action.meta?.arg?.ItemId;
        const index = state.wishlist?.findIndex(item => item?.id === ItemId);
        state.wishlist?.splice(index, 1);
      })
    },
});

export const { addToWhishlist, removeFromWhishlist
    , getWhishlist } =
    whishlistSlice.actions;
    export default whishlistSlice.reducer;
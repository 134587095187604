import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProjectsByFilters, fetchProjectById, fetchProjects } from './projectApi';

const initialState = {
  projects: [],
  filteredProjects:[],
  isLoading: false,
  selectedProject:null,
};

export const fetchProjectAsync = createAsyncThunk(
    'product/fetchProjects',
    async (id) => {
      const response = await fetchProjects();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    }
  );
  export const fetchProjectByIdAsync = createAsyncThunk(
    'project/fetchProjectById',
    async (id) => {
      const response = await fetchProjectById(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    }
  );
 
  export const fetchProjectsByFiltersAsync = createAsyncThunk(
    'product/fetchProjectsByFilters',
    async (filter) => {
      const response = await fetchProjectsByFilters(filter);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    }
  );
export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearSelectedProject:(state)=>{
        state.selectedProject = null
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(fetchProjectByIdAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectByIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject = action.payload;
      })
      .addCase(fetchProjectsByFiltersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectsByFiltersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filteredProjects = action.payload;
      });


      
  },
});

export const { clearSelectedProject } = projectSlice.actions;



export default projectSlice.reducer;
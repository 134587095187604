import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'
import { BASE_URL } from '../../util/Constant';

const LOGIN_URL = `${BASE_URL}/auth/jwt/create`;
const USER_DETAIL_URL = `${BASE_URL}/auth/users/me`;
const REFRESH_TOKEN_URL = `${BASE_URL}/auth/jwt/refresh`;

export const login = createAsyncThunk('auth/login', async (credentials) => {
  try {
    const response = await axios.post(LOGIN_URL, credentials);
    const data = response.data
    if(response.status===200){
      Cookies.set('accessToken', data.access);
     Cookies.set('refreshToken', data.refresh);
    }
     
    return data;
  } catch (error) {
    throw error.response.data;
  }
});

export const getUserDetails = createAsyncThunk('auth/getUserDetails', async (accessToken) => {
  try {
    const response = await axios.get(USER_DETAIL_URL, {
      headers: {
        Authorization: `JWT ${accessToken}`,
      },
    });
    
    if(response.status===200){
      return response.data;
    }else{
      console.log("response",response)
    }
  } catch (error) {
    throw error.response.data;
  }
});

export const refreshToken = createAsyncThunk('auth/refreshToken', async (refreshToken) => {
  try {
    const response = await axios.post(REFRESH_TOKEN_URL, {refresh: refreshToken });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    // accessToken: localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null,
    // refreshToken: localStorage.getItem("refreshToken") ? localStorage.getItem("refreshToken") : null,
    accessToken: Cookies.get('accessToken') || null,
    refreshToken: Cookies.get('refreshToken') || null,
    isAuthenticated: (localStorage.getItem('isAuthenticated')==="false" ? false : localStorage.getItem('isAuthenticated')==="true" ?  true : false ) || false,
    status: 'idle',
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.setItem("accessToken", null)
      // localStorage.setItem("refreshToken", null)
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      localStorage.setItem("isAuthenticated", false)
      state.isAuthenticated=false;
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
        state.isAuthenticated=true;
        // localStorage.setItem("accessToken", action.payload.access)
        // localStorage.setItem("refreshToken", action.payload.refresh)
        Cookies.set('accessToken', action.payload.access); // Set the expiration time as needed
        Cookies.set('refreshToken', action.payload.refresh);
        localStorage.setItem("isAuthenticated", true)
        state.error= null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = "Given credentials are no correct";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.access;
        localStorage.setItem('accessToken', action.payload.access)
        localStorage.setItem("isAuthenticated", true)
      });
      

  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;


import { BASE_URL } from "../../util/Constant";

export function fetchProjectById(id) {
    return new Promise(async (resolve) => {
      const response = await fetch(`${BASE_URL}/projects/projects/` + id);
      const data = await response.json();
      resolve({ data });
    });
  }
  
  
export function fetchProjects() {
    return new Promise(async (resolve) => {
      const response = await fetch(`${BASE_URL}/projects/projects/`);
      const data = await response.json();
      resolve({ data });
    });
  }
  
  export function fetchProjectsByFilters(filters) {
    let queryString = '';
  
    if (filters.city !== null && filters.city !== "") {
      queryString += `city=${filters.city}`;
    }
  
    if (
      filters.area !== null &&
      filters.area !== "" &&
      filters.area?.length > 0
    ) {
      const areasString = filters.area.join('&area=');
    
      queryString += queryString.length > 0 ? `&area=${areasString}` : `area=${areasString}`;
    }
  
    if (filters.bhk !== null && filters.bhk !== "" && filters.bhk?.length > 0) {
      const bhkString = filters.bhk.join('&bhk=');
      queryString += queryString.length > 0 ? `&bhk=${bhkString}` : `area=${bhkString}`;
    }
    if (filters.possession !== null && filters.possession !== "") {
      const constructionStageValue = filters.possession.replace(/\s+/g, '+');
      queryString += queryString.length > 0 ? `&construction_stage=${constructionStageValue}` : `construction_stage=${constructionStageValue}`;
    }
  
    return new Promise(async (resolve) => {
      const response = await fetch(`${BASE_URL}/projects/projects/` + (queryString.length > 0 ? '?' + queryString : ''));
      const data = await response.json();
      resolve({ data });
    });
  }
  
  
  

  
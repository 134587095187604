import React, { useEffect, useState } from "react";
import "./Detail.css";
import download from "downloadjs";
import Slider from "react-slick";
import { BASE_URL } from "../../../util/Constant";
import { Link, useParams } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";
import {
  addToWishlistAsync,
  getUserWishlistsAsync,
} from "../../../features/wishlist/wishlistSlice";
import Loader from "../../../components/loader/Loader";
import useFetch from "../../../hooks/useFetch";

const Detail = () => {
  const dispatch = useDispatch();
  const property = useSelector((state) => state.project.selectedProject);
  const isLoading = useSelector((state) => state.project.isLoading);
  const isLoggedIn = useSelector(
    (state) => state.authorization.isAuthenticated
  );
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { data: officeData } = useFetch("/projects/offices/");
  const [selectedLocation, setSelectedLocation] = useState(0);
  const { id } = useParams();
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const isItemInWishlist = (id) => {
    // Check if wishlist exists and is an array
    if (wishlist && Array.isArray(wishlist)) {
      // Use the some method to check if the itemId is in the wishlist array
      return wishlist?.some((item) => item.project_id === id);
    } else {
      // Handle the case where wishlist is not an array
      console.error("wishlist is not an array or is not defined.");
      return false; // You can return false or another appropriate value here.
    }
  };
  const handleWishlist = () => {
    dispatch(addToWishlistAsync({ itemId: id, token: accessToken }));
  };
  const handleCallback = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/projects/callback/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
        body: JSON.stringify({ project_id: id }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if (response.ok) {
        alert(
          "Thanks for your callback request. You will get callback from us very soon -Team TDK"
        );
      }

      // setError(null);
    } catch {
      console.log("Error handling callback");
    }
  };

  useEffect(() => {
    dispatch(fetchProjectByIdAsync(id));
  }, [id, dispatch]);
  // const property= 1
  const downloadFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        download(blob);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(Number(event.target.value));
  };
  function shareOnWhatsApp(pageURL) {
    const whatsappURL = `https://web.whatsapp.com/send?text=${pageURL}`;
    window.open(whatsappURL, "_blank");
  }

  const formatPossessionDate = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date(); // Get the current date
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    
    if (date < currentDate) {
      return 'Ready to Move'; 
    }
    
    return `${month} ${year}`;
  };
  
  useEffect(() => {
    dispatch(getUserWishlistsAsync(accessToken));
  }, [dispatch, accessToken]);

  return (
    <div className="detail-page">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {property && (
            <div className="main">
              {/* Project detail slider */}
              <div className="project-detail-slider">
                <div className="container">
                  <div className="our-locations">
                    <p>
                      {property.name} | {property.address}{" "}
                      <a
                        href={property.google_location}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i
                          className="bi bi-geo-alt"
                          style={{ fontSize: "1.5rem", color: "#012060" }}
                        ></i>
                      </a>
                    </p>
                  </div>
                  <div className="row">
                    <div className=" details-images col-lg-6 col-md-6 col-sm-12">
                      <section className="slider-for">
                        {property ? (
                          <Slider {...sliderSettings}>
                            <div className="customer-image">
                              <ClickableImage
                                src={property.image1}
                                alt="Image 1"
                              />
                            </div>
                            <div className="customer-image">
                              <ClickableImage
                                src={
                                  property.image2
                                    ? property.image2
                                    : "/images/pro-2.jpg"
                                }
                                alt="Image 1"
                              />
                            </div>
                            <div className="customer-image">
                              <ClickableImage
                                src={
                                  property.image3
                                    ? property.image3
                                    : "/images/pro-3.jpg"
                                }
                                alt="Image 1"
                              />
                            </div>
                            <div className="customer-image">
                              <ClickableImage
                                src={
                                  property.image4
                                    ? property.image4
                                    : "/images/pro-1.jpg"
                                }
                                alt="Image 1"
                              />
                            </div>
                            <div className="customer-image">
                              <video
                                src={
                                  property.video1
                                    ? property.video1
                                    : "https://www.youtube.com/embed/SVlIpeUaf58"
                                }
                                controls
                                autoPlay
                                muted
                              ></video>
                            </div>
                          </Slider>
                        ) : (
                          <div>Loading...</div>
                        )}
                      </section>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      {/* Project detail right */}
                      <div className="project-detail-right">
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="project-detail-slider-left">
                              <p className="possession">
                                <b>possession</b>{" "}
                                <span>{formatPossessionDate(property.possession)}</span>
                              </p>
                              <div className="our-builder">
                                <img
                                  src={
                                    property.builder_logo
                                      ? property.builder_logo
                                      : "/images/image_not_available.png"
                                  }
                                  alt="Partner 1"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="project-detail-slider-right">
                              <div className="project-detail-slider-right-button">
                                {/* <div className="like-wrapper">
                                  {isLoggedIn ? (
                                    <i
                                      className={`bi ${
                                        isItemInWishlist(property?.id)
                                          ? "bi-heart-fill"
                                          : "bi-heart"
                                      }`}
                                      style={{
                                        fontSize: "30px",
                                        color: isItemInWishlist(property?.id)
                                          ? "#012060"
                                          : "black",
                                      }}
                                      onClick={() => handleWishlist()}
                                    ></i>
                                  ) : (
                                    <Link to="/login" className="like">
                                      <i
                                        className={`bi bi-heart `}
                                        style={{
                                          fontSize: "30px",
                                          color: "#012060",
                                        }}
                                      ></i>
                                    </Link>
                                  )}
                                </div> */}
                                <ul>
                                  <li>{isLoggedIn ? (
                                    <i
                                      className={`bi ${
                                        isItemInWishlist(property?.id)
                                          ? "bi-heart-fill"
                                          : "bi-heart"
                                      }`}
                                      style={{
                                        fontSize: "30px",
                                        color: isItemInWishlist(property?.id)
                                          ? "#012060"
                                          : "black",
                                      }}
                                      onClick={() => handleWishlist()}
                                    ></i>
                                  ) : (
                                    <Link to="/login" className="like">
                                      <i
                                        className={`bi bi-heart `}
                                        style={{
                                          fontSize: "30px",
                                          color: "#012060",
                                        }}
                                      ></i>
                                    </Link>
                                  )}</li>
                                  {isLoggedIn ? (
                                    <li>
                                      <Link to={`/sitevisit/${property?.id}`}>
                                        site visit
                                      </Link>
                                    </li>
                                  ) : (
                                    <li>
                                      <Link to={`/login`}>site visit</Link>
                                    </li>
                                  )}
                                  {isLoggedIn ? (
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/buynow/${property.id}`,
                                          state: { property },
                                        }}
                                      >
                                        Buy now
                                      </Link>
                                    </li>
                                  ) : (
                                    <li>
                                      <Link to={`/login`}>Buy now</Link>
                                    </li>
                                  )}
                                  {isLoggedIn ? (
                                    <li
                                      onClick={() =>
                                        handleCallback(property.id)
                                      }
                                    >
                                      <Link>Call back</Link>
                                    </li>
                                  ) : (
                                    <li>
                                      <Link to={`/login`}>call back</Link>
                                    </li>
                                  )}
                                  
                                  <li className="whatsapp-btn">
                                    <span
                                      onClick={() =>
                                        shareOnWhatsApp(
                                          encodeURIComponent(
                                            window.location.href
                                          )
                                        )
                                      }
                                    >
                                      <img src="/images/whatsapp.png" alt="" />
                                      <p>share with family</p>
                                    </span>
                                  </li>
                                  
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="area-map">
                {isSmallDevice ? (
                  <>
                    
                    <div className="container">
                      <div className="row"><Slider {...{...sliderSettings, slidesToShow: property.layout.length > 1 ? 2 : 1 }}>
                        {property?.layout?.map((layout) => (
                          <div
                            className="col-lg-3 col-md-3 col-sm-12"
                            key={layout.id}
                          >
                            <div className="map-detail">
                              <p>
                                {layout.bhk.length > 0 && layout.bhk[0].bhk} BHK{" "}
                                {layout.RERAcarpetarea} sqft
                              </p>
                              <ClickableImage
                                src={layout.layoutimage}
                                alt="image"
                              />
                            </div>
                          </div>
                        ))}</Slider>
                      </div>
                    </div>
                    
                  </>
                ) : (
                  
                    <div className="container">
                      <div className="row">
                     {property?.layout?.length > 4 ?  <>
                      <Slider {...{...sliderSettings, slidesToShow: 4, infinite:false }}>
                        {property?.layout?.map((layout) => (
                          <div
                            className="p-2"
                            key={layout.id}
                          >
                            <div className="map-detail">
                         
                              <p className="d-flex flex-coloumn">
                              <span>{layout.nickname}</span>
                                {layout.bhk.length > 0 && layout.bhk[0].bhk} BHK{" "}
                                {layout.RERAcarpetarea} sqft
                              </p>
                              <ClickableImage
                                src={layout.layoutimage}
                                alt="image"
                              />
                              
                            </div>
                          </div>
                        ))}</Slider>
                     </> : <>
                     {property?.layout?.map((layout) => (
                          <div
                            className="col-lg-3 col-md-3 "
                            key={layout.id}
                          >
                            <div className="map-detail">
                              <p>
                                {layout.bhk.length > 0 && layout.bhk[0].bhk} BHK{" "}
                                {layout.RERAcarpetarea} sqft
                              </p>
                              <ClickableImage
                                src={layout.layoutimage}
                                alt="image"
                              />
                            </div>
                          </div>
                        ))}
                     </>}
                        
                      </div>
                    </div>
                  
                )}
              </div>
              <div className="micro-detail">
                <div className="container">
                  <div className="micro-detail-box">
                    <p>
                      <span className="text-uppercase fw-bold">
                        MiCRO DETAILS :
                      </span>{" "}
                      <span>{property.micro_details}</span>
                    </p>
                  </div>
                  <div className="micro-detail-box">
                    <p>
                      <span className="text-uppercase fw-bold">
                        {" "}
                        MACRO DETAILS :{" "}
                      </span>{" "}
                      <span>{property.macro_details}</span>
                    </p>
                  </div>
                </div>
              </div>
              {/* Banks offering quick loan */}
              <div className="bank-offering">
                <div className="container">
                  <h4>banks offering quick loan in this project</h4>
                  <div className="quick-loan clearfix">
                    <ul className="clearfix">
                      <li>
                        <img src="/images/bank-1.png" alt="" />
                      </li>
                      <li>
                        <img src="/images/bank-2.png" alt="" />
                      </li>
                      <li>
                        <img src="/images/bank-3.png" alt="" />
                      </li>
                      <li>
                        <img src="/images/bank-4.png" alt="" />
                      </li>
                      <li>
                        <img src="/images/bank-5.png" alt="" />
                      </li>
                      <li>
                        <img src="/images/bank-6.png" alt="" />
                      </li>
                    </ul>
                  </div>
                  <div className="pre-sanction">
                    <Link to="">Already have pre sanction ?</Link>

                    <Link to="">Check Eligibility & Downpayment, EMI</Link>
                  </div>
                </div>
              </div>
              {/* legal documents */}

              <div className="container">
                <div className="rera-section">
                  <div className="rera-page">
                    <ul>
                      <li>
                        <p >
                          <span className="fw-bold text-uppercase ">Rera Number : </span>
                          <span style={{ wordWrap: "break-word" }}>
                            {property.rera_number}
                          </span>
                        </p>
                      </li>
                      <li>
                        <Link
                          to={
                            property && property.rera_webpage
                              ? property.rera_webpage
                              : "#"
                          }
                        >
                          RERA page
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="legal-documents">
                    <p className="mb-2">
                      Check Legal Documents before you buy !
                    </p>
                    <div className="d-flex align-items-center gap-5">
                      <select
                        value={selectedDocument}
                        onChange={(e) => setSelectedDocument(e.target.value)}
                      >
                        <option value={null}>Select a document</option>
                        <option value="builder_kyc_permission">
                          Builder KYC Permission
                        </option>
                        <option value="document_7_12">Document 7/12</option>
                        <option value="document_revenue_records">
                          Document Revenue Records
                        </option>
                        <option value="land_ownership">Land Ownership</option>
                        <option value="non_agriculture_permission">
                          Non Agriculture Permission
                        </option>
                        <option value="land_title_report">
                          Land Title Report
                        </option>
                        <option value="nhai_permission">NHAI Permission</option>
                        <option value="town_planning_scheme_layout">
                          Town Planning Scheme Layout
                        </option>
                        <option value="final_plot_plan">Final Plot Plan</option>
                        <option value="bu_or_oc_permission">
                          BU or OC Permission
                        </option>
                        <option value="construction_permission">
                          Construction Permission
                        </option>
                        <option value="rera_certificate">
                          RERA Certificate
                        </option>
                      </select>{" "}
                      {selectedDocument && (
                        <span>
                          {property[selectedDocument] ? (
                            <button
                              onClick={() =>
                                downloadFile(property[selectedDocument])
                              }
                            >
                              <i className="bi bi-download"></i>
                            </button>
                          ) : (
                            <span> Not Available</span>
                          )}
                        </span>
                      )}
                    </div>
                  </div>

                  {officeData && officeData.length > 0 ? (
                    <div className="home-buying ">
                      <p>
                        We understand Home Buying is a big decision and you
                        might want to meet us in person.
                      </p>
                      <p>Here we are, lets have a tea or coffee</p>
                      <ul className="area-selection">
                        <li>
                          <select
                            value={selectedLocation}
                            onChange={handleLocationChange}
                          >
                            <option value={0}>AHMEDABAD WEST</option>
                            <option value={1}>AHMEDABAD EAST</option>
                            <option value={2}>AHMEDABAD NORTH</option>
                          </select>
                        </li>
                        <li>
                          <div className="location-whatsapp">
                            <a
                              href={
                                officeData[selectedLocation]?.google_location
                              }
                              target="_blank"
                              rel="noreferrer "
                            >
                              <i
                                className="bi bi-geo-alt"
                                style={{ fontSize: "1.5rem", color: "white" }}
                              ></i>
                            </a>

                            <button
                              onClick={() =>
                                shareOnWhatsApp(
                                  encodeURIComponent(
                                    officeData[selectedLocation]
                                      ?.google_location
                                  )
                                )
                              }
                              style={{
                                backgroundColor: "#012060",
                                color: "white",
                                padding: "5px",
                                borderRadius: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              Get location on Whatsapp
                            </button>
                          </div>
                        </li>
                      </ul>
                      <ul className="area-show 1 box">
                        <li>
                          <p>{officeData[selectedLocation]?.nickname}</p>
                          <p>{officeData[selectedLocation]?.line1}</p>
                          <p>{officeData[selectedLocation]?.line2}</p>
                          <p>
                            {officeData[selectedLocation]?.area}{" "}
                            {officeData[selectedLocation]?.city}{" "}
                            {officeData[selectedLocation]?.pincode}
                          </p>
                          <p >
                            PHONE : 079 30267000, 26751299
                          </p>
                          <p> TIME : 10 AM TO 8 PM</p>
                        </li>
                        <li>
                          <img
                            src={
                              officeData[selectedLocation]?.building_photo
                                ? officeData[selectedLocation]?.building_photo
                                : "/images/home-buying.png"
                            }
                            alt=""
                          />
                        </li>
                      </ul>
                      <p>
                        You are welcome even without prior schedule. However,
                        prior intimation will help us serve better.{" "}
                        <Link to="">Schedule here</Link>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="container">
                <div className="about-builder-section">
                  <div className="about-builder-detail">
                    <h6>About Builder:</h6>
                    <div>
                      {property && property.builder_description ? (
                        property.builder_description
                      ) : (
                        <p> Builder Description </p>
                      )}
                    </div>
                  </div>
                  <div className="about-locality-detail">
                    <h6>About Locality:</h6>
                    <div>
                      {property && property.locality_description ? (
                        property.locality_description
                      ) : (
                        <p> Locality Description </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-speak project-detail">
                <div className="container">
                  <div className="customer-testimonial">
                    <h5 className="text-center">Customer Speaks !</h5>
                    <section className="testimonial">
                      <Slider {...sliderSettings}>
                        <div className="testimonial-slider">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="customer-image">
                                <img src="/images/coustomer-1.png" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="customer-review">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="customer-image">
                                <img src="/images/coustomer-1.png" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="customer-review">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="customer-image">
                                <img src="/images/coustomer-1.png" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="customer-review">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="customer-image">
                                <img src="/images/coustomer-1.png" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="customer-review">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Detail;

import React, { useEffect, useState } from "react";
import {  FaPlusCircle, FaWindowClose } from "react-icons/fa";
import "./addLoan.css";
import useFetch from "../../../../hooks/useFetch";
import { BASE_URL } from "../../../../util/Constant";
import { useSelector } from "react-redux";

const AddExistingLoan = ({id}) => {
  
  const {data:banks} = useFetch("/finance/bankfi/")
  const [addLoan, setAddLoan] = useState(false);
  const [loans, setloans] = useState([]);
 const accessToken = useSelector((state) => state.authorization.accessToken);
  const [formData, setFormData] = useState({
    bank: null,
    type_of_loan_or_facility: null,
    loan_amount: null,
    santioned_date: null,
    present_outstanding: null,
    emi: null,
    product_name:"",
    last_emi_date: null,
    comment: ""
})

console.log(banks)
  const handleInputChange = (e) =>{
    setFormData({...formData,[e.target.name]: e.target.value})
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`${BASE_URL}/finance/applicants/${id}/existingloans/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization :  `JWT ${accessToken}`
          
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Loan added successfully");
        setAddLoan(false)
        window.location.reload()
      }else{
        alert("Error adding Loan")
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  

  useEffect(()=>{
    const getLoans = async ()=>{
      try {
        const response = await fetch(`${BASE_URL}/finance/applicants/${id}/existingloans/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization :  `JWT ${accessToken}`
            
          },
        });
  
        const result = await response.json()
        setloans(result)
        if (!response.ok) {
          console.log("error Fetching loans");
        }
      } catch (error) {
        console.log("error ", error);
      }
    }
  getLoans()
  },[id,accessToken])

  console.log("loans",loans);
  return (
    <div>
      <div className="heading">
        <h4>Existing Loans: </h4>
        {addLoan ?<FaWindowClose onClick={() => setAddLoan(false)} className="add-btn"/> :  <FaPlusCircle
          className="add-btn"
          onClick={() => setAddLoan(true)}
        /> }
      </div>
      {addLoan ? (
        <form className="row " onSubmit={handleSubmit}>
          <div className="form-group col-sm-12 col-sm-6 col-lg-4">
            <label htmlFor="name">Bank Name </label>
            <select
              className="form-control"
              id="bank"
              name="bank"
              value={formData?.bank}
              onChange={handleInputChange}
              required
            >
              <option value="">Select </option>
              {banks && banks.map((bank,i)=><option key={i} value={bank.id}>{bank.name}</option>)}
              
            </select>
          </div>
          <div className="form-group col-sm-12 col-sm-6 col-lg-4">
            <label htmlFor="name">Type of loan or facility</label>
            <select
              className="form-control"
              id="type_of_loan_or_facility"
              name="type_of_loan_or_facility"
              value={formData?.type_of_loan_or_facility}
              onChange={handleInputChange}
              required
            >
              <option value="">Select </option>
              <option value="Home Loan">Home Loan</option>
              <option value="Personal Loan">Personal Loan</option>
              <option value="Loan Against Property">Loan Against Property</option>
              <option value="Business Loan">Business Loan</option>
              <option value="Car Loan">Car Loan</option>
              <option value="Commercial Vehicle Loan">Commercial Vehicle Loan</option>
              <option value="Business Loan">Business Loan</option>
              <option value="CC / OD Facility">CC / OD Facility</option>
              <option value="Working Capital Loan">Working Capital Loan</option>
              <option value="Any other">Any other</option>
            </select>
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Loan amount</label>
            <input
              type="number"
              className="form-control"
              id="loan_amount"
              name="loan_amount"
                value={formData?.loan_amount}
                onChange={handleInputChange}
                placeholder="Enter loan amount"
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Santioned date</label>
            <input
              type="date"
              className="form-control"
              id="santioned_date"
              name="santioned_date"
                value={formData?.santioned_date}
                onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Present outstanding</label>
            <input
              type="number"
              className="form-control"
              id="present_outstanding"
              name="present_outstanding"
                value={formData?.present_outstanding}
                onChange={handleInputChange}
                placeholder="Enter Present outstanding"
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Emi</label>
            <input
              type="number"
              className="form-control"
              id="emi"
              name="emi"
                value={formData?.emi}
                onChange={handleInputChange}
                placeholder="Enter EMI amount"
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Product name</label>
            <input
              type="text"
              className="form-control"
              id="product_name"
              name="product_name"
                value={formData?.product_name}
                onChange={handleInputChange}
                placeholder="Enter Product name"
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-4">
            <label htmlFor="name">Last emi date</label>
            <input
              type="date"
              className="form-control"
              id="last_emi_date"
              name="last_emi_date"
                value={formData?.last_emi_date}
                onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group col-sm-12 col-md-12 col-lg-4">
            <label htmlFor="name">Comment</label>
            <input
              type="text"
              className="form-control"
              id="comment"
              name="comment"
                value={formData?.comment}
                onChange={handleInputChange}
              
            />
          </div>
          <div className="text-center">
          <button type="submit" className={`m-2 btn btn-primary `}>
            Add Loan
          </button>
        </div>
        </form>
      ) : (
        <div>
          <table>
            <thead>
              <tr className="text-center">
                <th>No.</th>
                <th>Bank/FI</th>
                <th>Type</th>
                <th>Amount</th>
                <th>EMI </th>
                <th>Sanction Date</th>
                <th>Outstanding</th>
                <th>Last EMI Date</th>
              </tr>
            </thead>
            <tbody className="text-center">
             
              {loans &&
              loans?.map((loan, index) => (
                <tr key={loan.id}>
                  <td>{index + 1}</td>
                  <td>{loan.bank}</td>
                  <td>{loan.type_of_loan_or_facility}</td>
                  <td>{loan.loan_amount}</td>
                  <td>{loan.emi}</td>
                  <td>{loan.santioned_date}</td>
                  <td>{loan.present_outstanding}</td>
                  <td>{loan.last_emi_date}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AddExistingLoan;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedCustomer = () => {
  const user_type = useSelector(
    (state) => state.authorization?.user?.user_type
  );
  const isAuthenticated = useSelector(
    (state) => state.authorization?.isAuthenticated
  );
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (user_type === "Customer") {
    return <Outlet />;
  }
};
export default ProtectedCustomer;


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import {
  getUserSettingsAsync, updateSettingsAsync,
} from "../../../features/settings/settingSlice";
import "./Settings.css";
import Loader from "../../../components/loader/Loader";

const Settings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings?.settings);
  const loading = useSelector((state) => state.settings.loading);
  const accessToken = useSelector((state) => state.authorization.accessToken);

  useEffect(() => {
    dispatch(getUserSettingsAsync(accessToken));
  }, [dispatch, accessToken]);

  const handleChange = async (type) => {
    // Update the specific setting based on the switch type
    const updatedSettings = {
      ...settings[0],
      [type]: !settings[0][type],
      
    };
    await dispatch(updateSettingsAsync({newData:updatedSettings,id:settings[0]?.id, token:accessToken }));
  };

  return (
    <div className="container">
      <h3>Settings</h3>
      <h5 className="ml-5 mt-2">Notifications</h5>
      {loading ? <Loader/> : <div className="profile-settings ml-5">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Email</th>
              <th>SMS/Whatsapp</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>New Project Alerts</td>
              <td>
                <Switch
                  onChange={() => handleChange("new_projects_alert_email")}
                  checked={settings ? settings[0]?.new_projects_alert_email : false}
                />
              </td>
              <td>
                <Switch
                  onChange={() => handleChange("new_projects_alert_sms_whatsapp")}
                  checked={
                    settings ? settings[0]?.new_projects_alert_sms_whatsapp : false
                  }
                />
              </td>
            </tr>
            <tr>
              <td>SiteVisits</td>
              <td>
                <Switch
                  onChange={() => handleChange("sitevisit_alert_email")}
                  checked={settings ? settings[0]?.sitevisit_alert_email : false}
                />
              </td>
              <td>
                <Switch
                  onChange={() => handleChange("sitevisit_alert_sms_whatsapp")}
                  checked={
                    settings ? settings[0]?.sitevisit_alert_sms_whatsapp : false
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Bookings Related</td>
              <td>
                <Switch
                  onChange={() => handleChange("booking_alert_email")}
                  checked={settings ? settings[0]?.booking_alert_email : false}
                />
              </td>
              <td>
                <Switch
                  onChange={() => handleChange("booking_alert_sms_whatsapp")}
                  checked={
                    settings ? settings[0]?.booking_alert_sms_whatsapp : false
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Payments Related</td>
              <td>
                <Switch
                  onChange={() => handleChange("payments_related_alert_email")}
                  checked={
                    settings ? settings[0]?.payments_related_alert_email : false
                  }
                />
              </td>
              <td>
                <Switch
                  onChange={() => handleChange("payments_related_alert_sms_whatsapp")}
                  checked={
                    settings ? settings[0]?.payments_related_alert_sms_whatsapp : false
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>}
    </div>
  );
};

export default Settings;

import React, {  useEffect, useState } from "react";
import SearchBox from "../../../components/searchBox/SearchBox";
import { BASE_URL } from "../../../util/Constant";
import { useSelector } from "react-redux";
import CityName from "../../../components/card/CityName";

const EditableRequirements = () => {
  const [otherData, setOtherData] = useState([])
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const [editingIndex, setEditingIndex] = useState(null);
  const lastElementIndex = otherData.length - 1;
  const [areaNames, setAreaNames] = useState({});
  const [showFilterField, setShowFilterField] = useState(false)
  
  useEffect(() => {
    const fetchOtherData = () => {

      fetch(`${BASE_URL}/projects/requirements/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setOtherData(data);
  
        })
        .catch((error) => {
          console.error("Error fetching other data:", error);
        });
    };
    fetchOtherData();
  }, [accessToken,showFilterField]);

  function formatValue(value) {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L";
    } else {
      return value;
    }
  }  
  useEffect(() => {
    const fetchAreaNames = async () => {
      try {
        const areaNamesData = {};

        for (let index = 1; index <= 10; index++) {
          const areaNumber = otherData[lastElementIndex]?.["area" + index];
          if (areaNumber) {
            const response = await fetch(
              `${BASE_URL}/projects/area/${areaNumber}`
            );
            const data = await response.json();
            areaNamesData[areaNumber] = data.area;
          }
        }

        setAreaNames(areaNamesData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAreaNames();
  }, [otherData, lastElementIndex]);

  const handleEditClick = () => {
    setEditingIndex(lastElementIndex);
    setShowFilterField(true)
  };
  

  return (
    <div>
      {editingIndex === lastElementIndex  && showFilterField? (
        <SearchBox showFilterField = {showFilterField} setShowFilterField={setShowFilterField} />
      ) : (
        <div>
          <h5>Your Requirements</h5>
          <p>CITY: <CityName Id={otherData[lastElementIndex]?.city}/> </p>
          <p>
            Area :{" "}
            {Array.from({ length: 10 }, (_, index) => {
              const areaNumber =
                otherData[lastElementIndex]?.["area" + (index + 1)];
              const areaName = areaNumber ? areaNames[areaNumber] || "" : "";
              return areaName;
            })
              .filter((area) => area !== "")
              .join(",")}
          </p>

          <p>
            BHK:{" "}
            {otherData[lastElementIndex]?.bhk
              ? otherData[lastElementIndex]?.bhk.join(", ")
              : ""}
          </p>
          <p>
            BUDGET:{" "}
            {otherData[lastElementIndex]?.min_budget
              ? formatValue(otherData[lastElementIndex]?.min_budget)
              : ""}-{otherData[lastElementIndex]?.max_budget
                ? formatValue(otherData[lastElementIndex]?.max_budget)
                : ""}
          </p>
          
          <p>
            Possession :
            {otherData[lastElementIndex]?.possession === "Ready"
              ? "Ready to move"
              : otherData[lastElementIndex]?.possession === "UnderCons"
              ? "Under construction"
              : "Does not matter"}
          </p>
          <span className="add-documents" onClick={handleEditClick}>
            EDIT REQUIREMENT
          </span>
        </div>
      )}
    </div>
  );
};

export default EditableRequirements;

import React from 'react'
import './BuilderConfirm.css'
import { useNavigate } from 'react-router-dom'

const BuilderConfirm = () => {
    const navigate = useNavigate()

    const login = () => navigate("/login")

  return (
    <div className="container d-flex justify-content-center">
        <div className='builder-confirmation'>
        <h1>Thank you for registration !</h1>
        
        <p>We will contact you soon to confirm the your builder account.</p>
        <button  onClick={login}>LOGIN</button>

    </div>
    </div>
  )
}

export default BuilderConfirm
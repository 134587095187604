import React, { useState } from "react";

const AddImages = ({handleInputChange}) => {
  const [imagePreviews, setImagePreviews] = useState(Array(20).fill(null)); // Array for image previews
  
  // Function to handle file input change and update image previews
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const newImagePreviews = [...imagePreviews];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        newImagePreviews[index] = e.target.result;
        setImagePreviews(newImagePreviews);
      };
      reader.readAsDataURL(file);
    } else {
      newImagePreviews[index] = null;
      setImagePreviews(newImagePreviews);
    }

    handleInputChange(event); // Call the original input change handler
  };

  // Generate input fields for images and videos
  const generateInputFields = (type) => {
    const inputFields = [];
    const fieldCount = type === "image" ? 20 : 3; // 20 for images, 3 for videos

    for (let i = 1; i <= fieldCount; i++) {
      const inputName = `${type}${i}`;
      inputFields.push(
        <div className="form-group" key={inputName}>
          <label htmlFor={inputName}>{`${type.charAt(0).toUpperCase()}${type.slice(1)} ${i} `}</label>
          <input
            type="file"
            className="form-control"
            id={inputName}
            name={inputName}
            onChange={(event) => handleFileChange(event, i - 1)}
          />
          {imagePreviews[i - 1] && (
            <img
              src={imagePreviews[i - 1]}
              alt={`Preview ${type.charAt(0).toUpperCase()}${type.slice(1)} ${i}`}
              className="image-preview"
              style={{
                width:"100%",
                maxHeight:"150px",
                objectFit:"cover",
                borderRadius:"5px"
              }}
            />
          )}
          {imagePreviews[i - 1] && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => removeImagePreview(i - 1)}
              style={{
                width:"100%",
                backgroundColor:"#112550"
              }}
            >
              Remove
            </button>
          )}
        </div>
      );
    }

    return inputFields;
  };

  
 // Function to remove image preview and reset the input file
const removeImagePreview = (index) => {
  const newImagePreviews = [...imagePreviews];
  newImagePreviews[index] = null;
  setImagePreviews(newImagePreviews);

  // Reset the input file
  const inputName = `image${index + 1}`;
  const inputFile = document.getElementById(inputName);
  if (inputFile) {
    inputFile.value = null;
  }
};

  return (
    <div className="add-images">
      <div className="form-group">
            <label htmlFor="ebrochure">E-Brochure</label>
            <input
              type="file"
              className="form-control"
              id="ebrochure"
              name="ebrochure"
              onChange={handleInputChange}
            />
          </div>
      <div className="images">
        <h2>Images</h2>
        <div className="input-fields">{generateInputFields("image")}</div>
      </div>

      <div className="videos">
        <h2>Videos</h2>
        <div className="input-fields">
        <div className="form-group">
          <label htmlFor="video1">Video 1 </label>
          <input
            type="file"
            className="form-control"
            id="video1"
            name="video1"
                        onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="video2">Video 2 </label>
          <input
            type="file"
            className="form-control"
            id="video2"
            name="video2"
                        onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="video3">Video 3 </label>
          <input
            type="file"
            className="form-control"
            id="video3"
            name="video3"
                        onChange={handleInputChange}
          />
        </div></div>
      </div>
    </div>
  );
};

export default AddImages;

import React from 'react';
import AreaName from '../../../components/card/AreaName';
import { useSelector} from 'react-redux'
import CityName from '../../../components/card/CityName';

const ProjectStatus = () => {
 const projects = useSelector(state=>state.project.projects)


  
  return (
    <div>
      <div className="container assign-sitevisits">
        <h3 className="mt-2">Project Status</h3>
        <div>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Project Name</th>
                <th>Area</th>
                <th>City</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {projects?.map((project,index) => (
                <tr key={project.id}>
                  <td>{index +1}</td>
                  <td>{project.name}</td>
                  <td><AreaName Id={project?.area}/></td>
                  <td><CityName Id={project?.city}/></td>
                  <td>{project?.status==="1" ? "Available" : project?.status==="2" ? "Sold Out" : "No Status Available"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatus;

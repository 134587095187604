import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import useFetch from "../../../hooks/useFetch";
import "./Account.css";
import {
  deleteItemFromWishlistAsync,
  getUserWishlistsAsync,
} from "../../../features/wishlist/wishlistSlice";
import ClickableImage from "../../../components/modal/ClickableImage";
import ProjectName from "../../../components/card/ProjectName";
import { getSiteVisitsAsync } from "../../../features/sitevisit/siteVisitSlice";
import EditableRequirements from "./EditableRequirements";
import Loader from "../../../components/loader/Loader";

export const Account = () => {
  const dispatch = useDispatch();
  const { data: banner } = useFetch(`/projects/bannerAA/`);
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const projects = useSelector((state) => state.project.projects);
  const [selectedSection, setSelectedSection] = useState("SiteVisits");
  const userData = useSelector((state) => state.authorization.user);
  const customerData = useSelector((state) => state.user.userDetail);
  const sortedSiteVisits = useSelector((state) => state.sitevisit.visits);
  const isLoading = useSelector((state) => state.sitevisit.isLoading);

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(timeString).toLocaleTimeString(undefined, options);
  };

  const handleRemoveFromWishlist = (id) => {
    dispatch(deleteItemFromWishlistAsync({ ItemId: id, token: accessToken }));
  };
  const findDataById = (id) => {
    const propertyItem = projects?.find((proj) => proj.id === id);

    return propertyItem ? propertyItem : null;
  };
  // const sortedSiteVisits=[];

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };
  const formatPossessionDate = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date(); // Get the current date
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    if (date < currentDate) {
      return "Ready to Move";
    }

    return `${month} ${year}`;
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  useEffect(() => {
    dispatch(getUserWishlistsAsync(accessToken));
    dispatch(getSiteVisitsAsync(accessToken));
  }, [dispatch, accessToken]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="profile-section">
            <div className="container">
              <div className="row">
                <div className="child1 col-lg-6 col-md-6 col-sm-12">
                  <div className="banner" >
                    {banner?.length > 0 ? (
                      <img src={banner[0]?.video_image} alt="banner" />
                    ) : (
                      <h1>BANNER</h1>
                    )}
                  </div>
                </div>
                <div className=" child2 col-lg-6 col-md-6 col-sm-12">
                  <div className="profile-datail">
                    <ul>
                      <li>
                        <img
                          src={
                            customerData?.profile_photo
                              ? customerData?.profile_photo
                              :
                              "/images/user-icon.png"
                          }
                          alt=""
                        />
                        {/* <img src="images/user.jpg" alt="" />  */}
                      </li>
                      <li>
                        <p>
                          {" "}
                          Name:{" "}
                          <span>
                            {userData?.first_name} {userData?.last_name}
                          </span>
                        </p>
                        <p>
                          Email: <span>{userData?.email}</span>
                        </p>
                        <p>
                          {" "}
                          Mobile No.:{" "}
                          <span>
                            {userData
                              ? userData.mobile_number
                                ? userData.mobile_number
                                : ""
                              : "Not available"}
                          </span>
                        </p>
                        <p>
                          <strong>Account:</strong> <span>Primary</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          Adhar : <span>Registered</span>
                        </p>
                        <p>
                          PAN : <span>Registered</span>
                        </p>
                        <Link to="" className="add-documents">
                          add another requirement
                        </Link>
                      </li>
                      <li>
                        <ul>
                          <li>
                            <Link to="">
                              <img src="images/message1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              {" "}
                              <img src="images/notification1.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buyer-pre-booking-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="prebooking" id="requirement">
                    <div className="requirement">
                      <EditableRequirements />
                    </div>

                    <div className="home-loan-detail">
                      <h2>BE SURE,</h2>
                      <h2>BE SMART.</h2>
                      <h6 className="">
                        Get Pre Sanction of Home loan & SAVE RS. 50,000/- !
                      </h6>
                      <div className="apply-btn">
                        <Link to="/applyforloan">APPLY FOR HOME LOANS</Link>
                      </div>
                    </div>
                    {/* <img src="images/pre2.jpg" /> */}
                    <div className="wishlist-images">
                      <h6>WISHLIST</h6>
                      <Slider className="wishlists-items" {...sliderSettings}>
                        {wishlist.length > 0
                          ? wishlist.map((Item) => {
                              const propertyItem = findDataById(
                                Item?.project_id
                              );
                              return (
                                <div key={Item?.project_id}>
                                  {propertyItem && (
                                    <div key={propertyItem?.id}>
                                      <div>
                                        <h6>
                                          <Link
                                            to={`/detail/${propertyItem?.id}`}
                                            style={{
                                              textDecoration: "none",
                                              color: "#12306c",
                                            }}
                                          >
                                            {propertyItem.name}
                                          </Link>
                                        </h6>
                                        <ClickableImage
                                          key={propertyItem?.id}
                                          src={
                                            propertyItem.image1
                                              ? propertyItem.image1
                                              : "/images/image_not_available.jpg"
                                          }
                                          alt={propertyItem?.name}
                                        />
                                        <p>
                                        </p>{" "}
                                        <p>
                                          POSSESSION{" "}
                                          {formatPossessionDate(
                                            propertyItem?.possession
                                          )}
                                        </p>{" "}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="pre-booking-right">
                    <ul>
                      <li
                        onClick={() => handleSectionClick("SiteVisits")}
                        className={
                          selectedSection === "SiteVisits"
                            ? "selected-items"
                            : ""
                        }
                      >
                        Site Visits
                      </li>
                      <li
                        onClick={() => handleSectionClick("wishlist")}
                        className={
                          selectedSection === "wishlist" ? "selected-items" : ""
                        }
                      >
                        Wishlist Properties
                      </li>
                    </ul>
                    {selectedSection && (
                      <div>
                        {selectedSection === "wishlist" && (
                          <div className="mt-2">
                            <div>
                              {wishlist.length > 0 ? (
                                <table>
                                  <thead>
                                    <tr>
                                      <th>No</th>

                                      <th>Property Name</th>
                                      <th>Remove</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {wishlist &&
                                      wishlist?.map((item, index) => {
                                        return (
                                          <tr key={item?.project_id}>
                                            <td>{index + 1}</td>

                                            <td>
                                              <Link
                                                to={`/detail/${item?.project_id}`}
                                                style={{
                                                  textDecoration: "none",
                                                  color: "black",
                                                }}
                                              >
                                                <ProjectName
                                                  ID={item?.project_id}
                                                />
                                              </Link>
                                            </td>
                                            <td>
                                              <button
                                                onClick={() =>
                                                  handleRemoveFromWishlist(
                                                    item?.id
                                                  )
                                                }
                                              >
                                                Remove
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              ) : (
                                <p>Your wishlist is empty.</p>
                              )}
                            </div>
                          </div>
                        )}
                        {selectedSection === "SiteVisits" && (
                          <div className="mt-2">
                            <div>
                              {sortedSiteVisits?.length > 0 ? (
                                <table>
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Project Name</th>
                                      <th>Date and Time</th>
                                      <th>Status</th>
                                      <th>Reschedule</th>
                                      <th>Cancel</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sortedSiteVisits?.map((visit, index) => {
                                      return (
                                        <tr key={visit.id}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <Link
                                              to={`/detail/${visit?.project_id}`}
                                              style={{
                                                textDecoration: "none",
                                                color: "black",
                                              }}
                                            >
                                              <ProjectName
                                                ID={visit?.project_id}
                                              />
                                            </Link>
                                          </td>
                                          <td>
                                            {new Date(
                                              visit.when
                                            ).toLocaleDateString("en-GB")}{" "}
                                            | {formatTime(visit.when)}
                                          </td>
                                          <td>{visit.status}</td>
                                          <td>
                                            <Link
                                              to={`/reschedule/${visit.project_id}/${visit.id}`}
                                            >
                                              <button>Reschedule</button>
                                            </Link>
                                          </td>
                                          <td>
                                            {visit.status === "Cancel" ||
                                            visit.status === "Complete" ? (
                                              <button disabled>Cancel</button>
                                            ) : (
                                              <Link
                                                to={`/cancellation/${visit.project_id}/${visit.id}`}
                                              >
                                                <button>Cancel</button>
                                              </Link>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                <p>No site visits scheduled.</p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect } from 'react'
import useFetch from '../../../hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilderDetailsAsync } from '../../../features/user/userSlice';

const AddOtherDetails = ({handleInputChange}) => {
  const data = {};
  
  const dispatch = useDispatch()
  const builderData = useSelector(state=>state.user.userDetail)
  const userData = useSelector(state=>state.authorization.user)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  // const {data: consnStages=[] } = useFetch("/projects/construction_stage/");
  const {data: unitstatus=[] } = useFetch("/projects/project_availability/");
  const {data: unitTypes=[] } = useFetch("/projects/unit_types/");

 useEffect(()=>{
  dispatch(getBuilderDetailsAsync(accessToken))
 },[dispatch,accessToken])

  const isMultipleSelect = userData.usertype === 'Admin';
  return (
    <div>
      <div className="project-detail">

      
      <div className="form-group">
            <label htmlFor="builder">Builder Name*</label>
            <select
            className="form-control"
            id="builder"
            name="builder"
            value={data?.builder}
            onChange={handleInputChange}
            required
            multiple={isMultipleSelect} // Enable multiple select for Admin only
          >
            {userData.user_type === 'Builder' && (<><option value="" >select</option>
              <option value={builderData?.id} >{builderData?.id}</option></>
            )}
          
          </select>
          </div>
          <div className="form-group">
            <label htmlFor="total_units_in_project">
              Total Units in Project*
            </label>
            <input
              type="number"
              className="form-control"
              id="total_units_in_project"
              name="total_units_in_project"
              value={data?.total_units_in_project}
              onChange={handleInputChange}
              placeholder="Enter Total Units in Project"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="project_startdate">Project Start Date*</label>
            <input
              type="date"
              className="form-control"
              id="project_startdate"
              name="project_startdate"
              value={data?.project_startdate}
              onChange={handleInputChange}
              placeholder="Enter Project Start Date"
              required
            />
          </div>

          

          <div className="form-group">
            <label htmlFor="project_layout">Project Layout</label>
            <input
              type="file"
              className="form-control"
              id="project_layout"
              name="project_layout"
              onChange={handleInputChange}
            />
          </div>

          

          <div className="form-group">
            <label htmlFor="status">Status*</label>
            <select
             className="form-control"
             id="status"
             name="status"
             value={data?.status}
             onChange={handleInputChange}
          >
            <option value="" >select</option>
            {
              unitstatus?.map(status => (<option value={status.id}>{status.availability_status}</option>))
            }
          </select>
          </div>

          <div className="form-group">
            <label htmlFor="construction_stage">Construction Stage*</label>
            <select
             className="form-control"
             id="construction_stage"
             name="construction_stage"
             value={data?.construction_stage}
             onChange={handleInputChange}
             required
          >
            <option value="" >select</option>
            <option value="Under Construction" >Under Construction</option>
            <option value="Ready to Move" >Ready to Move</option>
            
          </select>
          </div>

          

          <div className="form-group">
            <label htmlFor="unit_types">Unit Types*</label>
            <select
             className="form-control"
             id="unit_types"
             name="unit_types"
             multiple
             value={data?.unit_types}
             onChange={handleInputChange}
          >
            {
              unitTypes?.map(type => (<option value={type.id}>{type.unittypes}</option>))
            }
            
          </select>
          </div>
          <div className="form-group">
            <label htmlFor="rera_webpage">RERA Webpage</label>
            <input
              type="text"
              className="form-control"
              id="rera_webpage"
              name="rera_webpage"
              value={data?.rera_webpage}
              onChange={handleInputChange}
              placeholder="Enter RERA Webpage URL"
            />
          </div>

          <div className="form-group">
            <label htmlFor="short_description">Short Description</label>
            <textarea
              className="form-control"
              id="short_description"
              name="short_description"
              value={data?.short_description}
              onChange={handleInputChange}
              placeholder="Enter Short Description"
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="size_description">Size Description</label>
            <textarea
              className="form-control"
              id="size_description"
              name="size_description"
              value={data?.size_description}
              onChange={handleInputChange}
              placeholder="Enter Size Description"
              rows={3}
            />
          </div>

          
          <div className="form-group">
            <label htmlFor="builder_description">Builder Description</label>
            <textarea
              className="form-control"
              id="builder_description"
              name="builder_description"
              value={data?.builder_description}
              onChange={handleInputChange}
              placeholder="Enter Builder Description"
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="locality_description">Locality Description</label>
            <textarea
              className="form-control"
              id="locality_description"
              name="locality_description"
              value={data?.locality_description}
              onChange={handleInputChange}
              placeholder="Enter Locality Description"
              rows={3}
            />
          </div></div>
    </div>
  )
}

export default AddOtherDetails
import React, {  useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClickableImage from "../../../components/modal/ClickableImage";
import { BASE_URL } from "../../../util/Constant";
import './Sitevisit.css'
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";

const Reschedule = () => {
  const { project_id, id } = useParams();
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const property = useSelector(state=>state.project.selectedProject)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [error, setError] = useState("");

  useEffect(()=>{
    dispatch(fetchProjectByIdAsync(project_id))
  },[dispatch,project_id])

  if (!property) {
    return <div>Loading...</div>;
  }
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (selectedTime) => {
    setSelectedTime(selectedTime);
  };
  const generateSelectableDates = () => {
    const today = new Date();
    const selectableDates = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      selectableDates.push(date);
    }

    return selectableDates;
  };

  const selectableDates = generateSelectableDates();
  const generateSelectableTimes = () => {
    const startTime = new Date();
    startTime.setHours(10, 0, 0, 0); // Start from 10:00 AM
    const endTime = new Date();
    endTime.setHours(18, 30, 0, 0); // End at 6:30 PM

    const selectableTimes = [];

    while (startTime <= endTime) {
      const formattedTime = startTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      selectableTimes.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return selectableTimes;
  };
  const selectableTimes = generateSelectableTimes();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate || !selectedTime) {
      setError("Please select both date and time.");
      return;
    }
  
    try {
      const startTime = selectedTime.split(" ")[0];
      const when = new Date(selectedDate + " " + startTime).toISOString();
      const status = "Reschedule";
  
      const response = await fetch(`${BASE_URL}/projects/sitevisits/${id}/`, {
        method: "PATCH",
        body: JSON.stringify({ status: status, when: when }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        const data = await response.json();
        setError(data.project_id)
        throw new Error("Failed to reschedule site visit.");
      }
      
      navigate(`/sitevisit/${project_id}/confirmation?date=${selectedDate}&time=${selectedTime}`);
    } catch (error) {
      console.error("Error rescheduling site visit:", error);
    }
  };
  

  return (
    <div className="site-visit-reschedule">
      <div className="container">
        <div className="site-visit-reschedule-detail">
          <form onSubmit={handleFormSubmit}>
            <div className="row" >
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="site-visit-left">
                  <h4>
                    {property.name} | {property.address}
                  </h4>
                  <ClickableImage
                      src={
                        property.image1 ? property.image1 : "/images/pro-1.jpg"
                      }
                    />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="site-visit-date">
                <div className="form-group">
                      <h5>Select Date :</h5>
                      <div className="date-box-container">
                        {selectableDates.map((date) => (
                          <div
                            key={date.toISOString().split("T")[0]}
                            className={`date-box ${
                              selectedDate === date.toISOString().split("T")[0]
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              handleDateChange({
                                target: { value: date.toISOString().split("T")[0] },
                              })
                            }
                          >
                            <p className="date">{date.getDate()}</p>
                            <p className="day">
                              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
                                date.getDay()
                              ]}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="form-group">
                      <h5>Select Time : </h5>
                      <div className="time-slot-container">
                        {selectableTimes.map((time) => (
                          <div
                            key={time}
                            className={`time-slot ${
                              selectedTime === time ? "selected" : ""
                            }`}
                            onClick={() => handleTimeChange(time)}
                          >
                            {time}
                          </div>
                        ))}
                      </div>
                    </div>
                </div>{error && <p style={{ color: "red" , textAlign:'center' }}>{error}</p>}
              </div>
             
                
              
            </div>
            <div className=" d-flex justify-content-end">
              <button className="buttons">Reschedule</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reschedule;

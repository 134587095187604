import React, {  useEffect, useState } from "react";
import { BASE_URL } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { getSiteVisitsAsync } from "../../../features/sitevisit/siteVisitSlice";
import AreaName from "../../../components/card/AreaName";
import CityName from "../../../components/card/CityName";
import UserData from "../../../components/card/UserData";

const AssignSitevisit = () => {
  const dispatch = useDispatch()
  const {data: assignedSitevisits } = useFetch("/projects/assignexecutive/");
  const projects = useSelector(state=>state.project.projects)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const siteVisits = useSelector(state=>state.sitevisit.visits)
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [startDate, setStartDate] = useState(""); 
  const [endDate, setEndDate] = useState("");
  const [selectedCity, setSelectedCity] = useState("All");

  const formatDateTime = (datetimeString) => {
    const dateTime = new Date(datetimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString(); 
    return { date, time };
  };
useEffect(()=>{
  const fetchexecutives = async () => {
    try{
    const response = await fetch(
      `${BASE_URL}/projects/sitevisitevecutive/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    setExeutives(data)
  }catch (error) {
    console.error( error);
  }
  };
  fetchexecutives();
},[accessToken])
useEffect(()=>{
  dispatch(getSiteVisitsAsync(accessToken))
},[dispatch,accessToken])
const [executives, setExeutives] = useState([]);
 

  // Function to filter executives based on project city
  const getExecutivesForCity = (city) => {
    return executives?.filter((executive) => executive?.city === city);
  };

  const assignSiteVisitExecutive = async (sitevisitId, executiveId) => {
    try {
      const response = await fetch(
        `${BASE_URL}/projects/assignexecutive/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${accessToken}`,
          },
          body: JSON.stringify({
            sitevisit: sitevisitId,
            sitevisit_executive: executiveId,
            assigned: "Assigned",
          }),
        }
      );
      if (response.ok) {
        alert("assign executive to site visit");
        window.location.reload()
      } else {
        const errorData = await response.json();
        const errorMessage = JSON.stringify(errorData);
        alert(errorMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleExecutiveChange = (event, sitevisitId) => {
    const executiveId = event.target.value;
    assignSiteVisitExecutive(sitevisitId, executiveId);
  };

  // for filters 
  const filteredSiteVisits = siteVisits
    ? siteVisits.filter((sitevisit) => {
        // Check if the site visit date falls within the selected date range
        const visitDate = new Date(sitevisit.when);
        const startFilterDate = startDate ? new Date(startDate) : null;
        const endFilterDate = endDate ? new Date(endDate) : null;

        const dateWithinRange =
          (!startFilterDate || visitDate >= startFilterDate) &&
          (!endFilterDate || visitDate <= endFilterDate);

        // Check if the selected city matches the site visit's project city
        const project = projects?.find(
          (project) => project.id === sitevisit.project_id
        );
        const cityMatchesFilter =
          selectedCity === "All" ||
          (project && String(project.city) === selectedCity);

        // Check if the project name matches the selected filter
        const projectMatchesFilter =
          selectedProjectName === "" ||
          selectedProjectName === "All" ||
          (project && project.name === selectedProjectName);

        return dateWithinRange && cityMatchesFilter && projectMatchesFilter;
      })
    : [];

  return (
    <div className="assign-sitevisits">
      <div className="container ">
        <h3 className="mt-2">Assign Site Visits</h3>
        <div>
          {/* Filter Card */}
          <div className="filter-card ">
            <div className="d-flex flex-column ">
              <label htmlFor="projectFilter">Project Name:</label>
              <select
                id="projectFilter"
                name="projectFilter"
                value={selectedProjectName}
                onChange={(event) => setSelectedProjectName(event.target.value)}
              >
                <option value="">All</option>
                {projects
                  ? projects.map((project) => (
                      <option key={project.id} value={project.name}>
                        {project.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div>
              <label htmlFor="">Filter by date :</label>
              <div className="date-filter">
                <div>
                  <label htmlFor="startDate">From :</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="endDate">To:</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value)}
                  />
                </div>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="cityFilter">City:</label>
              <select
                id="cityFilter"
                name="cityFilter"
                value={selectedCity}
                onChange={(event) => setSelectedCity(event.target.value)}
              >
                <option value="All">All</option>
                
                {Array.from(
                  new Set(projects?.map((project) => project.city))
                )?.map((city) => (
                  <option key={city} value={city}>
                    <CityName Id={city} />
                  </option>
                ))}
              </select>
            </div>
          </div>
          {siteVisits && projects ? (
            <table>
              <thead>
                <tr className="text-center">
                  <th>SiteVisit Id</th>
                  <th>Project Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Area</th>
                  <th>City</th>

                  <th>Assigned Executive</th>
                  <th>Assign Sitevisit Executive</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {filteredSiteVisits.map((sitevisit) => {
                  // Find the corresponding project based on sitevisit.project_id
                  const project = projects?.find(
                    (project) => project.id === sitevisit.project_id
                  );
                  // Format date and time
                  const { date, time } = formatDateTime(sitevisit.when);
                  // Find the assigned site visit entry for the current site visit
                  const assignedSiteVisit =
    Array.isArray(assignedSitevisits) && assignedSitevisits.length
      ? assignedSitevisits.find(
          (assigned) => assigned.sitevisit === sitevisit.id
        )
      : null;

                  // Get the executives for the project's city
                  const executivesForCity = project
                    ? getExecutivesForCity(project.city)
                    : [];
                  const assignedExecutiveName = executivesForCity.find(
                    (executive) =>
                      executive.id === assignedSiteVisit?.sitevisit_executive
                  )?.user;
                  return (
                    <tr key={sitevisit.id}>
                      <td>{sitevisit.id}</td>
                      <td>{project ? project.name : ""}</td>
                      <td>{date}</td> {/* Display date */}
                      <td>{time}</td>
                      <td>
                        <AreaName Id={project?.area} />
                      </td>
                      <td>
                        <CityName Id={project?.city} />
                      </td>
                      <td>
                        {assignedSiteVisit ? (
                          // <>{assignedExecutiveName}</>
                          <><UserData userID={assignedExecutiveName}/></>
                        ) : (
                          "Not Assigned"
                        )}
                      </td>
                      <td>
                        <select
                          name="sitevisit_executive"
                          onChange={(event) =>
                            handleExecutiveChange(event, sitevisit.id)
                          }
                        >
                          <option value="">Select</option>
                          {executivesForCity.map((executive) => (
                            <option key={executive.id} value={executive.id}>
                              {/* {executive?.user} */}
                              <UserData userID={executive?.user}/>
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignSitevisit;

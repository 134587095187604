import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../util/Constant';
import './Authentication.css'
const ForgotPass = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/auth/users/reset_password/`, { email });
      setMessage(response.data.message); 
      setEmail('');
    } catch (error) {
      // Handle error messages from the server
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="row contforsignin">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="signup-form width-50">
                <h3 className="text-center text-uppercase mb-3">forgot password</h3>
                {message && <div className="text-center mb-3">{message}</div>}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group text-center">
                    <input type="submit" id="icon" className="signup text-uppercase" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPass;
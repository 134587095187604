import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Home from './pages/common/home/Home'
import Search from './pages/common/searchPage/Search'
import Detail from './pages/common/detailPage/Detail'
import SignUp from './pages/authentication/SignUp';
import Login from './pages/authentication/Login';
import ForgotPass from './pages/authentication/ForgotPass';
import Verify from './pages/authentication/Verify';
import Error from './pages/common/404/Error';
import ProtectedBuilder from './protectedRoutes/ProtectedBuilder';
import Dashboard from './pages/builder/dashboard/Dashboard';
import { Account } from './pages/customer/account/Account';
import Profile from './pages/customer/profile/Profile';
import Booking from './pages/customer/booking/Booking';
import Settings from './pages/customer/settingPage/Settings';
import ProtectedCustomer from './protectedRoutes/ProtectedCustomer';
import ProtectedAdmin from './protectedRoutes/ProtectedAdmin'
import BuilderProfile from './pages/builder/profile/Profile'
import Feedback from './pages/customer/feedback/Feedback';
import BuyNow from './pages/customer/buyNow/BuyNow';
import BuyNowConf from './pages/customer/buyNow/Confirmation';
import SiteVisit from './pages/customer/siteVisit/SiteVisit';
import Confirmation from './pages/customer/siteVisit/Confirmation';
import Reschedule from './pages/customer/siteVisit/Reschedule';
import Cancellation from './pages/customer/siteVisit/Cancellation';
import DetailConf from './pages/customer/buyNow/DetailConf';
import BuyerForm from './pages/customer/buyNow/BuyerForm';
import AddBlock from './pages/builder/blocks/AddBlock';
import AddLayout from './pages/builder/layouts/AddLayout';
import AddUnit from './pages/builder/units/AddUnit';
import AddConstStatus from './pages/builder/constStatus/AddConstStatus';
import AddProject from './pages/builder/addProject/AddProject';
import ProjectStatus from './pages/admin/projectsStatus/ProjectStatus';
import AssignSitevisit from './pages/admin/manageSiteVisits/AssignSitevisit';
import ProjectDetails from './pages/admin/projectsDetail/ProjectDetails'; 
import AllBlock from './pages/admin/blocks/Blocks';
import AddBlockInAdmin from './pages/admin/blocks/AddBlock';
import Layouts from './pages/admin/layouts/Layouts';
import AddLayoutInAdmin from './pages/admin/layouts/AddLayoutInAdmin';
import AllUnits from './pages/admin/units/AllUnits';
import AddUnitInAdmin from './pages/admin/units/AddUnitInAdmin';
import AddDeveloper from './pages/admin/addDeveloper/AddDeveloper';
import AddProjectInAdmin from './pages/admin/addProject/AddProject';
import LoanForm from './pages/customer/loan/LoanForm';
import BuilderConfirm from './pages/authentication/BuilderConfirm';
import Payment from './pages/customer/buyNow/Payment';
import Nearest from './components/Nearest'
import Loan from './pages/customer/loanApplication/applicant/LoanForm';
import Application from './pages/customer/loanApplication/application/Application';
import CreditAndLoan from './pages/customer/loan/creditAndloan/CreditAndLoan';
import HomeLoan from './pages/customer/loanApplication/loanDashboard/HomeLoan';

function App() {

  // window.addEventListener("contextmenu", (e)=> e.preventDefault())
  return (
    <>
      <BrowserRouter>
      
        <Navbar /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />}/>
          <Route path="/detail/:id" element={<Detail />}/>
          <Route path="/login" element={<Login  />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgotpassword" element={<ForgotPass/>}/> 
          <Route path="/builderconfirmation" element={<BuilderConfirm />} />
          <Route path="/nearest" element={<Nearest />} />
          <Route element={<ProtectedCustomer/>}><Route path="/profile" element={<Profile />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/account" element={<Account />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/bookings" element={<Booking />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/settings" element={<Settings />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/feedback" element={<Feedback />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/buynow/:id" element={<BuyNow />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/sitevisit/:id" element={<SiteVisit />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/sitevisit/:id/confirmation" element={<Confirmation />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/reschedule/:project_id/:id" element={<Reschedule />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/cancellation/:project_id/:id" element={<Cancellation />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/buynow/:id/buynowconformation/:layoutId/:unitId" element={<DetailConf />}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/buynow/:id/buyerdetail"  element={<BuyerForm/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/confirmation" element={<BuyNowConf/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/payment" element={<Payment />} /></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/applyforloan"  element={<LoanForm/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/loanapplication"  element={<Loan/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/application"  element={<Application/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/creditAndLoan"  element={<CreditAndLoan/>}/></Route>
          <Route element={<ProtectedCustomer/>}><Route path="/homeloan"  element={<HomeLoan/>}/></Route>
          
          <Route element={<ProtectedBuilder/>}><Route path="/dashboard" element={<Dashboard />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/addblock" element={<AddBlock />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/builderprofile" element={<BuilderProfile />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/addlayout" element={<AddLayout />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/addunit" element={<AddUnit />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/addconststatus/:id" element={<AddConstStatus />}/></Route>
          <Route element={<ProtectedBuilder/>}><Route path="/addproject" element={<AddProject />}/></Route>
          
          <Route element={<ProtectedAdmin/>}><Route path="/assignsitevisit" element={<AssignSitevisit />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/projectstatus" element={<ProjectStatus />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/projectdetails" element={<ProjectDetails />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/addprojects" element={<AddProjectInAdmin />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/allblocks" element={<AllBlock />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/addBlockInAdmin" element={<AddBlockInAdmin />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/allLayouts" element={<Layouts />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/addLayoutInAdmin" element={<AddLayoutInAdmin />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/allUnits" element={<AllUnits />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/addUnitInAdmin" element={<AddUnitInAdmin />}/></Route>
          <Route element={<ProtectedAdmin/>}><Route path="/adddeveloper" element={<AddDeveloper />}/></Route>
          
          <Route path="*" element={<Error />}/>
         
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useState,useEffect } from "react";
import Chart from 'chart.js/auto'
import './Calculator.css'
import { Link } from "react-router-dom";

const Emi = () => {
  const [data, setData] = useState({
    amount_required: "1000000",
    duration: "24",
    rate_of_interest: "10",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let maxValue;

    switch (name) {
      case "amount_required":
        maxValue = 100000000;
        break;
      case "duration":
        maxValue = 360;
        break;
      case "rate_of_interest":
        maxValue = 16;
        break;
      default:
        maxValue = Number.MAX_SAFE_INTEGER;
    }

    // Validate the entered value to be within the specified range
    const newValue = Math.min(maxValue, Math.max(0, value));

    setData({
      ...data,
      [name]: newValue,
    });
  };

  const formatCurrency = (amount) => {
    if (amount < 100000) {
      return `₹${amount / 1000} K`;
    } else if (amount < 10000000) {
      return `₹${amount / 100000} L`;
    } else {
      return `₹${amount / 10000000} Cr`;
    }
  };
  const calculateEmi = () => {
    const loanAmount = parseFloat(data.amount_required);
    const interestRate = parseFloat(data.rate_of_interest) / 1200; // Monthly interest rate
    const loanTenure = parseFloat(data.duration);

   
    const emi =
      (loanAmount * (interestRate * Math.pow(1 + interestRate, loanTenure))) /
      (Math.pow(1 + interestRate, loanTenure) - 1);

    return isNaN(emi) ? 0 : emi.toFixed(0);
  };

  const calculateInterestPayable = () => {
    const loanAmount = parseFloat(data.amount_required);
    const emiAmount = parseFloat(calculateEmi());
    const loanTenure = parseFloat(data.duration);

    const totalRepayment = emiAmount * loanTenure;
    const interestPayable = totalRepayment - loanAmount;

    
    return isNaN(interestPayable) ? 0 : interestPayable.toFixed(0);
  };

  const totalAmount = ()=>{
    const loanAmount = parseFloat(data.amount_required)
    const interestPayable =  parseFloat(calculateInterestPayable())

    const total = loanAmount + interestPayable
    return total.toFixed(0)
  }


 

  const updateChart = () => {
    // Access the chart canvas
    const ctx = document.getElementById("donutChart");
  
    // Check if the chart instance already exists
    const existingChart = Chart.getChart(ctx);
  

    // If the chart instance exists, destroy it
    if (existingChart) {
      existingChart.destroy();
    }

    ctx.width = 200; // set the desired width
    ctx.height = 200;
  
    // Create a new Chart.js doughnut chart with the updated data and colors
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Principal amount", "Interest Payable"],
        datasets: [
          {
            data: [parseFloat(data.amount_required), parseFloat(calculateInterestPayable())],
            backgroundColor: ["darkblue", "skyblue"],
          },
        ],
      },
    });
  };

  useEffect(() => {
    updateChart();
  }, [data]);
  
  return (
    <div className="calculator">
      <div>
        <h3 className="text-center">Calculate Home Loan EMI</h3>
        <p className="text-center">
          Use our home loan EMI calculator, input the loan amount, the rate of
          interest and the loan tenure. The calculator will then display the
          home loan EMI amount you will need to pay each month.
        </p>
      </div>
      <div className="calcultor-form row">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <div className="form-group mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name"> Loan Amount </label>
              <input
                type="number"
                className="form-control mt-2"
                id="amount_required_input"
                name="amount_required"
                value={data.amount_required}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="amount_required"
              name="amount_required"
              min="100000" // set the minimum value of the slider
              max="100000000" // set the maximum value of the slider
              step="10000" // set the step interval
              value={data.amount_required}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span> {formatCurrency(100000)}</span>
              <span>{formatCurrency(100000000)}</span>
            </div>
          </div>
          <div className="form-group mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Loan Duration</label>
              <input
                type="number"
                className="form-control mt-2"
                id="duration"
                name="duration"
                value={data.duration}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="duration"
              name="duration"
              min="30" // set the minimum value of the slider
              max="360" // set the maximum value of the slider
              step="6" // set the step interval
              value={data.duration}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between ">
              <span>30 Months</span>
              <span>360 Months</span>
            </div>
          </div>
          <div className="form-group mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="name">Interest Rate</label>
              <input
                type="number"
                className="form-control mt-2"
                id="rate_of_interest"
                name="rate_of_interest"
                value={data.rate_of_interest}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="range"
              className="form-range"
              id="rate_of_interest"
              name="rate_of_interest"
              min="8" // set the minimum value of the slider
              max="16" // set the maximum value of the slider
              step="0.5" // set the step interval
              value={data.rate_of_interest}
              onChange={handleInputChange}
              required
            />
            <div className="d-flex justify-content-between">
              <span>8 %</span>
              <span>16 %</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6 ">
          <div className="form-result row text-center">
            <div className="chart-value">
          <div className="mb-3 chart">
            <canvas id="donutChart" ></canvas>
          </div>
          <div className="col value">
            <div className="d-flex justify-content-between mb-2 ">
              <span>Principal Amount</span>
              <span>&#8377;{data.amount_required}</span>
             
            </div>
            
            <div className="d-flex justify-content-between mb-2">
              <span>Interest Payable</span>
              <span>&#8377;{calculateInterestPayable()}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Total amount</span>
              <span>&#8377;{totalAmount()}</span>
            </div>
            </div>
            </div>
            <div className="monthly-emi">
              <p>Your Monthly EMI is <span>&#8377;{calculateEmi()}</span></p>
            </div>
            
            <div className="col-12 mt-3">
            <Link to='/homeloan'><button>Apply For Loan</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Emi;

import React, { useEffect, useState } from "react";
import "./Profile.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserDetailsAsync,
  updateUserDetailAsync,
} from "../../../features/user/userSlice";
import Loader from "../../../components/loader/Loader";

const Profile = () => {
  const userData = useSelector((state) => state.authorization.user);
  const accessToken = useSelector((state) => state.authorization.accessToken);
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.user.userDetail);
  const isaLoading = useSelector((state) => state.user.isLoading);
  const Error = useSelector((state) => state.user.error);
  const [isEditMode, setIsEditMode] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (profilePhoto) {
      formData.append("profile_photo", profilePhoto);
    }

    formData.append("mobile_number2", mobileNumber);
    dispatch(updateUserDetailAsync({ newData: formData, token: accessToken }));
    if (Error === null) {
      setIsEditMode(false);
    }
  };
  useEffect(() => {
    dispatch(getUserDetailsAsync(accessToken));
  }, [dispatch, accessToken]);
  return (
    <>
      {isaLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="profile">
            <div className="profile-right">
              <h3>My Profile</h3>
              <div className="profile-icons">
                <button onClick={handleEditClick}>
                  {isEditMode ? (
                    <i
                      className="bi bi-x-square"
                      style={{ fontSize: "30px" }}
                    /> // "X" icon to cancel edit
                  ) : (
                    <i
                      className="bi bi-pencil-square"
                      style={{ fontSize: "30px" }}
                    /> // "Pencil" icon for editing
                  )}
                </button>
              </div>

              <div className="profile-detail">
                <div className="profile-photo">
                  {isEditMode ? (
                    // Render a file input for profile photo when in edit mode
                    <>
                      <label htmlFor="file-input" className="file-input-label">
                        <div className="file-input-box">
                          <i className="fas fa-plus">+</i>
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          id="file-input"
                          onChange={(e) => setProfilePhoto(e.target.files[0])} // Store the selected file object
                          name="profile_photo"
                        />
                      </label>
                    </>
                  ) : (
                    // Render the profile photo
                    <img
                      src={
                        customerData?.profile_photo
                          ? customerData?.profile_photo
                          : "/images/user-icon.png"
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="details">
                  <p>
                    Name:{" "}
                    <span>
                      {userData?.first_name} {userData?.last_name}
                    </span>
                  </p>
                  <p>
                    Email: <span>{userData?.email}</span>
                  </p>
                  <p>
                    Mobile No.:{" "}
                    <span>
                      {userData
                        ? userData.mobile_number || "Not available"
                        : ""}
                    </span>
                  </p>
                  {isEditMode ? (
                    // Render a text input for mobile number when in edit mode
                    <p>
                      Mobile Number 2:
                      <input
                        type="number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        required
                      />
                    </p>
                  ) : (
                    <p>
                      Mobile Number 2:{" "}
                      <span>
                        {customerData
                          ? customerData.mobile_number2 || "Not available"
                          : ""}
                      </span>
                    </p>
                  )}
                  <p>
                    Adhar : <span>Registered</span>
                  </p>
                  <p>
                    PAN : <span>Registered</span>
                  </p>
                  <p>
                    <strong>Account:</strong> <span>Primary</span>
                  </p>
                  {isEditMode && (
                    // Render a submit button for the form when in edit mode
                    <p style={{ color: "#012060", fontStyle: "italic" }}>
                      To edit registered email and mobile number contact us on
                      discountking@gmail.com
                    </p>
                  )}
                  {isEditMode && (
                    // Render a submit button for the form when in edit mode
                    <button onClick={handleFormSubmit} className="buttons">
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;

import React, {  useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFetch from '../../../hooks/useFetch'
import { fetchProjectByIdAsync } from "../../../features/project/projectSlice";
import Stepper from "../../../components/Stepper/StepTracker";

const DetailConf = () => {
  const { id, layoutId, unitId } = useParams();
  const dispatch = useDispatch();
  const property = useSelector(state=>state.project.selectedProject)
  const {data: layoutData} = useFetch(`/projects/projects/${id}/layouts/${layoutId}`)
  const {data:unitData} = useFetch(`/projects/projects/${id}/units/${unitId}`)
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    dispatch(fetchProjectByIdAsync(id))
  },[dispatch,id])
  function handleChange(e) {
    setChecked(e.target.checked);
  }
 
  return (
    <div className="availability">
      {layoutData ? (
        <div className="container">
          <Stepper currentPage={1} />
          <div className="project-name">
            <div className="buyer-detail-image">
              <img
                src={property?.image1 ? property?.image1 : "/images/pro-1.jpg"}
                alt=""
              />
            </div>
            <div className="buyer-detail-text">
              <h3>{property?.name}</h3>
              <h3>
                {property?.address}{" "}
                <a href={property?.google_location} target="_blank" rel="noreferrer noopener">
                  <i
                    className="bi bi-geo-alt"
                    style={{ fontSize: "1.5rem", color: "#012060" }}
                  ></i>
                </a>
              </h3>
            </div>
          </div>
          <div className="avaibility-detail">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="select-unit">
                  <img src="/images/avaibility.png" alt=""/>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 col-sm-12">
                <div  >
                  <input value="test" type="checkbox" onChange={handleChange} />
                  <label htmlFor="" className="ml-2">Female buyer</label>
                </div>
                <div className="price-component">
                  <div className="price-component-detail">
                    <h4>Price components</h4>
                    <ul>
                      <li>
                        <p>
                          Basic rate @ {unitData?.basic_rate_per_sqft}/sqft
                          <span>
                            {" "}
                            &#8377;{" "}
                            {unitData?.basic_rate_per_sqft * unitData?.unit_superbuiltup_area}{" "}
                            /-
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          AEC, AMC, legal  @ {unitData?.extra_charges_per_sqft}/sqft
                          <span>
                            {" "}
                            &#8377;{" "}
                            {unitData?.extra_charges_per_sqft*unitData?.unit_superbuiltup_area}{" "}
                            /-
                          </span>
                        </p>
                      </li>
                      
                      <li>

                        <p> 
                          Legal fees
                          <span> &#8377; {unitData?.legal_fees} /-</span>
                        </p>
                      </li>
                     
                      <strong className="d-flex justify-content-around">
                          <span>Basic Cost</span> 
                          <p> &#8377; {unitData?.basic_cost} /-</p>
                        </strong>
                      <li>
                        <p>
                          GST @ {unitData?.gst_percent}% 
                          <span> &#8377; {unitData?.gst} /-</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Sale deed value 
                          <span> &#8377; {unitData?.saledeed_value} /-</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Stamp duty
                          <span> &#8377; {unitData?.stamp_duty_amount} /-</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Registration fees
                          <span> &#8377; {checked ? unitData?.registration_fees_for_female_buyers : unitData?.registration_fees_amount} /-</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Maintenance Deposit
                          <span> &#8377; {unitData?.maintenance_deposit_amount}/-</span>
                        </p>
                      </li>
                      <li>
                        <p>
                        2 year Advance Maintenance
                          <span> &#8377; {unitData?.advance_maintenance_amount} /-</span>
                        </p>
                      </li>
                        <strong className="d-flex justify-content-around">
                          <span>Total</span> 
                          <span> &#8377; {checked ? unitData?.all_inclusive_price_for_female_buyers : unitData?.all_inclusive_price} /-</span>
                        </strong>
                     
                    </ul>
                    
                  </div>
                </div>
              </div>
            </div>
            <p>
              <strong>
                Note : Registration fees and Stamp Duty charges may changes as
                per Goverment rules
              </strong>
            </p>
            <div className="payment-terms">
              <div className="payment-terms-detail">
                <h4>Payment Terms:</h4>
                <ul>
                  <li>
                    <p>
                      Provisional Booking Amount: <span>Rs 1,00,000</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Once we confirm booking, <span>10% - Rs 1,00,000/-</span>{" "}
                      to be paid in 1 week followed by ATS
                    </p>
                  </li>
                  <li>
                    <p>
                      After ATS, aother <span>10%</span> in 1 month
                    </p>
                  </li>
                  <li>
                    <p>
                      Thereafter <span>2.5%</span> on every slab approx. every
                      30 days for 21 slabs
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>15%</span> on completion of plaster
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>12.5%</span> on intimation of receiving BU
                      Permission followed by execution of saledeed and handover
                      of possession
                    </p>
                  </li>
                </ul>
                <h5>
                  If your booking is not accepted by us for some reson, we will
                  refund back <span>Rs 1,00,000/-</span> to your account
                  immediately or maximum within 24 hrs.
                </h5>
              </div>
            </div>

            <div className="confermation-main">
              <div className="confermation-detail">
                <h4>Confirmation</h4>
                <p>Selected unit No.: {unitData?.full_unit_number}</p>
                <p>Size: {layoutData.nickname}</p>
                <p>Total price: Rs {unitData?.all_inclusive_price}/-</p>
                <p>Construction Stage: {unitData?.status }</p>
                <p>
                  Amount Due as per Stage: &#8377;{unitData?.saledeed_value}/- + GST  &#8377;{unitData?.gst}/-
                  (32.5% + GST)
                </p>
                <p>
                  This Amount is to be paid in 1 month of ATS Registration / 45
                  days off booking.
                </p>
              </div>
              <div className="confermation-btn">
                <ul>
                  <li>
                    {" "}
                    <Link
                      to={`/buynow/${property?.id}/buyerdetail?unitNumber=${unitData?.full_unit_number}`}
                    >
                      Pay & Book
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>No layouts are available</div>
      )}
    </div>
  );
};

export default DetailConf;

import React from 'react'
import BasicDetail from './BasicDetail'
import Occupation from './OccupationSalaried'
import Asset from './Asset'
import Occupation2 from './OccupationSelfEmployed'

const Pages = ({page, handleInputChange,data}) => {
    const display =[
         <BasicDetail data={data} handleInputChange={handleInputChange} /> ,
         data.occupation==="Salaried" ? <Occupation data={data} handleInputChange={handleInputChange}/> : 
    <Occupation2 data={data} handleInputChange={handleInputChange}/>,
         <Asset data={data} handleInputChange={handleInputChange} />,
    ]

  return (
    <div>
        {display[page]}
    </div>
  )
}

export default Pages
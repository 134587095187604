import React, { useState } from "react";
import FormInputs from "./FormInputs";
import './AddProject.css'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../util/Constant";

const initialData = {
  name: "",
  address: "",
  google_location: "",
  rera_number: "",
  possession: "",
  macro_details: "",
  micro_details: "",
  bhk: [],
  amenities: [],
  city: "",
  area: "",
  total_floors: "",
  total_blocks: "",
  land_parcel_SqMtr: "",
  builder: [],
  builder_logo: "",
  total_units_in_project: "",
  project_startdate: "",
  image1: "",
  image2: "",
  image3: "",
  image4: "",
  image5: "",
  image6: "",
  image7: "",
  image8: "",
  image9: "",
  image10: "",
  image11: "",
  image12: "",
  image13: "",
  image14: "",
  image15: "",
  image16: "",
  image17: "",
  image18: "",
  image19: "",
  image20: "",
  video1: "",
  video2: "",
  video3: "",
  ebrochure: "",
  project_layout: "",
  builder_kyc_documents: "",
  document_7_12: "",
  document_revenue_records: "",
  land_ownership: "",
  non_agriculture_permission: "",
  land_title_report: "",
  nhai_permission: "",
  town_planning_scheme_layout: "",
  final_plot_plan: "",
  f_form: "",
  plan_pass: "",
  unit_plan_pass: "",
  bu_or_oc_permission: "",
  status: "",
  construction_stage: "",
  construction_permission: "",
  rera_certificate: "",
  unit_types: [],
  short_description: "",
  size_description: "",
  rera_webpage: "",
  builder_description: "",
  locality_description: "",
};

const AddProject = () => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate()
  const userData = useSelector(state=>state.authorization.user)
  const accessToken = useSelector(state=>state.authorization.accessToken)
 
  const [data, setData] = useState(initialData);
  const title = {
    0: "Project Info",
    1: "Other Details",
    2: "Upload Images and Videos",
    3: "Upload documents",
  };
  const handleprev = () => setPage((prev) => prev - 1);
  const handlenext = () => setPage((prev) => prev + 1);
  const disablePrev = page === 0;
  const disableNext = page === Object.keys(title).length - 1;
  const prevHide = page === 0 && "remove-button";
  const nextHide = page === Object.keys(title).length - 1 && "remove-button";
  const submitHide = page !== Object.keys(title).length - 1 && "remove-button";

  const handleSubmit = (e) => {
    e.preventDefault();

    const apiUrl = `${BASE_URL}/projects/builder_projects/`;
    const formData = new FormData(); 

    // Append data to the FormData object
    for (const key in data) {
      if (key === "amenities" || key === "bhk" || key === "unit_types") {
        
        if (Array.isArray(data[key])) {
          data[key].forEach((value) => {
            formData.append(key, value);
          });
        }
      } else if (data[key] instanceof File) {
        formData.append(key, data[key]); 
      } else {
        formData.append(key, data[key]); 
      }
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `JWT ${accessToken}`,
      },
      body: formData,
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          alert("Project added successfully!");
          setData(initialData);
          if(userData.user_type==="Builder"){
            navigate("/dashboard");
          }
          
        } else {
          alert("Failed to add project.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while adding the project.");
      });
  };
  const handleInputChange = (e) => {
    const { name, type, options } = e.target;

    if (type === "file") {
      const selectedFile = e.target.files[0];
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile, 
      }));
    } else if (type === "select-multiple") {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setData({
        ...data,
        [name]: selectedOptions,
      });
    } else {
      setData({ ...data, [name]: e.target.value });
    }
  };
  return (
    <div className="mains">
    <div className="container">
      <div className="add-project">
        <form onSubmit={handleSubmit} enctype="multipart/form-data">
          <h1 className="text-center mb-3  " >{title[page]}</h1>
          <FormInputs page = {page}  handleInputChange = {handleInputChange} handleSubmit={handleSubmit} data={data}/>
          <div className="button-container text-center">
            <button type="button" className={`buttons ${prevHide}`} onClick={handleprev} disabled={disablePrev}>
              Prev
            </button>
            <button type="button" className={`buttons ${nextHide}`} onClick={handlenext} disabled={disableNext}>
              Next
            </button>
            <button type="submit" className={`buttons ${submitHide}`}>
            Add Project
          </button>
          </div>
          
        </form>
      </div>
    </div></div>
  );
};

export default AddProject;

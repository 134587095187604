import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../util/Constant';

const AreaName = ({Id}) => {
  const [name, setName] = useState();

  useEffect(() => {
    if (Id) {
      const apiUrl = `${BASE_URL}/projects/area/${Id}/`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const projectName = data.area;
          setName(projectName);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [Id]);
    return (
        <>
             {name && <p style={{marginBottom:"0"}}>{name}</p>}
             </>
       )
     }
export default AreaName
import React, { useEffect, useState } from "react";
import "../../builder/units/Unit.css"
// import LayoutName from "../Utils/LayoutName";
// import Sunlight from "../../Cards/Sunlight";
// import ViewFromFlat from "../../Cards/View";
// import OpenDirection from "../../Cards/OpenDirection";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUnitAsync } from "../../../features/builder/builderSlice";
import useFetch from "../../../hooks/useFetch";
import LayoutName from "../../../components/card/LayoutName";
import BlockName from "../../../components/card/BlockName";
import Sunlight from "../../../components/card/Sunlight";
import ViewFromFlat from "../../../components/card/View";
import OpenDirection from "../../../components/card/OpenDirection";
// import BlockName from "../Utils/BlockName";

const AllUnits = () => {
const [selectedProjId, setSelectedProjID] = useState(4)
const dispatch = useDispatch()
const projects =  useSelector(state=>state.project.projects)
const {data: units} = useFetch(`/projects/projects/${selectedProjId}/units/`)
  const accessToken = useSelector(state=>state.authorization.accessToken)
  const intialFilters = {
    layout: "",
    block: "",
    floor_range: "",
    status: "",
    facing_of_gate: "",
    sun_light: "",
  };
  const [filter, setFilter] = useState(intialFilters);
  const extractUniqueOptions = (property) => {
    if (Array.isArray(units)) {
      return [...new Set(units.map((unit) => unit[property]))];
    } else {
      // Handle the case where units is not an array (e.g., set to an empty array)
      return [];
    }
  };
  
  const layoutOptions = extractUniqueOptions("layout");
  const blockOptions = extractUniqueOptions("block");
  
  

  useEffect(() => {
    dispatch(getUnitAsync({token:accessToken, id:selectedProjId}))
  }, [selectedProjId,dispatch,accessToken]);
  

  const sortedUnits = Array.isArray(units)
    ? units.filter((unit) => {
        const { layout, block, status, floor_range, facing_of_gate, sun_light } =
          filter;
        return (
          (layout === "" || parseInt(layout) === unit.layout) &&
          (block === "" || block === unit.block) &&
          (status === "" || status === unit.status) &&
          (floor_range === "" || floor_range === unit.floor_range) &&
          (facing_of_gate === "" || facing_of_gate === unit.facing_of_gate) &&
          (sun_light === "" || unit.sun_light.some(value => sun_light.includes(value)))
        );
      })
    : [];
  
  return (
    <div className="container">
      <div className='d-flex justify-content-between'>
      <h1 className="text-center mb-4">All Units</h1>
      <h1><Link to="/addUnitInAdmin"><i className="bi bi-plus-circle"></i></Link></h1>
      </div>
      <div className="filter-card d-flex justify-content-between mt-3">
        <div className="form-group">
          <label htmlFor="project">Projects</label>
          <select
            className="form-control"
            value={selectedProjId}
            onChange={(e) => {
              setSelectedProjID(e.target.value);
            }}
          >
            <option value="">Select Project</option>
            {projects && projects.length > 0
              ? projects.map((proj, index) => (
                  <option key={index} value={proj.id}>
                    {proj.name} 
                  </option>
                ))
              : ""}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Layouts</label>
          <select
            className="form-control"
            name="layout"
            value={filter.layout}
            onChange={(e) => setFilter({ ...filter, layout: e.target.value })}
          >
            <option value="">All Layouts</option>
            {layoutOptions?.map((layoutOption, index) => (
              <option key={index} value={layoutOption}>
                <LayoutName ID={layoutOption} projectID={selectedProjId} />
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Block</label>
          <select
            className="form-control"
            name="block"
            value={filter.block}
            onChange={(e) => setFilter({ ...filter, block: e.target.value })}
          >
            <option value="">All Blocks</option>
            {blockOptions?.map((blockOption, index) => (
              <option key={index} value={blockOption}>
                {blockOption}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Floor range</label>
          <select
            className="form-control"
            name="floor_range"
            value={filter.floor_range}
            onChange={(e) =>
              setFilter({ ...filter, floor_range: e.target.value })
            }
          >
            <option value="">All Range</option>
            <option value="Lower">Lower</option>
            <option value="Middle">Middle</option>
            <option value="Higer">Higher</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Status</label>
          <select
            className="form-control"
            name="status"
            value={filter.status}
            onChange={(e) => setFilter({ ...filter, status: e.target.value })}
          >
            <option value="">All Status</option>
            <option value="Avl">Available</option>
            <option value="BO">Booked</option>
            <option value="HO">Hold</option>
            
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Facing of gate</label>
          <select
            className="form-control"
            name="facing_of_gate"
            value={filter.facing_of_gate}
            onChange={(e) =>
              setFilter({ ...filter, facing_of_gate: e.target.value })
            }
          >
            <option value="">All Facing</option>
            <option value="N">North</option>
            <option value="W">West</option>
            <option value="E">East</option>
            <option value="S">South</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Sunlight</label>
          <select
            className="form-control"
            name="sun_light"
            value={filter.sun_light}
            onChange={(e) =>
              setFilter({ ...filter, sun_light: e.target.value })
            }
          >
            <option value="">All</option>
            <option value="1">Morning</option>
            <option value="2">Afternoon</option>
          </select>
        </div>
      </div>
      <div
        className="project-details unit-details"
        style={{ overflowX: "auto", marginBottom: "20px" }}
      >
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Layout</th>
              <th>Block</th>
              <th>Floor</th>
              <th>Floor Range</th>
              <th>Full Unit Number</th>
              <th>Unit Superbuiltup Area</th>
              <th>Status</th>
              <th>Facing of Gate</th>
              <th>Sunlight</th>
              <th>View from Flat</th>
              <th>Open direction</th>
              <th>Basic rate per sqft</th>
              <th>Extra charges per sqft</th>
              <th>Legal fees</th>
              <th>Stamp duty percent</th>
              <th>Registration fees percent</th>
              <th>Registration fees for female buyers</th>
              <th>Maintenance deposit per sqft</th>
              <th>Advance maintenance per sqft</th>
              <th>Advance maintenance number of months</th>
              <th>Price comment</th>
            </tr>
          </thead>
          <tbody>
            {sortedUnits &&
              sortedUnits?.map((unit, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <LayoutName ID={unit.layout} projectID={selectedProjId} />
                  </td>
                  <td><BlockName ID={unit.block} projectID = {selectedProjId}/></td>
                  <td>{unit.floor}</td>
                  <td>{unit.floor_range ? unit.floor_range : "Not Avl"}</td>
                  <td>{unit.full_unit_number}</td>
                  <td>{unit.unit_superbuiltup_area}</td>
                  <td>
                    {unit.status === "Avl"
                      ? "Available"
                      : unit.status === "BO"
                      ? "Booked"
                      : unit.status === "HO"
                      ? "Hold"
                      : "Not Available"}
                  </td>
                  <td>
                    {unit.facing_of_gate === "E"
                      ? "East"
                      : unit.facing_of_gate === "W"
                      ? "West"
                      : unit.facing_of_gate === "N"
                      ? "North"
                      : unit.facing_of_gate === "S"
                      ? "South"
                      : "Not Available"}
                  </td>
                  <td>
                    {unit.sun_light.length === 0
                      ? "Not Avl"
                      : unit.sun_light.map((value, index) => (
                          <Sunlight
                            key={value}
                            ID={value}
                            isLast={index === unit?.sun_light.length - 1}
                          />
                        ))}
                  </td>
                  <td>
                    {unit.view_from_flat.length === 0
                      ? "Not Avl"
                      : unit.view_from_flat.map((value, index) => (
                          <ViewFromFlat
                            key={value}
                            ID={value}
                            isLast={index === unit?.view_from_flat.length - 1}
                          />
                        ))}
                  </td>
                  <td>
                    {unit.open_direction.length === 0
                      ? "Not Avl"
                      : unit.open_direction.map((value, index) => (
                          <OpenDirection
                            key={value}
                            ID={value}
                            isLast={index === unit?.open_direction.length - 1}
                          />
                        ))}
                  </td>
                  <td>{unit.basic_rate_per_sqft}</td>
                  <td>{unit.extra_charges_per_sqft}</td>
                  <td>{unit.legal_fees}</td>
                  <td>{unit.stamp_duty_amount}</td>
                  <td>{unit.registration_fees_amount}</td>
                  <td>{unit.registration_fees_for_female_buyers}</td>
                  <td>{unit.maintenance_deposit_amount}</td>
                  <td>{unit.advance_maintenance_amount}</td>
                  <td>{unit.advance_maintenance_number_of_months}</td>
                  <td>{unit.price_comment}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllUnits;

import React, {  useEffect, useState } from 'react'
import ProjectName from '../../../components/card/ProjectName';
import { Link } from 'react-router-dom';
import './Block.css'
import { useDispatch, useSelector } from 'react-redux';
import { getBlockAsync } from '../../../features/builder/builderSlice';

const Block = ({selectedProjId}) => {
// const {data: block } = useFetch("/projects/block/");
  const block  = useSelector(state=>state.builder.blocks)
  const dispatch = useDispatch()
  const accessToken = useSelector(state=>state.authorization.accessToken)
 const [filters, setFilters] = useState({
  // projectName: "",
  block: "",
  totalfloors:"",
  no_of_units_on_each_floor:"",
  total_no_of_units_in_block:""
});


const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters((prevFilters) => ({
    ...prevFilters,
    [name]: value,
  }));
};

const filteredBlocks = block?.filter((block) => selectedProjId === block.project)
const blockName = [...new Set(filteredBlocks?.map((block) => block.block))];
const totalfloors = [...new Set(filteredBlocks?.map((block) => block.total_floors))];
const floorUnits = [...new Set(filteredBlocks?.map((block) => block.no_of_units_on_each_floor))];
const blockUnits = [...new Set(filteredBlocks?.map((block) => block.total_no_of_units_in_block))];


const sortedBlocks = filteredBlocks?.filter((block)=>{
  return(
    (filters.block === "" || filters.block === block.block)&&
    (filters.totalfloors === "" || parseInt(filters.totalfloors) === block.total_floors)&&
    (filters.no_of_units_on_each_floor === "" || parseInt(filters.no_of_units_on_each_floor) === block.no_of_units_on_each_floor)&&
    (filters.total_no_of_units_in_block === "" || parseInt(filters.total_no_of_units_in_block) === block.total_no_of_units_in_block)
  )
})
useEffect(()=>{
  dispatch(getBlockAsync(accessToken))
},[dispatch,accessToken])
  return (
    <div className='container all-blocks'>
      
      <div className='d-flex justify-content-between '>
      <h1 className="text-center mb-4">All Blocks</h1>
      <h1><Link to="/addblock"><i className="bi bi-plus-circle"></i></Link></h1>
      </div>
      
      <div className="filter-card d-flex justify-content-around">
          
          <div>
          <label htmlFor="projectFilter">Filter by Block</label>
          <select
            name="block"
            value={filters.block}
            onChange={handleFilterChange}
          >
            <option value="">All Block</option>
           
            {blockName.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by Floors</label>
          <select
            name="totalfloors"
            value={filters.totalfloors}
            onChange={handleFilterChange}
          >
            <option value="">All Floors</option>
           
            {totalfloors.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by Units on each floor</label>
          <select
            name="no_of_units_on_each_floor"
            value={filters.no_of_units_on_each_floor}
            onChange={handleFilterChange}
          >
            <option value="">All Units</option>
           
            {floorUnits.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <div>
          <label htmlFor="projectFilter">Filter by Units in block</label>
          <select
            name="total_no_of_units_in_block"
            value={filters.total_no_of_units_in_block}
            onChange={handleFilterChange}
          >
            <option value="">All Units</option>
           
            {blockUnits.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          </div>
        </div>
      <div className="project-details block-details" style={{overflowX:"auto", marginBottom:"20px"}}>
          <table>
            <thead>
              <tr className="text-center">
                <th>Sr No.</th>
                <th>Project</th>
                <th>Block</th>
                <th>Total floor</th>
                <th>Units on each floor</th>
                <th>Total units in block</th>
                <th>Edit block details</th>
                <th>Delete block</th>
              </tr>
            </thead>
            <tbody className="text-center">
               {sortedBlocks && sortedBlocks?.map((block,index) =>
                  (
                    <tr key={block.id}>
                      <td>{index+1}</td>
                      <td><ProjectName ID = {block.project}/></td>
                      <td>{block.block}</td>
                      <td>{block.total_floors}</td>
                      <td>{block.no_of_units_on_each_floor}</td>
                      <td>{block.total_no_of_units_in_block}</td>
                      <td><button className='small-btn'>EDIT</button></td>
                      <td><button className='small-btn'>DELETE</button></td>
                    </tr>
                )
              )} 
            </tbody>
          </table>
        </div>
    </div>
  )
}
export default Block